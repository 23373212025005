import { TableCell, Typography, styled } from "@mui/material"
import TextCell from "./TextCell"
import SelectCell, { ISelectValue } from "./SelectCell"
import { LivenessMode } from "../../../types/FaceVerification"
import { NormalTableRow, SmallContentTableRow, SmallTextTableRow } from "../Settings"

interface ILivenessModeSelectRowProps {
    isMobile: boolean,
    active: boolean,
    getEnumValuesAndKey: () => ISelectValue[],
    selectLiveness: string
    handleSetLivenessMode: (mode: string) => void
}

const DecriptionTypography = styled(Typography)(({theme}) => ({
    paddingLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px"
    }
}))

const LivenessModeSelectRow: React.FC<ILivenessModeSelectRowProps> = (props) => {

    const getDescription = () => {
        switch(props.selectLiveness) {
            case "1":  //passive
                return "User is only required to position his face according to the provided system status."
            case "2": //active
                return "User has to perform specified head turning and blink when asked."
            case "4": //simple
                return "Easier version of Passive mode."
            case "5": //custom 
                return "User is asked to turn his head left, right, up, down and blink."
            case "6": //passive with blink
                return "Same as Passive mode, but the user has to also blink afterwards."
            default:
                return ""
        }
    }

    const capitalizeFirstLetter = (string: string) => {
        return (string.charAt(0).toUpperCase() + string.slice(1)).replace("_", " ").replace("_", " ");
    }

    const getLivenessDescription = (colspan: number) => {
        return (
            <SmallContentTableRow>
                <TableCell colSpan={colspan} style={{paddingTop: "0px"}}>
                    <DecriptionTypography>
                        <b>
                            {capitalizeFirstLetter(LivenessMode[props.selectLiveness as unknown as number].toLowerCase())} mode:&nbsp;                   
                        </b>
                        {getDescription()}
                    </DecriptionTypography>
                </TableCell>
            </SmallContentTableRow>
        )
    }

    return (
        props.isMobile?
        <>
            <SmallTextTableRow>
                <TextCell colspan={3} title="Liveness mode" subtitle="Determines which Liveness checking algorithm to use."/>
            </SmallTextTableRow>
            <SmallContentTableRow style={{borderBottom: "none"}}>
                <SelectCell
                    title="Mode"
                    colSpan={3}
                    active={props.active}
                    menuItems={props.getEnumValuesAndKey()}
                    value={props.selectLiveness}
                    setValue={props.handleSetLivenessMode}
                />
            </SmallContentTableRow>
            {getLivenessDescription(3)}
        </>
        :
            <>
            <NormalTableRow style={{borderBottom: "none"}}>
                <TextCell title="Liveness mode" subtitle="Determines which Liveness checking algorithm to use."/>
                <SelectCell
                    title="Mode"
                    colSpan={3}
                    active={props.active}
                    menuItems={props.getEnumValuesAndKey()}
                    value={props.selectLiveness}
                    setValue={props.handleSetLivenessMode}
                />
            </NormalTableRow>
            {getLivenessDescription(4)}
            </>
    )
}

export default LivenessModeSelectRow