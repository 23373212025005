import { Grid } from "@mui/material"
import { FilterTitle } from "../FilterTitle"
import InputFilter from "../InputFilter"

interface GridFilterRowProps {
    title: string,
    lowerBound: number | undefined,
    upperBound: number | undefined,
    setLowerBound: (num: number) => void,
    setUpperBound: (num: number) => void
}

const GridFilterRow: React.FC<GridFilterRowProps> = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <FilterTitle>
                    {props.title}
                </FilterTitle>
            </Grid>
            <Grid item xs={6}>
                <InputFilter
                    style={{marginRight: "0.5rem"}}
                    label="From"
                    value={props.lowerBound}
                    setValue={props.setLowerBound}
                    placeholder="0"
                    minValue={0}
                    maxValue={255}
                />
            </Grid>
            <Grid item xs={6}>
                <InputFilter
                    style={{marginLeft: "0.5rem"}}
                    label="To"
                    value={props.upperBound}
                    setValue={props.setUpperBound}
                    placeholder="255"
                    minValue={0}
                    maxValue={255}
                />
            </Grid>
        </Grid>
    )
}

export default GridFilterRow