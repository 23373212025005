import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#00695c",
            contrastText: "#ffffff",
            light: "#f2f8f8",
            dark: "435c59"
        },
        secondary: {
            main: "#486179", // 4f807f   518382
            contrastText: "#ffffff",
            light: "#cadcec"
        },
        background: {
            default: "#f8f8f8",
            paper: "#ffffff",
        },
        text: {
            primary: "#1b1c1e", //#4E4E4E
            secondary: "#ffffff",
        },
        warning: {
            main: "#d87935",
            light: "#df8f57",
            dark: "#b4652c"
        },
        error: {
            main: "#d83553",
            dark: "#b42c45"
        },
        success: {
            dark: "#006A44",
            main: "#307a5e"
        },
        info: {
            main: "#477eb8"
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%",
                    "& .MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                            paddingTop: "8px",
                            paddingBottom: "8px",
                        }
                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid rgba(224, 224, 224, 1)"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none"
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    marginBottom: "0px",
                    paddingBottom: "5px"
                },
                markLabel: {
                    color: "#898989"
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingBottom: "8px",
                    paddingTop: "8px" 
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                outlinedPrimary: {
                    borderWidth: "3px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    borderColor: "#00695C",
                    ":hover": {
                        borderColor: "#004F45",
                        borderWidth: "3px"
                    }
                },
                outlinedSecondary: {
                    color: "#1b1c1e",
                    borderWidth: "3px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    borderColor: "#a6a6a6",
                    ":hover": {
                        borderColor: "#00695c",
                        borderWidth: "3px",
                        color: "#00695c"
                    }
                },
                textPrimary: {
                    textDecoration: "none",
                    textTransform: "none",
                },
                textInherit: {
                    textDecoration: "none",
                    textTransform: "none"              
                },
                textSecondary: {
                    textDecoration: "none",
                    textTransform: "none" 
                },
                root: {
                    textDecoration: "none",
                    textTransform: "none",
                    borderRadius: "6px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "15px",
                    paddingRight: "15px"
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    textDecoration: "none"
                },
                firstButton: {
                    borderColor: "#d7d7d7"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#8d8d8d"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#898989"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#fcfcfc",
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fcfcfc",
                    "&.Mui-expanded": {
                        margin: "0 0 0 0"
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    "&.Mui-expanded": {
                        margin: "0 0 0 0"
                    }
                },
                content: {
                    margin: "0px",
                    "&.Mui-expanded": {
                        margin: "0 0 0 0"
                    }
                },
                contentGutters: {
                    margin: "0px"
                }
            }
        }
    }
});

export default theme;