import { Box, Drawer, Grid, IconButton, DrawerProps } from "@mui/material"
import { IExceptionFilter } from "../../../../Store/Exceptions/types"
import { GridButtons } from "../grid/GridButtons"
import { BoxButton } from "../BoxButton"
import ButtonGradient from "../ButtonGradient"
import ButtonLightGray from "../ButtonLightGray"
import { FilterTitle } from "../FilterTitle"
import FilterDrawerTitle from "../FilterDrawerTitle"
import DateTimeRange from "../DateTimeRange"
import CloseIcon from '@mui/icons-material/Close';
import FilterDrawerMoreTextField from "../FilterDrawerMoreTextField"

interface IExceptionDrawerMoreFilter extends DrawerProps {
    onFilterApply: (filter: IExceptionFilter) => void,
    resetFilter: () => void,
    filter: IExceptionFilter,
    setFilter: React.Dispatch<React.SetStateAction<IExceptionFilter>>
}

const ExceptionDrawerMoreFilter: React.FC<IExceptionDrawerMoreFilter> = (props) => {

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            props.onFilterApply(props.filter)
        }
    }

    return (
        <Drawer
            anchor={props.anchor}
            open={props.open}
            onClose={props.onClose}
            style={{position: "relative"}}
            onKeyDown={(e) => handleKeyDown(e)}
        >
            <Box style={{maxWidth: "450px", height: "100%"}}>
                <Grid container>
                    <Grid item xs={8}>
                        <FilterDrawerTitle>
                            More Filters
                        </FilterDrawerTitle>
                    </Grid>
                    <Grid item xs={4} display={"inline-flex"} justifyContent={"flex-end"}>
                        <IconButton onClick={props.onClose as () => {}}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                    <Grid item xs={12}>
                        <FilterTitle>
                            Data ranges
                        </FilterTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeRange
                            from={props.filter.dateLowerBound}
                            to={props.filter.dateUpperBound}
                            onFromDateChange={(value) => props.setFilter({...props.filter, dateLowerBound: value})}
                            onToDateChange={(value) => props.setFilter({...props.filter, dateUpperBound: value})}
                        />
                    </Grid>
                    <FilterDrawerMoreTextField
                        title="Exception name"
                        value={props.filter.exceptionName}
                        setValue={(value) => {props.setFilter({...props.filter, exceptionName: value})}}
                    />
                    <FilterDrawerMoreTextField
                        title="Exception message"
                        value={props.filter.exceptionMessage}
                        setValue={(value) => {props.setFilter({...props.filter, exceptionMessage: value})}}
                    />
                    <FilterDrawerMoreTextField
                        title="HTTP"
                        value={props.filter.httpCode}
                        setValue={(value) => {props.setFilter({...props.filter, httpCode: value})}}
                    />
                    <FilterDrawerMoreTextField
                        title="Path"
                        value={props.filter.path}
                        setValue={(value) => {props.setFilter({...props.filter, path: value})}}
                    />
                </Grid>
                <Box style={{paddingBottom: "2rem"}} />
                <GridButtons style={{position: "absolute"}} container>
                    <Grid item xs={6} >
                        <BoxButton>
                            <ButtonLightGray
                                onClick={props.resetFilter}
                                variant='text'
                                color="primary"
                                style={{height: "100%", width: "100%"}}
                            >
                                Clear filter
                            </ButtonLightGray>
                        </BoxButton>
                    </Grid>
                    <Grid item xs={6}>
                        <BoxButton>    
                            <ButtonGradient
                                onClick={() => props.onFilterApply(props.filter)}
                                variant='contained'
                                color="primary"
                                style={{height: "100%", width: "100%"}}
                            >
                                Apply
                            </ButtonGradient>
                        </BoxButton>
                    </Grid>
                </GridButtons>
            </Box>
        </Drawer>
    )
}

export default ExceptionDrawerMoreFilter