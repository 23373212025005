import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid,  MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField, Typography, styled } from '@mui/material'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Buffer } from 'buffer';
import ButtonGradient from '../Filter/Components/ButtonGradient';
import ButtonLightGray from '../Filter/Components/ButtonLightGray';
import { APIsContext } from '../../Store/APIs/context';
import { CreateSubjectRequest, CreateSubjectRequestModality, CreateSubjectRequestOption } from 'neurotec-megamatcherid-management-client';
import { useSnackbar } from 'notistack';
import DialogTitleClose from '../Misc/Dialog/Components/DialogTitleClose';

const SelectType = styled(Select)({
    padding: "none",
})

const TypographyTitle = styled(Typography)({
})

enum FileType {
    IMAGE = "IMAGE",
    NTEMPLATE = "NTemplate",
    FVTEMPLATE = "FVTEMPLATE"
}

interface IAddSubjectDialogProps {
    open: boolean,
    onClose: () => void
    rerender: () => void
}

const readFile = async (file: File): Promise<Uint8Array> => {
    return new Promise<Uint8Array>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            let result = new Uint8Array(reader.result as ArrayBuffer);
            resolve(result);
        }
        reader.onerror = (event) => {
            reject(reader.error)
        }
        reader.readAsArrayBuffer(file);
    })
}

const AddSubjectDialog: React.FC<IAddSubjectDialogProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [fileType, setFileType] = useState<CreateSubjectRequestOption>(CreateSubjectRequestOption.Image)
    const [image, setImage] = useState("");
    const [fileName, setFileName] = useState("")
    const [file, setFile] = useState("")
    const [detectLiveness, setDetectLiveness] = useState(false);
    const [checkIcao, setCheckIcao] = useState(false)
    const [modality, setModality] = useState<CreateSubjectRequestModality>(CreateSubjectRequestModality.FaceModality)
    const { state } = useContext(APIsContext)

    useEffect(() => {
        setFileType(FileType.IMAGE)
        setImage("");
        setFileName("")
        setFile("")
        setDetectLiveness(false)
        setCheckIcao(false)
    }, [props])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const subjectId = new FormData(event.currentTarget).get("subject-id") as string;
        if ((subjectId === null || subjectId === "")) {
            enqueueSnackbar("Subject Id must not be blank", {variant: "warning"})
            return
        }
        if (file === null || file === "") {
            enqueueSnackbar("No file selected", {variant: "warning"})
            return
        }
        const request: CreateSubjectRequest = {
            detectLiveness: detectLiveness,
            checkIcaoCompliance: checkIcao,
            subjectId: subjectId,
            data: file,
            option: fileType,
            modality: modality
        }
        state.SubjectsApi.createSubject({createSubjectRequest: request}).then(res => {
            if (res.status === 200) {
                enqueueSnackbar("Subject created successfully", { variant: 'info' });
                props.rerender();
            } else {
                enqueueSnackbar("Failed to create subject", { variant: 'error' });
            }
        }).catch(err => {
            enqueueSnackbar(err.message, { variant: 'error' });
        })
        props.onClose();
    }

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        setFileName(file.name)
        const fileContent = await readFile(file)
        setFile(Buffer.from(fileContent).toString('base64'));
        if (fileType === FileType.IMAGE) {
            setImage(URL.createObjectURL(file))
        }
    }

    const handleFileTypeChange = (event: SelectChangeEvent<unknown>) => {
        setFileType(event.target.value as CreateSubjectRequestOption)
    }

    return (
        <Dialog
            maxWidth="md"
            scroll="body"
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <Box component="form" onSubmit={handleSubmit} noValidate >
                <DialogTitle>
                    <DialogTitleClose title={"Add subject"} onClose={props.onClose}/>
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{ padding: "1rem 0" }} spacing={1}>
                        <Grid item xs={12} sm={6} style={{ backgroundColor: "#E8E8E8", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {image ? <img src={image} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" /> :
                                (<Typography>Image preview</Typography>)}
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <TypographyTitle style={{paddingBottom: "0.25rem"}}>
                                Subject ID
                            </TypographyTitle>
                            <TextField
                                sx={{margin: "none"}}
                                autoComplete="off"
                                id="subject-id"
                                name="subject-id"
                                label="Subject ID"
                                inputProps={{
                                    style: {paddingBottom: "0.5rem"}
                                }}
                                InputLabelProps={{
                                    sx: {top: "-7px", "&.MuiInputLabel-shrink": {top: "0px"}},
                                }}
                            />
                            <TypographyTitle>
                                File Type
                            </TypographyTitle>
                            <SelectType
                                value={fileType}
                                onChange={handleFileTypeChange}
                                style={{padding: "0px", marginBottom: "0.5rem"}}
                                MenuProps={{MenuListProps: {disablePadding: true}}}
                            >
                                <MenuItem value={CreateSubjectRequestOption.Image}>Subject image</MenuItem>
                                <MenuItem value={CreateSubjectRequestOption.Audio}>Subject audio</MenuItem>
                                <MenuItem value={CreateSubjectRequestOption.NTemplate}>Subject N Template</MenuItem>
                                <MenuItem value={CreateSubjectRequestOption.MmidTemplate}>Subject MMID Template</MenuItem>
                            </SelectType>
                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <TypographyTitle>Modality</TypographyTitle>
                                        <RadioGroup
                                            value={modality}
                                            onChange={(e) => {setModality(e.target.value as CreateSubjectRequestModality)}}
                                        >
                                            <FormControlLabel value={CreateSubjectRequestModality.FaceModality} control={<Radio />} label="Face" />
                                            <FormControlLabel value={CreateSubjectRequestModality.VoiceModality} control={<Radio />} label="Audio" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TypographyTitle>
                                        Detect liveness
                                    </TypographyTitle>
                                    <Switch value={detectLiveness} onChange={() => setDetectLiveness(!detectLiveness)}/>
                                </Grid>
                                <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                                    <Box>
                                        <TypographyTitle>
                                            Check Icao
                                        </TypographyTitle>
                                        <Switch value={checkIcao} onChange={() => setCheckIcao(!checkIcao)}/>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={5} md={4}>     
                                    <Button variant="outlined" component="label" style={{width: "100%"}}>
                                        Choose file
                                        <input
                                            hidden
                                            type='file'
                                            accept='image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp'
                                            onChange={handleFileUpload}
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={8} style={{paddingLeft: "1rem"}}>
                                    {fileName}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonLightGray onClick={props.onClose}>
                        Cancel
                    </ButtonLightGray>
                    <ButtonGradient variant="contained" color="primary" type='submit'>
                        Create subject
                    </ButtonGradient>
                </DialogActions>
            </Box>
        </Dialog >
    )
}

export default AddSubjectDialog
