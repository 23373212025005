import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { HeadCell, Order, OrderByKey } from "./DataTable";
import { visuallyHidden } from '@mui/utils';
import { THEME } from '../../../config';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: OrderByKey) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell[];
    inputProps: string;
    disableCheckBox?: boolean
}

const EnhancedTableHead = (props: EnhancedTableProps) => {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, inputProps } = props;

    const createSortHandler = (property: OrderByKey) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{backgroundColor: "#ececec"}}>
            <TableRow>
                <TableCell padding="checkbox" width={"5%"}>
                    {!props.disableCheckBox? 
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all ' + inputProps,
                            }}
                        /> : null
                    }
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        width={headCell.width}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx = {{
                                    '&.MuiTableSortLabel-root': {
                                        color: THEME.palette.text.primary,
                                    },
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: THEME.palette.primary.main,
                                    },
                                    '&.Mui-active': {
                                        color: THEME.palette.primary.main,
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: THEME.palette.primary.main + ' !important',
                                    },
                                }
                            }
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden} style={{backgroundColor: "red"}}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead;
