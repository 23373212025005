import { Box, Button, Tooltip, Typography, styled } from '@mui/material'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import {Image as NImage, Rectangle} from 'neurotec-megamatcherid-management-client';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { THEME } from '../../config';
import ImageDialog from './ImageDialog';
import { SelectionIconButton } from './SelectionIconButton';

const BoxSubjectId = styled(Box)({
    backgroundColor: "#D6E4DF",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "1rem",
    borderRadius: "5px"
});

const BoxImage = styled(Box)({
    minHeight: "25vh",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#E8E8E8"
});

interface IImageCanvasProps {
    image: void | NImage | undefined | null,
    isTokenImage?: boolean,
    clickable?: boolean,
    rectangle?: Rectangle,
    subjectId?: string
}

const ImageCanvas: React.FC<IImageCanvasProps> = (props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLElement>(null);
    const [tokenImage, setTokenImage] = useState<boolean>(false)
    const [showTokenImage, setShowTokenImage] = useState(props.isTokenImage);
    const [imageDialog, openImageDialog] = useState(false)

    const handleStringLength = (str: string | undefined) => {
        if (!str) return ""
        if (str.length < 35) return str;
        return str.substring(0, 30) + "..."
    }

    const getImage = useCallback(() => {
        if (props.image) {
            if (tokenImage) {
                if (showTokenImage) {
                    return props.image.tokenImage as unknown as string;
                } else {
                    return props.image.image as unknown as string;
                }
            } else {
                if (props.image.image) {
                    return props.image.image as unknown as string;
                }
            }
        }
        return undefined;
    }, [props.image, tokenImage, showTokenImage])

    const draw = useCallback(() => {
        if (canvasRef.current && divRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            if (ctx && props.image?.image) {
                const image = new Image();
                image.src = "data:image/png;base64, " + getImage()
                image.onload = () => {
                    if (divRef.current === null) {
                        return
                    }
                    // Setting image width/heigth scale
                    const aspectRatio = image.width / image.height;

                    canvas.width = divRef.current.offsetWidth
                    canvas.height = divRef.current.offsetHeight

                    const canvasAspectRation = canvas.width / canvas.height

                    let canvasCurrentWidth = canvas.width;
                    let canvasCurrentHeight = canvas.height;
                    
                    let rectangleLineWidth

                    if (aspectRatio > canvasAspectRation) { //image is in landscape
                        canvasCurrentHeight = canvasCurrentWidth / aspectRatio;
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio;
                        rectangleLineWidth = canvasCurrentHeight / 85
                    }
                    else { // image is in portrait
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio;
                        canvasCurrentHeight = canvasCurrentWidth / aspectRatio;
                        rectangleLineWidth = canvasCurrentWidth / 85
                    }

                    const horizontalCenter = (canvas.width - canvasCurrentWidth) / 2;
                    const verticalCenter = (canvas.height - canvasCurrentHeight) / 2;

                    ctx.clearRect(0, 0, canvas.width, canvas.height)

                    ctx.drawImage(image, horizontalCenter, verticalCenter, canvasCurrentWidth, canvasCurrentHeight);
                    if (!showTokenImage) {
                        ctx.strokeStyle = "green"
                        ctx.beginPath();
                        ctx.lineWidth = rectangleLineWidth
                        const rect = props.rectangle;
                        if (rect && rect.x && rect.y && rect.height && rect.width) {
                            const widthProportion = image.width / canvasCurrentWidth;
                            const heightProportion = image.height / canvasCurrentHeight;
                            ctx.rect(
                                (rect.x / widthProportion) + horizontalCenter,
                                (rect.y / heightProportion) + verticalCenter,
                                rect.width / widthProportion,
                                rect.height / heightProportion);
                            ctx.stroke()
                        }
                    }
                }
            }
        }
    }, [props, getImage, showTokenImage]);

    const clearAndDraw = useCallback(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            draw();
        }
    }, [draw])

    useEffect(() => {
        clearAndDraw();
        if (props.image?.tokenImage) {
            setTokenImage(true)
        } else {
            setTokenImage(false)
        }
    }, [clearAndDraw, props.image?.tokenImage]);

    const handleImageSelection = () => {
        if (tokenImage) {
            setShowTokenImage(!showTokenImage)
        }
    }

    const getImageArrowSelection = (left: boolean) => {
        return (
            tokenImage?
                <SelectionIconButton
                    onClick={handleImageSelection}
                    disabled={left? showTokenImage : !showTokenImage}
                    style={{right: left? "15px" : undefined, left: left? undefined : "15px"}}
                >
                    <ArrowBackIosNewIcon style={{color: THEME.palette.text.secondary, transform: left? "scale(-1)" : "scale(1)"}} />
                </SelectionIconButton >
            : null
        )
    }

    useLayoutEffect(() => {
        draw();
        window.addEventListener('resize', draw);
        return () =>
            window.removeEventListener('resize', draw);
    }, [draw])

    return (
        <Box style={{height: "90%"}}>
            {props.subjectId && props.subjectId !== "None" ?
                <Tooltip title={props.subjectId}>
                    <BoxSubjectId>
                        <b>&nbsp;&nbsp;Subject Id:</b> {handleStringLength(props.subjectId)}
                    </BoxSubjectId>
                </Tooltip> : null
            }
            <BoxImage ref={divRef}>
                {props.image?.image ?
                    <Box style={{position: "absolute", alignItems: "center", display: "flex"}}>
                        {getImageArrowSelection(true)}
                        {getImageArrowSelection(false)}
                        {props.clickable ?
                            <Button onClick={() => openImageDialog(true)} disableRipple>
                                <canvas ref={canvasRef} style={{ maxWidth: "100%"}} />
                            </Button> : <canvas ref={canvasRef} style={{ maxWidth: "100%"}} />
                        }
                        <ImageDialog image={props.image} open={imageDialog} onClose={() => openImageDialog(false)} isTokenImage={tokenImage && showTokenImage}/>
                    </Box>
                    : (typeof props.image !== 'undefined')? <Typography>No image</Typography> : <Typography>Loading...</Typography>
                }
            </BoxImage>
        </Box>
    )
}

export default ImageCanvas
