import { Paper, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { SettingTable, settingUseStyles } from "../Settings"
import TextCell from "./TextCell"
import ExceptionFilterRow from "./ExceptionFilterRow"

interface IExceptionsFilterTable {
    isMobile: boolean,
    getExceptionFilter: (type: "name" | "http" | "path") => string,
    setExceptionFilter: (type: "name" | "http" | "path", value: string) => void
}

const ExceptionsFilterTable: React.FC<IExceptionsFilterTable> = (props) => {

    const classes = settingUseStyles()
    
    return (
        <TableContainer component={Paper} className={classes.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell colspan={3} width="100%" head title="Exception filter settings" subtitle="Some exceptions might not be relevant, you can filter them out, so that they do not clutter exceptions log."/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ExceptionFilterRow
                        title="Filter out exception name"
                        subtitle="E.g. FVManagementGenericException"
                        isMobile={props.isMobile}
                        apply={(str: string) => props.setExceptionFilter("name", str)}
                        value={props.getExceptionFilter("name")}
                        type="name"
                    />
                    <ExceptionFilterRow
                        title="Filter out HTTP response code"
                        subtitle="E.g. 404"
                        isMobile={props.isMobile}
                        apply={(str: string) => props.setExceptionFilter("http", str)}
                        value={props.getExceptionFilter("http")}
                        type="http"
                    />
                    <ExceptionFilterRow
                        title="Filter out HTTP call path"
                        subtitle="E.g. /management/info"
                        isMobile={props.isMobile}
                        apply={(str: string) => props.setExceptionFilter("path", str)}
                        value={props.getExceptionFilter("path")}
                        type="path"
                    />
                </TableBody>
            </SettingTable>
        </TableContainer>
    )
}

export default ExceptionsFilterTable