import React from 'react'
import { Grid, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
                paddingTop: "8px",
                paddingBottom: "8px",
            }
        }
    }
}))

interface IDateTimeRangeProps {
    from: number | undefined,
    to:  number | undefined,
    onFromDateChange: (date: number) => void,
    onToDateChange: (date: number) => void
}

const DateTimeRange: React.FC<IDateTimeRangeProps> = (props) => {

    const classes = useStyles();

    const onChange = (date: number | null | undefined, setFun: (date: number) => void, keyboardInputValue?: string | undefined) => {
        if (date !== null && date !== undefined) {
            setFun(Math.floor((date as unknown as Date).getTime()))
        }
    }

    return (
        <Grid container>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <Grid item xs={12}>
                    <DateTimePicker
                        className={classes.root}
                        label="From"
                        value={props.from ?? Math.floor(new Date().getTime())}
                        onChange={(date) => onChange(date, props.onFromDateChange)}
                        renderInput={(params) => <TextField {...params}/>}
                    />
                </Grid>
                <Grid item xs={12} style={{paddingTop: "1rem"}}>
                    <DateTimePicker
                        className={classes.root}
                        label="To"
                        value={props.to ?? Math.floor(new Date().getTime())}
                        onChange={(date) => onChange(date, props.onToDateChange)}
                        renderInput={(params) => <TextField {...params} />}

                    />
                </Grid>
            </LocalizationProvider>
        </Grid>
    )
}

export default DateTimeRange
