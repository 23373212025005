import { makeStyles } from "@mui/styles";
import { Dialog, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { THEME } from "../../../config";
import DialogButton from "../../../components/Misc/Dialog/Components/DialogButton";
import DialogGridButton from "../../../components/Misc/Dialog/Components/DialogGridButton";

const useStyles = makeStyles(() => ({
    gridRadio: {
        paddingLeft: "2rem"
    },
    radio: {
        color: THEME.palette.primary.main,
    }
}))

interface IISOConfimationDialog {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    apply: (parameterGroup: parameter) => void
}

export enum parameter {
    PC = "PC",
    MOBILE = "Mobile"
}

const ISOConfirmationDialog: React.FC<IISOConfimationDialog> = (props) => {

    const [state, setState] = useState<parameter>(parameter.PC)
    const classes = useStyles();

    const handleClose = () => {
        props.setOpen(false)
        setState(parameter.PC)
    }

    const handleApply = () => {
        props.apply(state)
        props.setOpen(false)
        setState(parameter.PC)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value as parameter)
    }

    return(
        <Dialog onClose={handleClose} open={props.open}>
        <DialogTitle>Set parameter group</DialogTitle>
        <Grid container>
            <Grid item xs={10} className={classes.gridRadio}>
                <RadioGroup
                    defaultValue={parameter.PC}
                    color="primary"
                    onChange={handleChange}
                >
                    <FormControlLabel value={parameter.PC} control={<Radio color="primary" className={classes.radio}/>} label="PC" />
                    <FormControlLabel value={parameter.MOBILE} control={<Radio color="primary" className={classes.radio} />} label="Mobile" />
                </RadioGroup>
            </Grid>
            <DialogGridButton container>
                <Grid item xs={6}>
                    <DialogButton variant="outlined" color="secondary" onClick={handleClose}>
                        Cancel
                    </DialogButton>
                </Grid>
                <Grid item xs={6}>
                    <DialogButton variant="contained" color="primary" onClick={handleApply}>
                        Apply
                    </DialogButton>
                </Grid>
            </DialogGridButton>
        </Grid>

        </Dialog>
    );
}

export default ISOConfirmationDialog