import { Box, IconButton, InputBase, OutlinedTextFieldProps, styled } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardEventHandler } from "react";
import { THEME } from "../../../config";


const SearchIconButton = styled(IconButton)(() => ({
    height: "100%",
    backgroundColor: "#A6A6A6",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    padding: "0px 0px 0px 0px",
    aspectRatio: " 1 / 1"
}));


const SearchTextField = styled(Box)(() => ({
    height: "calc(100% - 2px);",
    borderStyle: "solid",
    borderRadius: "7px",
    borderColor: "#A6A6A6",
    borderWidth: "1px",
    padding: "-1px",
    display: "flex",
    margin: "1px",
    ":hover": {
        borderColor: "#1b1c1e"
    },
    ":focus-within": {
        borderColor: THEME.palette.primary.main,
        borderWidth: "2px",
        margin: "0px"
    }
}));

interface IFilterTextFieldProps extends OutlinedTextFieldProps {
    onClick: () => void
    onInputKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
    placeholder: string
}

const FilterTextField: React.FC<IFilterTextFieldProps> = (props) => {

    return (
        <SearchTextField>
            <InputBase
                value={props.value? props.value : ""}
                onChange={props.onChange}
                sx={{ ml: 1, flex: 1}}
                placeholder={props.placeholder}
                inputProps={{ 'aria-label': 'subject id' }}
                onKeyDown={props.onInputKeyDown}
            />
            <SearchIconButton onClick={props.onClick}>
                <SearchIcon style={{ color: "#D7D7D7" }} fontSize="inherit"/>
            </SearchIconButton>
        </SearchTextField>
    )
}

export default FilterTextField