import React from "react";

export enum SettingType {
    CAPTURING = "CAPTURING",
    VERIFICATION = "VERIFICATION",
    LIVENESS = "LIVENESS",
    ICAO = "ICAO",
    MANAGEMENT = "MANAGEMENT",
    DEVELOPER = "DEVELOPER",
    RESET = "RESET",
    ABOUT = "ABOUT",
}

export enum LicensingType {
    USAGE = "USAGE",
    CLOUD = "CLOUD",
    DONGLES = "DONGLES",
    INTERNET_LICENSING = "INTERNET_LICENSING"
}

export interface LicenseTabs {
    usage?: boolean,
    cloud?: boolean,
    dongles?: boolean,
    internetLicensing?: boolean
}

export interface NavigationState {
    page: React.FC<any> | null,
    selected: boolean,
    isDrawerOpen: boolean,
    isMobile: boolean,
    settingType: SettingType,
    licensingType: LicensingType,
    licensingTabs: LicenseTabs
    optionsActive: boolean,
    isLoading: boolean,
    developerOptions: boolean,
    subjectUUID: string,
}
export const initNavigationState: NavigationState = {
    page: null,
    selected: false,
    isDrawerOpen: false,
    isMobile: false,
    settingType: SettingType.CAPTURING,
    licensingType: LicensingType.USAGE,
    licensingTabs: {usage: undefined, cloud: undefined, dongles: undefined, internetLicensing: undefined},
    optionsActive: false,
    isLoading: false,
    developerOptions: false,
    subjectUUID: "",
}