import React from "react";
import { ActionType, ClickMenu, ClosePage, NavigationActions, SetDeveloperOptions, SetLicensingCloudActive, SetLicensingDonglesActive, SetLicensingInternetLicenseActive, SetLicensingType, SetLicensingUsageActive, SetLoading, SetOptionsActive, SetSettingType, SetSubjectUUID } from "./actions";
import { LicensingType, NavigationState, SettingType } from "./state";

export const navigationReducer = (state: NavigationState, action: NavigationActions): NavigationState => {
    switch (action.type) {
        case ActionType.ClickMenu:
            return { ...state, page: action.page, selected: true, isDrawerOpen: false };
        case ActionType.ToggleDrawer:
            return { ...state, isDrawerOpen: !state.isDrawerOpen };
        case ActionType.ToggleMobileMenu:
            return { ...state, isMobile: action.toggle };
        case ActionType.ClosePage:
            return { ...state, selected: false };
        case ActionType.SetSettingType:
            return { ...state, settingType: action.settingType };
        case ActionType.SetLicensingType:
            return { ...state, licensingType: action.licensingType };
        case ActionType.SetLicensingUsageActive:
            return { ...state, licensingTabs: {...state.licensingTabs, usage: action.usage}};
        case ActionType.SetLicensingCloudActive:
            return { ...state, licensingTabs: {...state.licensingTabs, cloud: action.cloud}};
        case ActionType.SetLicensingDonglesActive:
            return { ...state, licensingTabs: {...state.licensingTabs, dongles: action.dongles}};
        case ActionType.SetLicensingInternetLicenseActive:
            return { ...state, licensingTabs: {...state.licensingTabs, internetLicensing: action.internetLicense}};
        case ActionType.SetOptionsActive:
            return { ...state, optionsActive: action.optionsActive};
        case ActionType.SetLoading:
            return { ...state, isLoading: action.isLoading};
        case ActionType.SetDeveloperOptions:
            return { ...state, developerOptions: action.developerOptions }
        case ActionType.SetSubjectUUID:
            return { ...state, subjectUUID: action.subjectUUID }
        default:
            return state;
    }
}

export const selectMenu = (page: React.FC<any>): ClickMenu => {
    return {
        type: ActionType.ClickMenu,
        page: page
    }
};

export const closePage = (): ClosePage => {
    return {
        type: ActionType.ClosePage
    }
};

export const setSettingPage = (settingType: SettingType): SetSettingType => {
    return {
        type: ActionType.SetSettingType,
        settingType: settingType
    }
};

export const setLicensingPage = (licensingType: LicensingType): SetLicensingType => {
    return {
        type: ActionType.SetLicensingType,
        licensingType: licensingType
    }
};

export const setLicensingUsageActive = (usage: boolean): SetLicensingUsageActive => {
    return {
        type: ActionType.SetLicensingUsageActive,
        usage: usage,
    }
};

export const setLicensingCloudActive = (cloud: boolean): SetLicensingCloudActive => {
    return {
        type: ActionType.SetLicensingCloudActive,
        cloud: cloud,
    }
};

export const setLicensingDonglesActive = (dongles: boolean): SetLicensingDonglesActive => {
    return {
        type: ActionType.SetLicensingDonglesActive,
        dongles: dongles,
    }
};

export const setLicensingInternetLicenseActive = (internetLicense: boolean): SetLicensingInternetLicenseActive => {
    return {
        type: ActionType.SetLicensingInternetLicenseActive,
        internetLicense: internetLicense,
    }
};

export const setOptionsActive = (optionsActive: boolean): SetOptionsActive => {
    return {
        type: ActionType.SetOptionsActive,
        optionsActive: optionsActive
    }
}

export const setLoading = (isLoading: boolean): SetLoading => {
    return {
        type: ActionType.SetLoading,
        isLoading: isLoading
    }
}

export const setDeveloperOptions = (developerOptions: boolean): SetDeveloperOptions => {
    return {
        type: ActionType.SetDeveloperOptions,
        developerOptions
    }
}

export const setSubjectUUID = (uuid: string): SetSubjectUUID => {
    return {
        type: ActionType.SetSubjectUUID,
        subjectUUID: uuid
    }
}
