import { BiometricDataApi, Configuration, ConfigurationParameters, ExceptionsApi, ImagesApi, JanusApi, LicensingApi, ManagementApi, OperationsApi, SubjectsApi } from "neurotec-megamatcherid-management-client";
import { APIsActionType, APIsActions } from "./actions";
import { APIsState } from "./state";

export const apisReducer = (state: APIsState, action: APIsActions): APIsState => {
    switch (action.type) {
        case APIsActionType.SetUpAPIs:
            return {
                JanusApi: new JanusApi(new Configuration(action.configuration)),
                ManagementApi: new ManagementApi(new Configuration(action.configuration)),
                SubjectsApi: new SubjectsApi(new Configuration(action.configuration)),
                OperationsApi: new OperationsApi(new Configuration(action.configuration)),
                ImagesApi: new ImagesApi(new Configuration(action.configuration)),
                LicensingApi: new LicensingApi(new Configuration(action.configuration)),
                ExceptionsApi: new ExceptionsApi(new Configuration(action.configuration)),
                BiometricDataApi: new BiometricDataApi(new Configuration(action.configuration))
            }
        default: 
            return state
    }
}

export const setUpApis = (configuration: ConfigurationParameters) => {
    return {
        type: APIsActionType.SetUpAPIs,
        configuration
    }
}