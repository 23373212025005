import { TableCell, TableRow, Typography, styled } from "@mui/material";
import { THEME } from "../../../config";


const TypographyTitleCell = styled(Typography)(({theme}) => ({
    fontSize: "0.9rem",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem"
    }
}))

const TypographyValueCell = styled(Typography)(({theme}) => ({
    fontSize: "0.9rem",
    fontWeight: "400",
    [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem"
    }
}))

interface ILicenseTableRow {
    title: string,
    value: string | number | undefined,
    status?: boolean
}

const LicenseTableRow: React.FC<ILicenseTableRow> = (props) => {

    const isStatus = (str: String | undefined | number) => {
        if (!props.status || (typeof str !== "string")) return THEME.palette.primary.dark
        if (str.toUpperCase() === "OK")
            return THEME.palette.primary.main
        else return THEME.palette.error.main
    }
    
    return(
        <TableRow style={{borderBottom: "none"}}>
            <TableCell style={{paddingTop: "8px", paddingBottom: "8px", paddingRight: "0px"}}>
                <TypographyTitleCell align="left">
                    {props.title}
                </TypographyTitleCell>
            </TableCell>
            <TableCell style={{paddingTop: "8px", paddingBottom: "8px", paddingLeft: "0px"}}>
                <TypographyValueCell style={{color: isStatus(props.value), fontWeight: props.status? "500" : "400"}} align="right">
                    {props.value}
                </TypographyValueCell>
            </TableCell>
        </TableRow>
    )
}

export default LicenseTableRow;