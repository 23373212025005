import { IconButton, TableCell } from "@mui/material"
import DataTableRow from "../DataTable/DataTableRow"
import DataTableCell from "../DataTable/DataTableCell"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useContext, useState } from "react";
import { APIsContext } from "../../../Store/APIs/context";
import { NavigationContext } from "../../../Store/Navigation/context";
import { setLoading } from "../../../Store/Navigation/reducer";
import { useSnackbar } from "notistack";
import CollapseCallStack from "./CollapseCallstack";

interface ICollapsibleDataTableExceptions {
    row: any,
    index: number
}

const CollapsibleDataTableExceptions: React.FC<ICollapsibleDataTableExceptions> = (props) => {

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const apisState = useContext(APIsContext).state
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [callstack, setCallStack] = useState<String>("")

    const handleOpenClick = (id: number) => {
        if (!open) {
            navigationDispatch(setLoading(true))
            apisState.ExceptionsApi.getExceptionsCallstackById({id: id}).then(res => {
                navigationDispatch(setLoading(false))
                setCallStack(res.data)
                setOpen(!open)
            }).catch(e => {
                console.log(e)
                enqueueSnackbar(e.message, {variant: "error"})
                navigationDispatch(setLoading(false))
            })
        } else {
            setOpen(!open)
        }
    }

    return (
        <>
            <DataTableRow
                hover
                tabIndex={-1}
                key={props.row.id}
                style={{backgroundColor: props.index % 2 === 0? "#fefefe" : "#fafafa"}}
            >
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {handleOpenClick(props.row["id"])}}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <DataTableCell onClick={() => {handleOpenClick(props.row["id"])}} value={props.row["date"]} type="date" align={"left"} />
                <DataTableCell onClick={() => {handleOpenClick(props.row["id"])}} value={props.row["exceptionName"]} type="exceptionName" align={"left"} />
                <DataTableCell onClick={() => {handleOpenClick(props.row["id"])}} value={props.row["httpResponseCode"]} type="HTTP" align={"left"} />
                <DataTableCell onClick={() => {handleOpenClick(props.row["id"])}} value={props.row["path"]} type="longstring" align={"left"} />
                <DataTableCell onClick={() => {handleOpenClick(props.row["id"])}} value={props.row["uuid"]} type="longstring" align={"left"} />
            </DataTableRow>
            <CollapseCallStack
                open={open}
                exceptionMessage={props.row["exceptionMessage"]}
                exceptionName={props.row["exceptionName"]}
                path={props.row["path"]}
                callstack={callstack}
                key={props.row.id * -1}
            />
        </>
    )
}

export default CollapsibleDataTableExceptions