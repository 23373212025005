import { Button, Container, Grid, Typography, styled } from "@mui/material"
import { useHistory } from "react-router-dom";
import { THEME } from "../../config";

const NumberTypography = styled(Typography)(() => ({
    marginTop: "4rem",
    fontSize: "4rem",
    fontWeight: "500",
    color: THEME.palette.primary.main
}));

const TitleTypography = styled(Typography)(() => ({
    fontSize: "2rem",
    color: THEME.palette.primary.main
}));

const SubtextTypography = styled(Typography)(() => ({
}));

const TryAgainButton = styled(Button)(() => ({
    marginTop: "1rem",
    textDecoration: "none",
    textTransform: "none",
    fontSize: "1rem"
}));


const PageNotFound: React.FC = () => {

    const history = useHistory()

    const handleButtonClick = () => {
        history.push("/")
    }
    
    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <NumberTypography align="center">
                        404
                    </NumberTypography>
                </Grid>
                <Grid item xs={12}>
                    <TitleTypography align="center">
                        Whoops. Page lost...
                    </TitleTypography>
                </Grid>
                <Grid item xs={12}>
                    <SubtextTypography align="center">
                        The page that you are looking for does not exist or has been moved.
                    </SubtextTypography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <TryAgainButton color="primary" variant="contained" onClick={handleButtonClick} >
                        Go home
                    </TryAgainButton>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PageNotFound