import { Button, TableCell, Paper, TableContainer, TableHead, TableRow } from "@mui/material"
import { SettingTable, settingUseStyles } from "../Settings"
import TextCell from "./TextCell";
import { makeStyles } from "@mui/styles"
import { useContext } from "react";
import { APIsContext } from "../../../Store/APIs/context";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
    tableCell: {
        width: "50%",
    },
    button: {
        opacity: "100%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
}))

interface IDownloadSettingsTable {
    isMobile: boolean,
}

const DownloadSettingsTable: React.FC<IDownloadSettingsTable> = (props) => {

    const classes = useStyles();
    const tableClasses = settingUseStyles()
    const state = useContext(APIsContext).state
    const { enqueueSnackbar } = useSnackbar();
    const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleDownloadSettings = () => {
        state.ManagementApi.getSettings().then(res => {
            state.ManagementApi.getManagementInfo().then(infoRes => {
                let settingsArray: {key: string, value: string}[] = []
                Object.entries(res.data).forEach(([key, value]) => {
                    settingsArray.push({ key, value })
                });
                let downloadInterface = {
                    date: formatDate(new Date()),
                    version: (infoRes.data?.version? infoRes.data?.version : "none").toString(),
                    revision: (infoRes.data?.revision? infoRes.data?.revison : "none").toString(),
                    settings: settingsArray
                };
    
                let atag = document.createElement("a");
                let file = new Blob([JSON.stringify(downloadInterface)], { type: "application/json" });
                atag.href = URL.createObjectURL(file);
                atag.download = "settings.json";
                atag.click();
            }).catch(e => {
                console.error(e)
                enqueueSnackbar("Unable to get setttings from management", {variant: "error"})             
            })
        }).catch(e => {
            console.error(e)
            enqueueSnackbar("Unable to get setttings from management", {variant: "error"})
        })
    }

    const getDialogCell = (span: number) => {
        return (
            <TableCell colSpan={span} className={classes.tableCell} align={"right"}>
                <Button className={classes.button} color="primary" onClick={handleDownloadSettings} variant="contained">
                    Download
                </Button>
            </TableCell>
        )
    }

    return (
        <TableContainer component={Paper} className={tableClasses.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell colspan={3} width="100%" head title="Download current settings" subtitle="Download current settings as json."/>
                        {getDialogCell(2)}
                    </TableRow>
                </TableHead>
            </SettingTable>
        </TableContainer>
    );
}

export default DownloadSettingsTable