import React, { useState } from 'react'
import { Checkbox, Table, TableBody, TableCell, TableContainer} from '@mui/material'
import EnhancedTableHead from './EnhancedTableHead';
import { IOperationFilter, OperationsFieldOrderBy } from '../../../Store/Operations/types';
import { FieldsOrder, ISubjectsFilter, SubjectsFieldOrderBy } from '../../../Store/Subjects/types';
import { useSnackbar } from 'notistack';
import { MultimodalSubject, Operation, SubjectModality } from 'neurotec-megamatcherid-management-client';
import DataTableCell from './DataTableCell';
import DataTableRow from './DataTableRow';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { handleReduceLivenessMode, handleReduceValue } from '../../../helpers/dataTableHelper';

export type Order = 'asc' | 'desc';
export type OrderByKey = 'id' | 'SUBJECT_ID' | 'SUBJECT_LIVENESS_SCORE' | 'SUBJECT_QUALITY' 
        | 'SUBJECT_STATUS' | 'SUBJECT_AGE' | 'SUBJECT_DATE' | 'SUBJECT_LIVENESS_MODE' | 'OPERATION_OPERATION_TYPE' | 'UUID'
        | 'OPERATION_DATE' | 'OPERATION_STATUS' | 'OPERATION_LIVENESS_MODE' | 'OPERATION_LIVENESS_SCORE' | 'OPERATION_AGE'
        | 'OPERATION_QUALITY' | 'EXCEPTION_UUID' | 'EXCEPTION_DATE' | 'EXCEPTION_HTTP_RESPONSE_CODE' | 'EXCEPTION_NAME' | 'EXCEPTION_MESSAGE' | 'EXCEPTION_PATH' | "OPERATION_MODALITY" | "SUBJECT_MODALITY";

export interface HeadCell {
    disablePadding: boolean;
    id: OrderByKey;
    label: string;
    align: 'left' | 'right';
    width?: string;
    identifier?: string;
    type?: string 
}

export interface IOperationId {
    id: number,
    uuid: string
}

interface IDataTableProps {
    data: MultimodalSubject[] | Operation[],
    filter: IOperationFilter | ISubjectsFilter,
    onFilterChange: (filter: any) => void,
    onRowClick?: (subject: any) => void,
    headCells: HeadCell[],
    title: string,
    dateName: string,
    setSelected: React.Dispatch<React.SetStateAction<IOperationId[]>>,
    selected: IOperationId[]
    type: "subjects" | "operations"
}

const DataTable: React.FC<IDataTableProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<OrderByKey>(props.type === "subjects"? "SUBJECT_ID" : "OPERATION_DATE");

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds: IOperationId[] = props.data.map((n: { id?: any; uuid?: any; }) => {return {id: n.id, uuid: n.uuid}});
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: OrderByKey) => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        switch (props.type) {
            case "subjects":
                let subjectFilter: ISubjectsFilter = {
                    ...props.filter,
                    subjectsFieldToOrderBy: SubjectsFieldOrderBy[property.toString() as keyof typeof SubjectsFieldOrderBy],
                    fieldsOrder: isAsc? FieldsOrder.ASC : FieldsOrder.DESC
                }
                props.onFilterChange(subjectFilter)
                break;
            case "operations":
                let operationFilter: IOperationFilter = {
                    ...props.filter as IOperationFilter,
                    operationsFieldToOrderBy: OperationsFieldOrderBy[property.toString() as keyof typeof OperationsFieldOrderBy],
                    fieldsOrder: isAsc? FieldsOrder.ASC : FieldsOrder.DESC
                }
                props.onFilterChange(operationFilter)
                break;
            default:
                enqueueSnackbar("Data table type not correct", {variant: "error"})
                break;
        }
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number, uuid: string) => {
        const selectedIndex = props.selected.findIndex(v => v.id === id)
        let newSelected: IOperationId[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selected, {id: id, uuid: uuid});
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selected.slice(1));
        } else if (selectedIndex === props.selected.length - 1) {
            newSelected = newSelected.concat(props.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                props.selected.slice(0, selectedIndex),
                props.selected.slice(selectedIndex + 1),
            );
        }
        props.setSelected(newSelected);
    };

    const isSelected = (id: number) => props.selected.findIndex(v => v.id === id) !== -1;

    const getModalityCell = (modality: SubjectModality | undefined, key?: number) => {
        switch (modality) {
            case "FACE_MODALITY":
                return (
                    <PermIdentityIcon key={key ?? undefined}/>
                )
            case "VOICE_MODALITY":
                return (
                    <GraphicEqIcon key={key ?? undefined}/>
                )
            default:
                return (
                    <QuestionMarkIcon key={key ?? undefined}/>
                )
        }
    }

    const showSubjectData = () => (
        <>
        {props.data.map((row: MultimodalSubject, index: number) => {
            const isItemSelected = isSelected(Number(row.id));
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
                <DataTableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    style={{backgroundColor: index % 2 === 0? "#fefefe" : "#fafafa"}}
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(event: any) => handleClick(event, Number(row.id), "")}
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell>
                    <TableCell onClick={() => props.onRowClick?.(row)} padding="checkbox">
                        {row.multimodalOperations?.map((o, index) => 
                            getModalityCell(o.modality? o.modality : undefined, index)
                        )}
                    </TableCell>
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.subjectId} type="string" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} type="date" align={"left"}
                        value={handleReduceValue(row.multimodalOperations?.map(o => o.date))} 
                    />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} type="number" align={"left"} 
                        value={handleReduceValue(row.multimodalOperations?.map(o => o.age))}
                    />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} type="number" align={"left"}
                        value={handleReduceValue(row.multimodalOperations?.map(o => o.quality))}
                    />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} type="liveness" align={"left"}
                        value={handleReduceLivenessMode(row.multimodalOperations?.map(o => o.livenessMode))}
                    />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} type="number" align={"left"}
                        value={handleReduceValue(row.multimodalOperations?.map(o => o.livenessScore))}
                    />
                </DataTableRow>
            )
        })}
        </>
    )

    const showOperationData = () => (
        <>
        {props.data.map((row: Operation, index: number) => {
            const isItemSelected = isSelected(Number(row.id));
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
                <DataTableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.uuid}
                    selected={isItemSelected}
                    style={{backgroundColor: index % 2 === 0? "#fefefe" : "#fafafa"}}
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(event: any) => handleClick(event, Number(row.id), row.uuid ?? "")}
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell>
                    <TableCell onClick={() => props.onRowClick?.(row)} padding="checkbox">
                        {getModalityCell(row.modality? row.modality : undefined)}
                    </TableCell>
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.date} type="date" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.status} type="status" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.operationType} type="type" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.livenessMode} type="liveness" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.livenessScore} type="number" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.age} type="number" align={"left"} />
                    <DataTableCell onClick={() => props.onRowClick?.(row)} value={row.quality} type="number" align={"left"} />
                </DataTableRow>
            )
        })}
        </>
    )
    
    const selectWhichTypeRepresent = () => {
        switch (props.type) {
            case "subjects":
                return showSubjectData();
            case "operations":
                return showOperationData();
            default:
                enqueueSnackbar("Data table type not correct", {variant: "error"})
                break;
        }
    }

    return (          
        <TableContainer>
            <Table size="small">
                <EnhancedTableHead
                    numSelected={props.selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={props.data.length}
                    headCells={props.headCells}
                    inputProps={props.title.toLowerCase()}
                />
                <TableBody>
                    {selectWhichTypeRepresent()}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DataTable
