import { IOperation, IcaoWarnings, LivenessMode, Modality, OperationType } from "../Operations/types";

export enum Status {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    TIMEOUT = "TIMEOUT",
    CANCELED = "CANCELED",
    BAD_QUALITY = "BAD_QUALITY",
    MATCH_NOT_FOUND = "MATCH_NOT_FOUND",
    CAMERA_NOT_FOUND = "CAMERA_NOT_FOUND",
    FACE_NOT_FOUND = "FACE_NOT_FOUND",
    LIVENESS_CHECK_FAILED = "LIVENESS_CHECK_FAILED",
    BAD_SHARPNESS = "BAD_SHARPNESS",
    TOO_NOISY = "TOO_NOISY",
    BAD_LIGHTNING = "BAD_LIGHTNING",
    OCCLUSION = "OCCLUSION",
    BAD_POSE = "BAD_POSE",
    TOO_MANY_OBJECTS = "TOO_MANY_OBJECTS",
    MASK_DETECTED = "MASK_DETECTED",
    DUPLICATE_FOUND = "DUPLICATE_FOUND",
    DUPLICATE_ID = "DUPLICATE_ID",
    MOTION_BLUR = "MOTION_BLUR",
    COMPRESSION_ARTIFACTS = "COMPRESSION_ARTIFACTS",
    TOO_DISTANT = "TOO_DISTANT",
    TOO_CLOSE = "TOO_CLOSE",
    FAILED = "FAILED",
    INITIALIZED = "INITIALIZED",
    JANUS_UNKNOWN_ERROR = "JANUS_UNKNOWN_ERROR",
	JANUS_PLUGIN_ERROR = "JANUS_PLUGIN_ERROR",
	JANUS_NOT_FOUND = "JANUS_NOT_FOUND",
	JANUS_BAD_REQUEST = "JANUS_BAD_REQUEST",
	JANUS_ICE_FAILED = "JANUS_ICE_FAILED",
    CHUNK_RECEIVE_TIMEOUT = "CHUNK_RECEIVE_TIMEOUT",
	STREAM_DECODE_FAILED = "STREAM_DECODE_FAILED",
    ADJUSTING_QUALITY = "ADJUSTING_QUALITY",
    INTERNAL_ERROR = "INTERNAL_ERROR"
}

export interface ISubject extends IOperation {
    id: number,
    subjectId: string,
    operationId: number,
}

export interface ISubjectsFilter {
    subjectsFieldToOrderBy?: SubjectsFieldOrderBy
    fieldsOrder?: FieldsOrder,

    subjectId?: string,
    livenessModes?: LivenessMode[],
    uuid?: string,
    icaoWarnings?: IcaoWarnings[],
    statuses?: Status[],
    operationTypes?: OperationType[],
    modalities?: Modality[], 

    dateLowerBound?: number,
    dateUpperBound?: number,
    yawLowerBound?: number,
    yawUpperBound?: number,
    rollLowerBound?: number,
    rollUpperBound?: number,
    pitchLowerBound?: number,
    pitchUpperBound?: number,
    livenessScoreLowerBound?: number,
    livenessScoreUpperBound?: number,
    qualityLowerBound?: number,
    qualityUpperBound?: number,
    ageLowerBound?: number,
    ageUpperBound?: number,
}

export interface NumberRange {
    lowerBound?: number,
    upperBound?: number
}

export interface DateRange {
    lowerBound?: Date,
    upperBound?: Date
}

export enum SubjectsFieldOrderBy {
    SUBJECT_ID = "SUBJECT_ID",
    SUBJECT_DATE = "SUBJECT_DATE",
    SUBJECT_AGE = "SUBJECT_AGE",
    SUBJECT_QUALITY = "SUBJECT_QUALITY",
    SUBJECT_LIVENESS_MODE = "SUBJECT_LIVENESS_MODE",
    SUBJECT_LIVENESS_SCORE = "SUBJECT_LIVENESS_SCORE",
    SUBJECT_MODALITY = "SUBJECT_MODALITY",
}

export enum FieldsOrder {
    ASC = "ASC",
    DESC = "DESC"
}
