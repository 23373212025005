export interface IStatus {
    status: LicensingStatusUpdate
}

export enum LicensingStatusUpdate {
    NONE = "NONE",
    FAILED = "FAILED",
    QUEUED = "QUEUED",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    UNKNOWN = "UNKNOWN"
}
