import { ThemeProvider } from '@mui/material/styles';
import { THEME } from './config';
import React, { useReducer } from 'react';
import './App.css';
import LoginPage from './containers/Login/LoginPage';
import { SnackbarProvider } from "notistack"
import { Redirect, Route, Router, Switch } from 'react-router';
import history from './config/history';
import PrivateRoute from './components/Route/PrivateRoute';
import Settings from './containers/Settings/Settings';
import Subjects from './containers/Subjects/Subjects';
import Licensing from './containers/Licensing/Licensing';
import configAxios from './config/axios';
import Operations from './containers/Operations/Operations';
import SnackbarStyled from './components/Misc/SnackBar/SnackbarStyled';
import { Box } from '@mui/material';
import Navigation from './components/Navigation/Navigation';
import PageNotFound from './components/PageNotFound/PageNotFound';
import { APIsContext } from './Store/APIs/context';
import { apisReducer } from './Store/APIs/reducer';
import { initAPIsState } from './Store/APIs/state';
import { navigationReducer } from './Store/Navigation/reducer';
import { initNavigationState } from './Store/Navigation/state';
import { NavigationContext } from './Store/Navigation/context';
import Exceptions from './containers/Exceptions/Exceptions';
import Janus from './containers/Janus/Janus';

configAxios();

const App: React.FC = () => {

  const [apisState, apisDispatch] = useReducer(apisReducer, initAPIsState);
  const [navigationState, navigationDispatch] = useReducer(navigationReducer, initNavigationState);

  return (
    <ThemeProvider theme={THEME}>
      <SnackbarProvider 
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        preventDuplicate
        Components={{
          success: SnackbarStyled,
          error: SnackbarStyled,
          warning: SnackbarStyled,
          info: SnackbarStyled,
        }}
      >
      <Box sx={{ display: "flex", flexDirection: "column"}}>
        <APIsContext.Provider value={{state: apisState, dispatch: apisDispatch}}>    
          <NavigationContext.Provider value={{ state: navigationState, dispatch: navigationDispatch }}>
            <Router history={history}>
              <Navigation />
              <Switch>
                <Route exact path="/login" component={LoginPage} />
                <PrivateRoute exact path='/' component={Subjects}/>
                <PrivateRoute exact path='/subjects' component={Subjects}/>
                <PrivateRoute exact path='/operations' component={Operations}/>
                <PrivateRoute exact path='/exceptions' component={Exceptions}/>
                <PrivateRoute exact path='/settings' component={Settings}/>
                <PrivateRoute exact path='/licensing' component={Licensing}/>
                <PrivateRoute exact path='/janus' component={Janus}/>
                  <Route exact path="*" render={() => <PageNotFound />} />
                  <Route>
                    <Redirect to="/404" />
                  </Route>
              </Switch>
              </Router>
            </NavigationContext.Provider>
          </APIsContext.Provider>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
