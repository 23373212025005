import { Container} from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from "react"
import OperationFilter from "../../components/Filter/OperationFilter"
import { MultimodalDownloadOption } from "../../components/OverviewView/ImageInfoDownload"
import DataTable, { HeadCell, IOperationId } from "../../components/Misc/DataTable/DataTable"
import { IOperationFilter, OperationsFieldOrderBy } from "../../Store/Operations/types"
import DatatableFooter from '../../components/Misc/DataTable/DatatableFooter'
import EnhancedTableToolbar from '../../components/Misc/DataTable/EnchancedTableToolbar'
import { useSnackbar } from 'notistack'
import { APIsContext } from '../../Store/APIs/context'
import { Operation } from 'neurotec-megamatcherid-management-client'
import fileDownload from 'js-file-download'
import { NavigationContext } from '../../Store/Navigation/context'
import { setLoading } from '../../Store/Navigation/reducer'
import OverviewDialog from '../../components/OverviewView/OverviewDialog'
import { FieldsOrder } from '../../Store/Subjects/types'


const headCells: HeadCell[] = [
    {
        id: 'OPERATION_MODALITY',
        align: 'left',
        disablePadding: true,
        label: 'Modality',
        width: "5%"
    },
    {
        id: 'OPERATION_DATE',
        align: 'left',
        disablePadding: false,
        label: 'Date',
        width: "23%"
    },
    {
        id: 'OPERATION_STATUS',
        align: 'left',
        disablePadding: false,
        label: 'Status',
        width: "15%"
    },
    {
        id: 'OPERATION_OPERATION_TYPE',
        align: 'left',
        disablePadding: false,
        label: 'Operation type',
        width: "15%"
    },
    {
        id: 'OPERATION_LIVENESS_MODE',
        align: 'left',
        disablePadding: false,
        label: 'Liveness mode',
        width: "14%"
    },
    {
        id: 'OPERATION_LIVENESS_SCORE',
        align: 'left',
        disablePadding: false,
        label: 'Liveness score',
        width: "9%"
    },
    {
        id: 'OPERATION_AGE',
        align: 'left',
        disablePadding: false,
        label: 'Estimated age',
        width: "9%"
    },
    {
        id: 'OPERATION_QUALITY',
        align: 'left',
        disablePadding: false,
        label: 'Quality',
        width: "9%"
    },
];

const Operations: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [operations, setOperations] = useState<Operation[]>([]);
    const [operationsFilter, setOperationsFilter] = useState<IOperationFilter>({fieldsOrder: FieldsOrder.DESC, operationsFieldToOrderBy: OperationsFieldOrderBy.OPERATION_DATE});
    const [operationsCount, setOperationsCount] = useState(0);
    const [showOperation, setShowOperation] = useState(false)
    const [selectedOperation, setSelectedOperation] = useState<Operation>();
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [selected, setSelected] = useState<IOperationId[]>([]);
    const { state } = useContext(APIsContext)

    const fetchOperation = useCallback(async () => {
        try {
            navigationDispatch(setLoading(true))
            state.OperationsApi.getLog({page: page, size: perPage, ...operationsFilter}).then(res => {
                setOperations(res.data)
                navigationDispatch(setLoading(false))
            }).catch(e => {
                console.log(e)
                enqueueSnackbar(e.message, {variant: "error"})
                navigationDispatch(setLoading(false))
            })

            state.OperationsApi.getLogCount(operationsFilter).then(res => {
                setOperationsCount(res.data)
            }).catch(e => {
                enqueueSnackbar(e.message, {variant: "error"})
            })

        } catch (error: any) {
            console.log(error)
            enqueueSnackbar(error.message, {variant: "error"})
        }
    }, [page, perPage, state.OperationsApi, operationsFilter, enqueueSnackbar, navigationDispatch])

    useEffect(() => {
        fetchOperation();
    }, [state.OperationsApi, operationsFilter, page, perPage, fetchOperation])

    const handlePageChange = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
        setPage(newPage)
    }

    const handleFilterChange = (filter: IOperationFilter) => {
        setPage(1)
        setOperationsFilter(filter)
    }

    const handleOperationClick = (operation: Operation) => {
        setShowOperation(true);
        setSelectedOperation(operation);
    }

    const handleDownload = (uuid: string, downloadOptions: Set<MultimodalDownloadOption>) => {
        // Do something about "response Type" blob :((
        state.BiometricDataApi.downloadBiometricDataByUUID({uuid: uuid, downloadOptions: downloadOptions}, {responseType: "blob"}).then(res => {
            fileDownload(res.data as any, "operation.zip");
        }).catch(err => {
            enqueueSnackbar("Unable to download", {variant: "error"})
            console.error(err)
        })
    } 

    const handleDownloadBulk = (downloadOptions: Set<MultimodalDownloadOption>) => {
        if (selected) {
            let idList: number[] = []
            selected.forEach(id => idList.push(id.id))
            state.BiometricDataApi.downloadBiometricDataBulkByOperation({ids: idList, downloadOptions: downloadOptions}, {responseType: "blob"}).then(res => {
                fileDownload(res.data as any, "operations.zip");
            }).catch(err => {
                enqueueSnackbar("Unable to download", {variant: "error"})
                console.error(err)
            })
        }
    }

    const handleOperationDelete = () => {
        if (selectedOperation && selectedOperation.uuid) {
            state.OperationsApi.deleteOperation({uuid: selectedOperation.uuid}).then(res => {
                enqueueSnackbar("Operation deleted successfully", { variant: 'success' });
                setSelectedOperation(undefined);
                setShowOperation(false)
                setOperationsFilter(operationsFilter)
                fetchOperation()
            }).catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' });
            })
        }
    }

    const handleBulkDelete = () => {
        let uuidList: string[] = []
        selected.forEach(id => uuidList.push(id.uuid))
        state.OperationsApi.deleteOperations({uuids: uuidList}).then(res => {
            if (res.data === 0) {
                enqueueSnackbar("Zero Operations deleted, Operations with operation type \"Create template\" can't be deleted ", { variant: 'warning' });
            } else {
                enqueueSnackbar("Selected " + res.data + " operations successfully deleted", { variant: 'info' });
            }
            setSelected([]);
            setOperationsFilter({})
        }).catch(err => {
            enqueueSnackbar(err.message, {variant: "error"})
        })
    }

    const getHeader = () => {
        return (
            selected.length > 0?
            <EnhancedTableToolbar 
                selected={selected.length} 
                onDeleteClick={handleBulkDelete} 
                onDownloadClick={handleDownloadBulk}
            />
            : 
            <OperationFilter
                filter={operationsFilter}
                onFilterApply={handleFilterChange}
            />
        )
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "column" }}>
                {getHeader()}
                <DataTable
                    data={operations}
                    filter={operationsFilter}
                    onFilterChange={handleFilterChange}
                    onRowClick={handleOperationClick}
                    headCells={headCells}
                    title={'Subjects'}
                    dateName={'date'}
                    setSelected={setSelected}
                    selected={selected}
                    type='operations'
                />
                <DatatableFooter
                    page={page}
                    handlePageChange={handlePageChange}
                    count={operationsCount}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    label='Operations'
                />
            </Container>
            <OverviewDialog
                singleOperation={selectedOperation}
                open={showOperation}
                onClose={() => setShowOperation(false)}
                onImageDelete={handleOperationDelete} 
                onImageDownload={handleDownload}
                isDeletable
                isOperation
            />
        </>
    )
}

export default Operations
