import { Box } from "@mui/system"
import { IOperationFilter } from "../../../../Store/Operations/types"
import { ISubjectsFilter } from "../../../../Store/Subjects/types"
import ChipFilter from "./ChipFilter"
import { useEffect, useState } from "react"
import { Button } from "@mui/material"

interface IChipFactoryProps {
    clearFilter: () => void,
    filter: ISubjectsFilter | IOperationFilter,
    setChip: (filter: ISubjectsFilter | IOperationFilter) => void
} 

const ChipFactory: React.FC<IChipFactoryProps> = (props) => {

    const [clearAllButton, setClearAllButton] = useState(false)

    useEffect(() => {
        let allEmpty: boolean = false;
        Object.keys(props.filter).forEach((f: string) => {
            if (props.filter[f as keyof typeof props.filter] !== undefined &&
                (props.filter[f as keyof typeof props.filter] as unknown as Array<number>).length > 0) {
                if (f !== "subjectsFieldToOrderBy" && f !== "fieldsOrder" && f !== "operationsFieldToOrderBy" && f !== "subjectId")
                allEmpty = true
            }
        })
        allEmpty ? setClearAllButton(true) : setClearAllButton(false)
    }, [props.filter, props])


    return (
        <Box style={{paddingTop: "1rem"}}>
            <ChipFilter
                title='Operation type'
                enumValue={props.filter.operationTypes}
                type={"enum"}
                onClick={() => props.setChip({...props.filter, operationTypes: undefined})}
            />
            <ChipFilter
                title='Liveness mode'
                enumValue={props.filter.livenessModes}
                type={"enum"}
                onClick={() => props.setChip({...props.filter, livenessModes: undefined})}
            />
            <ChipFilter
                title='Statuses'
                enumValue={props.filter.statuses}
                type={"enum"}
                onClick={() => props.setChip({...props.filter, statuses: undefined})}
            />
            <ChipFilter
                title='Icao warnings'
                enumValue={props.filter.icaoWarnings}
                type={"enum"}
                onClick={() => props.setChip({...props.filter, icaoWarnings: undefined})}
            />
            <ChipFilter
                title='Date'
                gteValue={props.filter.dateLowerBound}
                lteValue={props.filter.dateUpperBound}
                minValue={0}
                maxValue={Math.floor(new Date().getTime() / 1000)}
                type={"date"}
                onClick={() => props.setChip({...props.filter, dateLowerBound: undefined, dateUpperBound: undefined})}
            />
            <ChipFilter
                title='Quality'
                gteValue={props.filter.qualityLowerBound}
                lteValue={props.filter.qualityUpperBound}
                minValue={0}
                maxValue={255}
                type={"number"}
                onClick={() => props.setChip({...props.filter, qualityLowerBound: undefined, qualityUpperBound: undefined})}
            />
            <ChipFilter
                title='Liveness score'
                gteValue={props.filter.livenessScoreLowerBound}
                lteValue={props.filter.livenessScoreUpperBound}
                minValue={0}
                maxValue={255}
                type={"number"}
                onClick={() => props.setChip({...props.filter, livenessScoreLowerBound: undefined, livenessScoreUpperBound: undefined})}
            />
            <ChipFilter
                title='Age'
                gteValue={props.filter.ageLowerBound}
                lteValue={props.filter.ageUpperBound}
                minValue={0}
                maxValue={255}
                type={"number"}
                onClick={() => props.setChip({...props.filter, ageLowerBound: undefined, ageUpperBound: undefined})}
            />
            <ChipFilter
                title='Modality'
                enumValue={props.filter.modalities}
                type={"enum"}
                onClick={() => props.setChip({...props.filter, modalities: undefined})}
            />
            <ChipFilter
                title='Yaw'
                gteValue={props.filter.yawLowerBound}
                lteValue={props.filter.yawUpperBound}
                minValue={0}
                maxValue={255}
                type={"number"}
                onClick={() => props.setChip({...props.filter, yawLowerBound: undefined, yawUpperBound: undefined})}
            />
            <ChipFilter
                title='Roll'
                gteValue={props.filter.rollLowerBound}
                lteValue={props.filter.rollUpperBound}
                minValue={0}
                maxValue={255}
                type={"number"}
                onClick={() => props.setChip({...props.filter, rollLowerBound: undefined, rollUpperBound: undefined})}
            />
            <ChipFilter
                title='Pitch'
                gteValue={props.filter.pitchLowerBound}
                lteValue={props.filter.pitchUpperBound}
                minValue={0}
                maxValue={255}
                type={"number"}
                onClick={() => props.setChip({...props.filter, pitchLowerBound: undefined, pitchUpperBound: undefined})}
            />
            {clearAllButton ?
                <Button variant="text" color="primary" onClick={props.clearFilter}>
                    Clear all
                </Button> : null    
            }
       </Box>
    )
}

export default ChipFactory