import { Box, Dialog, DialogTitle, Grid, TextField } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DialogGridIcon from "./Components/DialogGridIcon";
import DialogGridText from "./Components/DialogGridText";
import DialogGridButton from "./Components/DialogGridButton";
import DialogButton from "./Components/DialogButton";
import { useState } from "react";

interface INumberEnteringDialog {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    title: string,
    cancelText: string,
    confirmText: string,
    confirm: (val: number) => void,
    minValue?: number,
    maxValue?: number
    confirmColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
}

const NumberEnteringDialog: React.FC<INumberEnteringDialog> = (props) => {

    const [value, setValue] = useState(0)

    const handleValueChange = (event: any) => {
        if (props.minValue !== undefined) {
            if (event.target.value as number < props.minValue) {
                setValue(props.minValue)
                return;
            }
        }
        if (props.maxValue !== undefined) {
            if (event.target.value as number > props.maxValue) {
                setValue(props.maxValue)
                return;
            }
        }
        setValue(event.target.value)
    }

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleConfirm = () => {
        props.confirm(value)
        props.setOpen(false)
    }

    return (
        <Dialog onClose={handleClose} open={props.open} maxWidth="xs">
            <DialogTitle>{props.title}</DialogTitle>
            <Grid container>
                <Grid container>
                    <DialogGridIcon item xs={2}>
                        <InfoOutlinedIcon style={{fontSize: 40}} />
                    </DialogGridIcon>
                    <DialogGridText item xs={10}>
                        <Box style={{paddingLeft: "1rem", paddingRight: "2rem"}}>
                            <TextField
                                value={value}
                                onChange={handleValueChange}
                                type="number"
                            />
                        </Box>
                    </DialogGridText>
                </Grid>
                <DialogGridButton container>
                    <Grid item xs={6} >
                        <DialogButton variant="outlined" color="secondary" onClick={handleClose}>
                            {props.cancelText}
                        </DialogButton>
                    </Grid>
                    <Grid item xs={6} >
                        <DialogButton color={props.confirmColor} variant="contained" onClick={handleConfirm}>
                            {props.confirmText}
                        </DialogButton>
                    </Grid>
                </DialogGridButton>
            </Grid>
        </Dialog>
    );
}

export default NumberEnteringDialog