import { Skeleton, Typography } from "@mui/material"
import { JanusStyledTableCell } from "./JanusStyledTableCell"

interface IJanusValueTableCellProps {
    value: any,
    loading?: boolean
}

const JanusValueTableCell: React.FC<IJanusValueTableCellProps> = (props) => {

    return (
        <JanusStyledTableCell style={{color: "#4E4E4E"}}>
            {props.loading? 
            <Skeleton/>
            :
            <Typography>
                {props.value ?? "-"}
            </Typography>
            }
        </JanusStyledTableCell>
    )
}

export default JanusValueTableCell