import { Box, Grid, Typography, styled } from "@mui/material";
import { Usage } from "neurotec-megamatcherid-management-client";
import { useCallback, useLayoutEffect, useRef } from "react";
import { THEME } from "../../../config";
import { capitalizeFirstLetterHelper, replaceAllHelper } from "../../../helpers/displayStringHelpers";

const lightBlue = "#1ca893"
const darkerBlue = "#477EB8"
const borderColor = "#ffffff"

const SmallCircles = styled(Box)(() => ({
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
}))

const PieChartBox = styled(Box)(({theme}) => ({
    width: "50%",
    paddingRight: "2rem",
    [theme.breakpoints.down("md")]: {
        paddingRight: "1rem"
    }
}))

interface IPieChartProps {
    usage: Usage,
    index: number
}

const PieChart: React.FC<IPieChartProps> = (props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLElement>(null);

    const draw = useCallback(() => {
        if (canvasRef.current && divRef.current) {
            const canvas = canvasRef.current
            const ctx = canvas.getContext("2d")
            if (ctx) {
                canvas.width = divRef.current.offsetWidth
                canvas.height = divRef.current.offsetHeight

                let percentage = 0
                if (props.usage.used && props.usage.limit) {
                    percentage = props.usage.used / props.usage.limit   
                }
                if (percentage < 0.005) percentage = 0.005

                ctx.beginPath();
                ctx.moveTo(canvas.width / 2, canvas.height / 2)
                ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, Math.PI * 2 * percentage); // Outer circle
                ctx.lineTo(canvas.width / 2, canvas.height / 2)
                ctx.fillStyle = lightBlue
                ctx.strokeStyle = borderColor
                ctx.lineWidth = 5
                ctx.stroke();
                ctx.fill()
              
                ctx.beginPath();
                ctx.moveTo(canvas.width / 2, canvas.height / 2)
                ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, Math.PI * 2 * percentage, Math.PI * 2); // Outer circle
                ctx.lineTo(canvas.width / 2, canvas.height / 2)
                ctx.fillStyle = darkerBlue
                ctx.strokeStyle = borderColor
                ctx.lineWidth = 5
                ctx.stroke();
                ctx.fill()
            }
        }
    }, [props])

    const handleTitle = (str: string | undefined) => {
        if (str === undefined) return ""
        let cutIndex = str.indexOf("_SERVER")
        if (cutIndex === -1) cutIndex = str.length
        return capitalizeFirstLetterHelper(replaceAllHelper(str.toLowerCase().slice(0, cutIndex), "_", " "))
    }

    const getSdkOrServer = (str: string | undefined) => {
        if (str === undefined) return ""
        return str.indexOf("_SERVER") === -1? "SDK" : "Server"
    }

    useLayoutEffect(() => {
        draw();
        window.addEventListener('resize', draw);
        return () =>
            window.removeEventListener('resize', draw);
    }, [draw])

    return (
        <PieChartBox key={props.index}>
            <Grid container style={{height: "100%", width: "100%"}}>
                <Grid item xs={12}
                    style={{
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "#D7D7D7",
                        display: "inline-flex",
                    }}>
                    <Grid item xs={8}>
                        <Typography align='left' style={{fontWeight: "500"}}>
                            {handleTitle(props.usage.operation)}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align='right' style={{color: THEME.palette.primary.main, fontWeight: "500"}}>
                            {getSdkOrServer(props.usage.operation)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1.5rem"}}>
                    <Box ref={divRef} style={{width: "100%", aspectRatio: "1 / 1"}}>
                        <canvas ref={canvasRef} style={{height: "100%", width: "100%", aspectRatio: "1 / 1"}} />
                    </Box>
                </Grid>
                <Grid item xs={12} style={{position: "relative"}}>
                    <Grid container style={{position: "absolute", top: "50%"}}>
                        <Grid item xs={12}>
                            <Typography component={'span'} style={{display: "inline-flex", color: "#303030"}}>
                                <SmallCircles style={{backgroundColor: lightBlue}} />&nbsp; Used: {props.usage.used} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={'span'} style={{display: "inline-flex", color: "#303030"}}>
                                <SmallCircles style={{backgroundColor: darkerBlue}} />&nbsp; Remaining: {props.usage.remaining}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PieChartBox>
    )
}

export default PieChart