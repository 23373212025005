import { Container } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import DataTable, { HeadCell, IOperationId } from '../../components/Misc/DataTable/DataTable';
import { ISubjectsFilter } from '../../Store/Subjects/types';
import { useSnackbar } from 'notistack';
import { APIsContext } from '../../Store/APIs/context';
import SubjectFilter from '../../components/Filter/SubjectFilter';
import { MultimodalSubject, Subject} from 'neurotec-megamatcherid-management-client';
import EnhancedTableToolbar from '../../components/Misc/DataTable/EnchancedTableToolbar';
import fileDownload from 'js-file-download';
import DatatableFooter from '../../components/Misc/DataTable/DatatableFooter';
import AddSubjectDialog from '../../components/AddSubject/AddSubjectDialog';
import { NavigationContext } from '../../Store/Navigation/context';
import { setLoading, setSubjectUUID } from '../../Store/Navigation/reducer';
import OverviewDialog from '../../components/OverviewView/OverviewDialog';
import { MultimodalDownloadOption } from '../../components/OverviewView/ImageInfoDownload';

const headCells: HeadCell[] = [
    {
        id: 'SUBJECT_MODALITY',
        align: 'left',
        disablePadding: true,
        label: 'Modality',
        width: "5%"
    },
    {
        id: 'SUBJECT_ID',
        align: 'left',
        disablePadding: true,
        label: 'Subject ID',
        width: "23%"
    },
    {
        id: 'SUBJECT_DATE',
        align: 'left',
        disablePadding: false,
        label: 'Date',
        width: "23%"
    },
    {
        id: 'SUBJECT_AGE',
        align: 'left',
        disablePadding: false,
        label: ' Estimated age',
        width: "9%"
    },
    {
        id: 'SUBJECT_QUALITY',
        align: 'left',
        disablePadding: false,
        label: 'Quality',
        width: "9%"
    },
    {
        id: 'SUBJECT_LIVENESS_MODE',
        align: 'left',
        disablePadding: false,
        label: 'Liveness mode',
        width: "15%"
    },
    {
        id: 'SUBJECT_LIVENESS_SCORE',
        align: 'left',
        disablePadding: false,
        label: 'Liveness score',
        width: "15%"
    },
];

const Subjects: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const navigationDispatch = useContext(NavigationContext).dispatch
    const navigationState = useContext(NavigationContext).state
    const [openCreateSubjectDialog, setOpenCreateSubjectDialog] = useState<boolean>(false)
    const [subjectsFilter, setSubjectsFilter] = useState<ISubjectsFilter>({});
    const [subjectCount, setSubjectCount] = useState(0);
    const [subjects, setSubjects] = useState<MultimodalSubject[]>([]);
    const [showSubject, setShowSubject] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState<MultimodalSubject>();
    const [selected, setSelected] = useState<IOperationId[]>([]);
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const apisState = useContext(APIsContext).state

    const fetchSubject = useCallback(async () => {
        try {
            navigationDispatch(setLoading(true))
            apisState.SubjectsApi.getMultimodalSubjects({page: page, size: perPage, ...subjectsFilter}).then(res => {
                setSubjects(res.data)
                navigationDispatch(setLoading(false))
            }).catch(e => {
                console.log(e)
                enqueueSnackbar(e.message, {variant: "error"})
                navigationDispatch(setLoading(false))
            })

            apisState.SubjectsApi.getSubjectsCount(subjectsFilter).then(res => {
                setSubjectCount(res.data)
            }).catch(e => {
                console.log(e)
                enqueueSnackbar(e.message, {variant: "error"})
            })

        } catch (error: any) {
            console.log(error)
            enqueueSnackbar(error.message, {variant: "error"})
        }
    }, [page, perPage, apisState.SubjectsApi, subjectsFilter, enqueueSnackbar, navigationDispatch])

    useEffect(() => {
        if (navigationState.subjectUUID) {
            setSubjectsFilter({uuid: navigationState.subjectUUID})
            navigationDispatch(setSubjectUUID(""))
        } else {
            fetchSubject();
        }
    }, [apisState.SubjectsApi, subjectsFilter, page, perPage, fetchSubject, navigationState.subjectUUID, navigationDispatch])

    const handlePageChange = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
        setPage(newPage)
    }

    const handleFilterChange = (filter: ISubjectsFilter) => {
        setPage(1)
        setSubjectsFilter(filter)
    }

    const handleSubjectClick = (subject: Subject) => {
        setShowSubject(true);
        setSelectedSubject(subject);
    }

    const handleAddSubjectClose = () => {
        setOpenCreateSubjectDialog(false)
    }

    const handleDownload = (subjectId: String, downloadOptions: Set<MultimodalDownloadOption>) => {
            apisState.BiometricDataApi.downloadBiometricDataBySubjectId({subjectId: subjectId as any as number, downloadOptions: downloadOptions}).then(res => {
                fileDownload(res.data as any, "subject.zip");
            }).catch(err => {
                enqueueSnackbar("Unable to download", {variant: "error"})
                console.error(err)
            })
    }

    const handleDownloadBulk = (downloadOptions: Set<MultimodalDownloadOption>) => {
        if (selected) {
            let idList: number[] = []
            selected.forEach(id => idList.push(id.id))
            apisState.BiometricDataApi.downloadSubjectsBiometricFaceDataBulkById({ids: idList, downloadOptions: downloadOptions}, {responseType: "blob"}).then(res => {
                fileDownload(res.data as any, "subjects.zip");
            }).catch(err => {
                enqueueSnackbar("Unable to download", {variant: "error"})
                console.error(err)
            })
        }
    }

    const handleSubjectDelete = () => {
        if (selectedSubject && selectedSubject.id) {
            apisState.SubjectsApi.deleteSubject({id: selectedSubject.id}).then(res => {
                enqueueSnackbar("Subject deleted successfully", { variant: 'success' });
                setSelectedSubject(undefined);
                setShowSubject(false)
                setSubjectsFilter(subjectsFilter)
                fetchSubject()
            }).catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' });
            })
        }
    }

    const handleBulkDelete = () => {
        let idList: number[] = []
        selected.forEach(id => idList.push(id.id))
        apisState.SubjectsApi.deleteSubjects({subjectIds: idList}).then(res => {
            enqueueSnackbar("Selected subjects successfully deleted", { variant: 'info' });
            setSelected([]);
            setSubjectsFilter({})
        }).catch(err => {
            enqueueSnackbar(err.message, {variant: "error"})
        })
    }

    const getHeader = () => {
        return (
            selected.length > 0?
            <EnhancedTableToolbar 
                selected={selected.length} 
                onDeleteClick={handleBulkDelete} 
                onDownloadClick={handleDownloadBulk}
            />
            :
            <SubjectFilter
                filter={subjectsFilter}
                onFilterApply={handleFilterChange}
                setOpenCreateSubjectDialog={setOpenCreateSubjectDialog}
            />
        )
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "column" }}>
                {getHeader()}
                <DataTable
                    data={subjects}
                    filter={subjectsFilter}
                    onFilterChange={handleFilterChange}
                    onRowClick={handleSubjectClick}
                    headCells={headCells}
                    title={'Subjects'}
                    dateName={'date'}
                    setSelected={setSelected}
                    selected={selected}
                    type='subjects'
                />
                <DatatableFooter
                    page={page}
                    handlePageChange={handlePageChange}
                    count={subjectCount}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    label='Subjects'
                />
            </Container>
            <OverviewDialog
                multimodalSubject={selectedSubject}
                open={showSubject}
                onClose={() => setShowSubject(false)}
                onImageDelete={handleSubjectDelete}
                onImageDownload={handleDownload}
                isDeletable
            />
            <AddSubjectDialog
                rerender={fetchSubject}
                open={openCreateSubjectDialog}
                onClose={handleAddSubjectClose}
             />
        </>
    )
}

export default Subjects
