import { Container, Grid, Theme, useMediaQuery, styled, TableRow, Button, TableCell, Table } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SettingsSidebar from "./SettingsSidebar";
import Verification from "./tabs/Verification";
import Capturing from "./tabs/Capturing";
import About from "./tabs/About";
import { MMIDAdvancedInitParams, MMIDInitParams } from "neurotec-megamatcherid-management-client";
import Liveness from "./tabs/Liveness";
import Management from "./tabs/Management";
import Reset from "./tabs/Reset";
import { ISliderInterface } from "./components/SliderCell";
import { useSnackbar } from "notistack";
import { ActionType } from "../../Store/Navigation/actions";
import { makeStyles } from "@mui/styles";
import { APIsContext } from "../../Store/APIs/context";
import Developer from "./tabs/Developer";
import ICAO from "./tabs/ICAO";
import { NavigationContext } from "../../Store/Navigation/context";

const GridBar = styled(Grid)(() => ({
    paddingTop: "2.1rem",
    position: "sticky",
    top: "max(7vh, 50px)",
    height: "fit-content"
}));

const GridTab = styled(Grid)(({ theme }) => ({
    paddingTop: "2rem",
    paddingLeft: "2rem",
    [theme.breakpoints.down("md")]: {
        paddingLeft: 0
    }
}));

export const SettingContainer = styled(Container)(({ theme }) => ({
    maxWidth: "97vw",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%"
    }
}));

export const SettingTable = styled(Table)(() => ({
    tableLayout: "fixed"
}))

export const settingUseStyles = makeStyles(() => ({
    container: {
        marginBottom: "2rem",
        tableLayout: "fixed"
    }
}))

export const SmallTextTableRow = styled(TableRow)(() => ({
    borderBottom: "none"
}))

export const SmallContentTableRow = styled(TableRow)(() => ({
    paddingLeft: "16px"
}))

export const NormalTableRow = styled(TableRow)(() => ({
    paddingLeft: "0px"
}))

export const DefaultButton = styled(Button)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        paddingRight: "0px"
    }
}))

export const TableCellButton = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
    }
}))

const fvMangementExceptionFilterPrefix = "fv.management.exceptions.filter."
const advancedPrefix = "fv.bio.advanced."
const initPrefix = "fv.bio.init."

export const initialFvParameters: MMIDInitParams = {
    livenessMode: 1,
    checkIcaoCompliance: false,
    timeout: 0,
    advancedParameters: [
        { key: "FramesToSkip", value: "100" },
        { key: "StrictQualityImageSize", value: "921600" },
        { key: "StrictQualityTimeout", value: "30000" }
    ]
}

export enum SettingType {
    CAPTURING = "CAPTURING",
    VERIFICATION = "VERIFICATION",
    LIVENESS = "LIVENESS",
    ICAO = "ICAO",
    MANAGEMENT = "MANAGEMENT",
    DEVELOPER = "DEVELOPER",
    RESET = "RESET",
    ABOUT = "ABOUT",
}

export interface ISetting {
    key: String,
    value: string | null
}
const Settings: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const apiState = useContext(APIsContext).state
    const { state, dispatch } = useContext(NavigationContext)
    const [managementSettings, setManagementSettings] = useState<MMIDAdvancedInitParams[]>([])
    const [defaultSettings, setDefaultSettings] = useState<MMIDAdvancedInitParams[]>([])
    const [setting, setSetting] = useState<SettingType>(state.settingType)
    const breakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        setSetting(state.settingType)
    }, [enqueueSnackbar, state])

    const fetchSettings = useCallback(async () => {
        try {
            const getSettingsData = (await apiState.ManagementApi.getSettings()).data
            let settingsArray: MMIDAdvancedInitParams[] = [];
            Object.entries(getSettingsData).forEach(([key, value]) => {
                settingsArray.push({ key, value })
            });
            setManagementSettings(settingsArray)

            const getDefaultSettings = (await apiState.ManagementApi.getDefaultProperties()).data
            setDefaultSettings(getDefaultSettings)
        } catch (error) {
            
        }
    }, [apiState.ManagementApi]);

    useEffect(() => {
        fetchSettings()
    }, [enqueueSnackbar, fetchSettings])

    const setSettingType = (type: SettingType) => {
        setSetting(type)
        dispatch({type: ActionType.SetSettingType, settingType: type})
    }

    const findDefaultOption = (option: string): MMIDAdvancedInitParams | undefined => {
        return defaultSettings.find((el) => el.key === option)
    }

    const findOption = (option: string) : MMIDAdvancedInitParams | undefined => {
        return managementSettings.find((el) => el.key?.includes(option))
    }

    const convertAdvancedToSliderInterface = (param: MMIDAdvancedInitParams) => {
        let key: string | undefined = undefined
        let value: number | undefined = undefined
        
        if (param.key)
            key = param.key
        if (param.value || param.value as unknown as number === 0)
            value = Number(param.value)
        if (key === undefined || value === undefined) {
            enqueueSnackbar("Could not convert advanced option", {variant: "error"})
            return undefined
        }
            
        return {key: key, value: value}
    }

    const findDefaultFVSliderInterface = (param: string): ISliderInterface | undefined => {
        let par = findDefaultOption(param)
        if (par) {
            return convertAdvancedToSliderInterface(par)
        } else {
            return undefined
        }
    }

    const findFVSliderInterface = (param: string): ISliderInterface | undefined => {
        let par = findOption(param)
        if (par) {
            return convertAdvancedToSliderInterface(par)
        } else {
            if (!defaultSettings || defaultSettings.length === 0)
                return undefined
            par = findDefaultOption(param)
            if (par) {
                return convertAdvancedToSliderInterface(par)
            } else {
                enqueueSnackbar("Could not get default properties", {variant: "error"})
            }
            return undefined
        }
    }

    const saveToManagementSettings = (key: string | undefined, value: string, prefix?: string) => {
        if (key === undefined) {
            enqueueSnackbar("Setting key is undefined", {variant: "error"})
            return;
        }
        if (key[0] === "f" && key[1] === "v") prefix = undefined;
        setAdvanceSetting(prefix ? prefix + key : key, value)
        if (findOption(key)) {
            const modifiedSettings = managementSettings.map((s) => {
                return s.key?.includes(key) ? {key: key, value: value} : s
            })
            setManagementSettings(modifiedSettings)
        } else {
            setManagementSettings([...managementSettings, {key: key, value: value}])
        }
    }

    const setAdvanceSetting = (key: string, value: string) => {
        apiState.ManagementApi.setSetting({key: key, value: value}).then(() => fetchSettings()).catch(err => enqueueSnackbar(err.message, {variant: "error"}))
    }

    const removeAdvancedSetting = (key: string) => {
        apiState.ManagementApi.deleteSetting({key: key}).then(() => {
            fetchSettings()
        }).catch(err => enqueueSnackbar(err.message, {variant: "error"}))
    }

    const resetValueToDefault = (name: string, basic?: boolean) => {
        if (basic)
            resetBasicValueToDefault(name)
        else
            resetAdvanceValueToDefault(name)
    }

    const resetBasicValueToDefault = (name: string) => {
        setAdvanceSetting(initPrefix + name, "0")
    }

    const resetAdvanceValueToDefault = (name: string) => {
        let defaultPar = findDefaultOption(name)
        if (defaultPar) {
            if (defaultPar.key && defaultPar.value) {
                saveToManagementSettings(defaultPar.key, defaultPar.value, advancedPrefix)
            } else {
                enqueueSnackbar("Default value or key is not defined", {variant: "error"})
            }
            if (name === "IcaoWarningsFilter") {
                let par = findOption(name)
                if (par) {
                    if (defaultPar.value) {
                        saveToManagementSettings(defaultPar.key, defaultPar.value, advancedPrefix)
                    } else {
                        saveToManagementSettings(defaultPar.key, "", advancedPrefix)
                    }
                }
            }
        } else {
            enqueueSnackbar("Could not get default properties", {variant: "error"})
        }
    }

    const setFVSliderInterfaceOption = (op: ISliderInterface, basic?: boolean | undefined) => {
        setOption(op.key, op.value, basic)
    }

    const setOption = (key: string, value: string | number | boolean, basic?: boolean) => {
        switch (typeof value) {
            case "string":
                saveToManagementSettings(key, value, basic? initPrefix : advancedPrefix)
                return;
            case "number":
                saveToManagementSettings(key, value.toString(), basic? initPrefix : advancedPrefix)
                return;
            case "boolean":
                saveToManagementSettings(key, value? "true" : "false", basic? initPrefix : advancedPrefix)
                return;
        }
    }

    const getLiveness = (): string => {
        let par = findOption("livenessMode")
        if (par) {
            if (par.value) return par.value
            saveToManagementSettings("livenessMode", "0", initPrefix)
            return "0"
        }
        else {
            saveToManagementSettings("livenessMode", "0", initPrefix)
            return "0"
        }
    }

    const getIcao = (): boolean => {
        let par = findOption("checkIcaoCompliance")
        if (par) {
            if (par.value)
                return par.value === "true" ? true : false
            setOption("checkIcaoCompliance", true, true)
            return true
        }
        else {
            setOption("checkIcaoCompliance", true, true)
            return true
        }
    }

    const getLivenessActionSequence = (): string => {
        let par = findOption("LivenessCustomActionSequence")?.value
        if (par !== undefined) {
            return par;
        } else {
            return ""
        }
    }

    const backgroundRemoval = () => {
        let par = findOption("IcaoRemoveBackground")?.value
        if (par !== undefined) {
            return par === "true" ? true : false
        }
        return false
    }

    const icaoWarningFilter = () => {
        let par = findOption("IcaoWarningsFilter")?.value
        if (par !== undefined) {
            return par
        }
        return ""
    }

    const getExceptionFilter = (type: "name" | "http" | "path"): string => {
        let par = findOption(fvMangementExceptionFilterPrefix + type)
        if (par?.value) {
            return par.value
        }
        else {
            return ""
        }
    }

    const setExceptionFilter = (type: "name" | "http" | "path", value: string) => {
        saveToManagementSettings(type, value, fvMangementExceptionFilterPrefix)
    }

    const resetAllParametersToDefault = () => {
        apiState.ManagementApi.resetSettings().catch(err => enqueueSnackbar(err.message, {variant: "error"}))
    }

    const getTab = (type: SettingType) => {
        switch(type) {
            case SettingType.CAPTURING:
                return <Capturing 
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setFVSliderInterfaceOption={setFVSliderInterfaceOption} 
                            timeout={undefined}
                            resetValueToDefault={resetValueToDefault}
                        />
            case SettingType.VERIFICATION:
                return <Verification 
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface} 
                            findFVSliderInterface={findFVSliderInterface} 
                            setFVSliderInterfaceOption={setFVSliderInterfaceOption} 
                            resetValueToDefault={resetValueToDefault}
                        />
            case SettingType.LIVENESS:
                return <Liveness
                            liveness={getLiveness()}
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setFVSliderInterfaceOption={setFVSliderInterfaceOption}
                            setOption={setOption}
                            resetValueToDefault={resetValueToDefault}
                            livenessCustomActionSequence={getLivenessActionSequence()}
                        />
            case SettingType.ICAO:
                return <ICAO 
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setFVSliderInterfaceOption={setFVSliderInterfaceOption}
                            resetValueToDefault={resetValueToDefault}
                            setOption={setOption}
                            icaoCompliance={getIcao()}
                            backgroundRemoval={backgroundRemoval()}
                            icaoWarningFilter={icaoWarningFilter()}
                        />
            case SettingType.MANAGEMENT:
                return <Management
                            hasClear
                            getExceptionFilter={getExceptionFilter}
                            setExceptionFilter={setExceptionFilter}
                        />
            case SettingType.DEVELOPER:
                return <Developer
                            setNewOption={(key: string, value: string) => setAdvanceSetting(key, value)} 
                            removeOption={(key: string) => removeAdvancedSetting(key)}
                            options={managementSettings} 
                        />
            case SettingType.RESET:
                return <Reset reset={resetAllParametersToDefault}/>
            case SettingType.ABOUT:
                return <About />
            default:
                return <Capturing
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setFVSliderInterfaceOption={setFVSliderInterfaceOption} 
                            timeout={undefined}
                            resetValueToDefault={resetValueToDefault}
                        />
        }
    }

    return (
        <SettingContainer>
            <Grid container style={{position: "relative"}}>
                <GridBar item md={2.5} xs={0}>
                {breakpoint?
                    <SettingsSidebar activeSetting={setting} setSettings={setSettingType} />
                    :
                    null
                }
                </GridBar>
                <GridTab item md={9.5} xs={12}>
                    {getTab(setting)}
                </GridTab>
            </Grid>
        </SettingContainer>
    );
}

export default Settings