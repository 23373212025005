import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material"
import ImageCanvas from "./ImageCanvas"
import {Image as NImage} from 'neurotec-megamatcherid-management-client';
import { THEME } from "../../config";
import DialogTitleClose from "../Misc/Dialog/Components/DialogTitleClose";

interface IImageDialogProps {
    image: void | NImage | undefined,
    open: boolean,
    onClose: () => void
    isTokenImage?: boolean,
}

const ImageDialog: React.FC<IImageDialogProps> = (props) => {
    
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogTitle>
                <DialogTitleClose title="Image" onClose={props.onClose}/>
            </DialogTitle>
            <DialogContent>
                <Box style={{width: "60vw", height: "70vh", maxWidth: THEME.breakpoints.values.lg}}>
                    <ImageCanvas image={props.image} isTokenImage={props.isTokenImage}/>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ImageDialog