import { Chip, ChipProps } from "@mui/material"
import { handleStringHelper } from "../../../../helpers/displayStringHelpers"

interface IChipFilter extends ChipProps{
    title: string,
    gteValue?: number,
    lteValue?: number,
    enumValue?: any[],
    minValue?: number,
    maxValue?: number,
    type: "number" | "date" | "enum"
    onClick: () => void
}

const ChipFilter: React.FC<IChipFilter> = (props) => {

    const getDateLabel = () => {
        if (props.gteValue !== undefined && props.lteValue !== undefined)
            return  props.title + ": " + new Date(props.gteValue).toLocaleString('en-GB') + "  -  "
                + new Date(props.lteValue).toLocaleString('en-GB')
        if (props.gteValue === undefined && props.lteValue !== undefined)
            return props.title + ": > " + new Date(props.lteValue).toLocaleString('en-GB')
        if (props.gteValue !== undefined && props.lteValue === undefined)
            return props.title + ": < " +  new Date(props.gteValue).toLocaleString('en-GB')
    }

    const getNumberLabel = () => {
        if (props.gteValue !== undefined && props.lteValue !== undefined)
            return  props.title + ": " + props.gteValue.toString() + "-" + props.lteValue.toString()
        if (props.gteValue === undefined && props.lteValue !== undefined)
            return props.title + ": " + (props.minValue ?? "0") + "-" + props.lteValue.toString()
        if (props.gteValue !== undefined && props.lteValue === undefined)
            return props.title + ": " + props.gteValue.toString() + "-" + (props.maxValue ?? "255")
    }

    const getEnumLabel = () => {
        if (props.enumValue && props.enumValue.length > 0) {
            let str: string = ""
            props.enumValue.forEach((e) => {
                if (str !== "") str += ", "
                str = str + handleStringHelper(e.toString())
            })
            return props.title + ": " + str
        }
    }

    const getLabel = () => {
        switch (props.type) {
            case "number":
                return getNumberLabel()
            case "date":
                return getDateLabel()
            case "enum":
                return getEnumLabel()
        }
    }

    return (
        (props.gteValue === undefined && props.lteValue === undefined) &&
        (props.enumValue === undefined || props.enumValue?.length <= 0)? null
            : <Chip style={{marginLeft: "0.5rem", marginBottom: "0.5rem"}} label={getLabel()} onDelete={props.onClick} />
    )
}

export default ChipFilter