import { Box, Button, Container, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { IStatus, LicensingStatusUpdate } from "../../Store/Licensing/types";
import { HttpError } from "../../config/types";
import { useSnackbar } from 'notistack';
import { APIsContext } from '../../Store/APIs/context';
import ButtonLightGray from '../Filter/Components/ButtonLightGray';

interface IDongleUpdateProbs {
    updateStatus: IStatus
    handleDongleUpdate: any
}

const DongleUpdate: React.FC<IDongleUpdateProbs> = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [updateTitle, setUpdateTitle] = useState<string>("Update")
    const [generateTitle, setGenerateTitle] = useState<string>("Generate dump");
    const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)
    const { state } = useContext(APIsContext)
    const [fileName, setFileName] = useState("")
    const [file, setFile] = useState<File>()

    useEffect(() => {
        const setInitButtons = () => {
            if (props.updateStatus.status === LicensingStatusUpdate.IN_PROGRESS || props.updateStatus.status === LicensingStatusUpdate.QUEUED) {
                setUpdateTitle("Updating...")
                setButtonsDisabled(true);
            }
            else {
                setUpdateTitle("Update")
                setButtonsDisabled(false);
            }
        }
        setInitButtons();
    }, [props.updateStatus])

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        setFileName(file.name)
        setFile(file);
    }

    const handleGenerateClick = async () => {
        setGenerateTitle("Generating...")
        setButtonsDisabled(true)
        state.LicensingApi.generateDongleDump().then(res => {
            setGenerateTitle("Generate dump")
            setButtonsDisabled(false)
            if (!res.data)
                return;
            let atag = document.createElement("a");
            let file = new Blob([res.data], { type: "text/plain" });
            atag.href = URL.createObjectURL(file);
            atag.download = "dump.dat";
            atag.click();
        }).catch((e: HttpError) => {
            if (e)
                enqueueSnackbar(e.status + ": " + e.message, { variant: 'error' });
            else 
                enqueueSnackbar("Cannot generate dongle dump, undefined error", { variant: 'error' });
        });
    };

    return (
        <Container style={{paddingTop: "1rem"}}>
            <Grid container>
                <Grid item xs={6} style={{borderStyle: "solid", borderWidth: "2px", borderColor: "#d7d7d7", borderRadius: "6px", padding: "2px" }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <ButtonLightGray component="label" style={{width: "100%"}} disabled={buttonsDisabled}>
                                Choose file
                                <input
                                    hidden
                                    type='file'
                                    onChange={handleFileUpload}
                                />
                            </ButtonLightGray>
                        </Grid>
                        <Grid item xs={12} md={8} style={{textOverflow: "clip", overflow: "hidden"}}>
                            <Box style={{display: "flex", alignItems: "center", height: "100%"}}>
                                <Typography style={{paddingLeft: "0.5rem", textOverflow: "clip"}}>
                                    {fileName === "" ? "No file chosen" : fileName}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} style={{display: "flex"}}>
                    <Grid container>
                        <Grid item xs={12} md={6} style={{marginBottom: isMobile? "0.5rem" : "0rem"}}>
                            <Box style={{
                                    display: "flex",
                                    justifyContent: isMobile? "right" : "left",
                                    marginBottom: isMobile? "0.5rem" : "0rem",
                                    height: "100%"
                                }}
                            >                            
                                <Button
                                    variant='contained'
                                    onClick={(e) => props.handleDongleUpdate(e, file)}
                                    style={{marginLeft: "1rem", height: "100%"}}
                                    disabled={buttonsDisabled}
                                >
                                    {updateTitle}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: "flex", gap: 1, justifyContent: "right", height: "100%"}}>
                                <ButtonLightGray onClick={handleGenerateClick} disabled={buttonsDisabled}>
                                    {generateTitle}
                                </ButtonLightGray>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default DongleUpdate