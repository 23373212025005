import { Box, Grid, Skeleton } from "@mui/material"

const SkeletonPieChart: React.FC = () => {
    
    return (
        <Box style={{width: "50%", paddingRight: "1rem"}}>
            <Grid container style={{height: "100%", width: "100%"}}>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" />
                </Grid>
                <Grid item xs={12} style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1.5rem"}}>
                    <Box style={{width: "100%", aspectRatio: "1 / 1"}}>
                        <Skeleton variant="circular" style={{width: "100%", height: "100%"}} />
                    </Box>
                </Grid>
                <Grid item xs={12} style={{position: "relative"}}>
                    <Skeleton variant="rectangular" style={{marginTop: "1rem"}}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SkeletonPieChart