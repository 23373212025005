import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Operation, OperationModality } from "neurotec-megamatcherid-management-client"
import OverviewRow from "./OverviewRow";

const evenColor = "#fefefe"
const oddColor = "#fafafa"

interface IOverviewProps {
    modality?: OperationModality
    operation: Operation
    isOperation?: boolean
}

const OverviewInfo: React.FC<IOverviewProps> = (props) => {
    return (
        <Table>
            <TableHead>
                <TableRow style={{paddingTop: "0px"}}>
                    <TableCell style={{paddingTop: "0px"}}>
                        Property
                    </TableCell>
                    <TableCell style={{paddingTop: "0px"}}>
                        Value
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <OverviewRow property="Insert date" value={props.operation.date} color={evenColor} type="date"/>
                {props.isOperation? <OverviewRow property="Operation type" value={props.operation.operationType?.toString()} color={oddColor} type="type"/> : null}
                {props.isOperation? <OverviewRow property="Status" value={props.operation.status?.toString()} color={evenColor} type="status"/> : null }
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Liveness mode" value={props.operation.livenessMode?.toString()} color={oddColor} type="liveness"/> : null}
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Estimated age" value={props.operation.age} color={evenColor} type="number"/> : null}
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Yaw" value={props.operation.yaw?.toFixed(2)?.toString()} color={oddColor} type="number"/> : null}
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Roll" value={props.operation.roll?.toFixed(2)?.toString()} color={evenColor} type="number"/> : null}
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Pitch" value={props.operation.pitch?.toFixed(2).toString()} color={oddColor} type="number"/> : null}
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Liveness target yaw" value={props.operation.livenessTargetYaw} color={evenColor} type="number"/> : null}
                {props.modality === "FACE_MODALITY"? <OverviewRow property="Liveness score" value={props.operation.livenessScore} color={oddColor} type="number"/> : null}
                <OverviewRow property="Quality" value={props.operation.quality} color={evenColor} type="number"/>
                <OverviewRow property="UUID" value={props.operation.uuid?.toString()} color={oddColor} type="longstring"/>
                <OverviewRow property="Modality type" value={props.operation.modality?.toString()} color={evenColor} type="modality"/>
            </TableBody>
        </Table>
    )
}

export default OverviewInfo