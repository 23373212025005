import { TableCell, Theme, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { THEME } from "../../../config"
import { handleDate, handleNumberHelper, handleStringFromLastOccurence, handleStringHelper, handleStringLengthHelper, selectHTTPColorHelper, selectStatusColorHelper } from "../../../helpers/displayStringHelpers"

interface DataTableCellProps {
    value: any,
    onClick?: () => void,
    align: "left" | "right"
    type: "number" | "string" | "date" | "longstring" | "type" | "status" | "liveness" | "HTTP" | "exceptionName"
}

const DataTableCell: React.FC<DataTableCellProps> = (props) => {
    
    const [color, setColor] = useState(THEME.palette.text.primary)
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (props.type === "status")
            setColor(selectStatusColorHelper(props.value))
        if (props.type === "HTTP")
            setColor(selectHTTPColorHelper(props.value))
    }, [props.value, props.type])

    const handleValue = () => {
        switch (props.type) {
            case "number":
                return (props.value !== undefined? handleNumberHelper(props.value) : "-")
            case "string":
                return (props.value !== undefined? handleStringLengthHelper(props.value) : "-")
            case "date":
                return (props.value !== undefined? handleDate(props.value, isMobile) : "-")
            case "longstring":
                return (props.value !== undefined && props.value !== ""? props.value : "-")
            case "type":
                return handleStringHelper(props.value)
            case "status":
                return handleStringHelper(props.value)
            case "liveness":
                return handleStringHelper(props.value)
            case "HTTP":
                return (props.value !== undefined? props.value : "-")
            case "exceptionName":
                return (props.value !== undefined? handleStringFromLastOccurence(props.value, ".") : "-")

        }
    }

    return (
        <TableCell
            onClick={props.onClick}
            align={props.align}
            style={{
                color: color,
                fontWeight: props.type === "status" || props.type === "HTTP" ? "500" : "400"
                }}
        >
            {handleValue()}
        </TableCell>
    )
}

export default DataTableCell