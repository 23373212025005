import { useContext, useEffect, useRef, useState } from "react"
import { IExceptionFilter } from "../../Store/Exceptions/types"
import { Box, Button, Grid, Typography, styled } from "@mui/material"
import FilterTextField from "./Components/FilterTextField"
import { GridButton } from "./Components/grid/GridButton"
import { BoxButton } from "./Components/BoxButton"
import ButtonLightGray from "./Components/ButtonLightGray"
import GridContainer from "./Components/grid/GridContainer"
import ExceptionDrawerMoreFilter from "./Components/drawer/ExceptionDrawerMoreFilter"
import { THEME } from "../../config"
import ConfirmationDialog from "../Misc/Dialog/ConfirmationDialog"
import { APIsContext } from "../../Store/APIs/context"
import { useSnackbar } from "notistack"
import CustomSwitch from "../Misc/CustomSwitch"
import ExportExceptionButton from "./Components/ExportExceptionButton"
import { NavigationContext } from "../../Store/Navigation/context"
import ImportExceptionButton from "./ImportExceptionButton"

const ClearDbButton = styled(Button)(() => ({
    fontWeight: 500,
    textTransform: "none",
    color: THEME.palette.text.secondary
}))

interface IExceptionFilterProps {
    onFilterApply: (filter: IExceptionFilter) => void
    filter: IExceptionFilter
    reloadFetch: () => void
}

const ExceptionFilter: React.FC<IExceptionFilterProps> = (props) => {

    const state = useContext(APIsContext).state
    const navigationState = useContext(NavigationContext).state
    const { enqueueSnackbar } = useSnackbar();
    const [exceptionFilter, setExceptionFilter] = useState<IExceptionFilter>(props.filter)
    const [regexValid, setRegexValid] = useState(true);
    const [openMoreFilters, setOpenMoreFilters] = useState<boolean>(false)
    const [clearDbOpen, setClearDbOpen] = useState<boolean>(false)
    const [autoRefreshSwitch, setAutoRefreshSwitch] = useState(false)
    const autoId = useRef<NodeJS.Timeout>();
    const [developerOptionsActive, setDeveloperOptionsActive] = useState(false)
    
    useEffect(() => {
        setDeveloperOptionsActive(navigationState.developerOptions)
    }, [navigationState.developerOptions])

    useEffect(() => {
        return(() => {
            clearInterval(autoId.current)
        })
    }, [])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            onFilterApply(exceptionFilter)
        }   
    }

    const handleExceptionUUIDChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setExceptionFilter({...exceptionFilter, uuid: event.target.value})
        try {
            new RegExp(event.target.value);
        } catch(e) {
            setRegexValid(false)
            return;
        }
        setRegexValid(true)
    }

    const handleClearExceptionDb = () => {
        state.ExceptionsApi.clearExceptionDb().then(res => {
            enqueueSnackbar("Successfully cleared exception database", {variant: "info"})
            resetFilter()
        }).catch((e: Error) => {
            if (e.message) {
                enqueueSnackbar(e.message, {variant: "error"})
            } else {
                enqueueSnackbar("Failed to clear exception database", {variant: "error"})
            }
        })
    }

    const handleAutoCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutoRefreshSwitch(!autoRefreshSwitch)
        if (e.target.checked) {
            autoId.current = setInterval(() => {
                props.reloadFetch()
            }, 5000)
        } else {
            clearInterval(autoId.current)
        }
    }

    const resetFilter = () => {
        setExceptionFilter({})
        props.onFilterApply({})
    }

    const onFilterApply = (filter: IExceptionFilter) => {
        props.onFilterApply(filter)
        setExceptionFilter(filter)
        setOpenMoreFilters(false)
    }

    return (
        <>
            <GridContainer container>
                <Grid item xs={12} md={5}>
                    <FilterTextField
                        variant='outlined'
                        spellCheck="false"
                        fullWidth
                        label="Regex"
                        size="small"
                        placeholder='Click to filter exception UUID'
                        value={exceptionFilter.uuid}
                        onChange={(event) => handleExceptionUUIDChange(event)}
                        error={!regexValid}
                        onClick={() => onFilterApply(exceptionFilter)}
                        onInputKeyDown={handleKeyDown}
                    />
                </Grid>
                <GridButton item xs={6} md={2}>
                    <BoxButton>
                        <ButtonLightGray
                            onClick={() => setOpenMoreFilters(true)}
                            variant='text'
                            color="primary"
                            style={{height: "100%"}}
                        >
                            More filters
                        </ButtonLightGray>
                    </BoxButton>
                </GridButton>
                <GridButton item xs={6} md={5} >
                    <Box justifyContent={"flex-end"} display={"flex"}>
                        <Box display="flex">
                            <CustomSwitch
                                state={autoRefreshSwitch}
                                handleChange={handleAutoCheckBox}
                            />
                            <Typography display={"inline-flex"} justifyContent={"center"} flexDirection={"column"}>
                                Auto refresh 
                            </Typography>
                        </Box>
                        {developerOptionsActive ? <ImportExceptionButton refresh={() => onFilterApply(exceptionFilter)}/> : null}
                        <ExportExceptionButton />
                        <BoxButton display="flex" justifyContent="flex-end">
                            <ClearDbButton
                                onClick={() => setClearDbOpen(true)}
                                color="error"
                                variant="contained"
                            >
                                Clear Exceptions
                            </ClearDbButton>
                        </BoxButton>
                    </Box>
                </GridButton>
            </GridContainer>
            <ExceptionDrawerMoreFilter
                anchor='right'
                filter={exceptionFilter}
                setFilter={setExceptionFilter}
                open={openMoreFilters}
                onClose={() => setOpenMoreFilters(false)}
                resetFilter={resetFilter}
                onFilterApply={onFilterApply}
            />
            <ConfirmationDialog
                open={clearDbOpen}
                setOpen={setClearDbOpen}
                title="Clear exceptions"
                cancelText="Cancel"
                confirmText="Yes, clear exceptions"
                confirm={handleClearExceptionDb}
                confirmColor="error"
                text="This action will clear all data in the exception log"
            />
        </>
    )
}

export default ExceptionFilter