import { Box, Button, Grid, Table, TableBody, TableContainer, Typography } from "@mui/material"
import JanusTypographyTitle from "./misc.tsx/JanusTypographyTitle"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import CustomSwitch from "../../../components/Misc/CustomSwitch"
import { APIsContext } from "../../../Store/APIs/context"
import { useSnackbar } from "notistack"
import JanusCollapsibleSessionRow from "./misc.tsx/JanusCollapsibleSessionRow"
import React from "react"
import LoopIcon from '@mui/icons-material/Loop';

const JanusSessions: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [sessionList, setSessionList] = useState<number[]>([])
    const state = useContext(APIsContext).state
    const [autoRefresh, setAutoRefresh] = useState(false)
    const [detailedInfo, setDetailedInfo] = useState(false)
    const autoId = useRef<NodeJS.Timeout>();

    const fetchSessionList = useCallback(() => {
        state.JanusApi.getListSession().then(res => {
            setSessionList(res.data)
        }).catch(() => {
            enqueueSnackbar("Unable to get janus session list", {variant: "error"})
        }
    )}, [state.JanusApi, enqueueSnackbar])

    useEffect(() => {
        fetchSessionList()
        return (() => {
            clearInterval(autoId.current)
        })
    }, [fetchSessionList])

    const handleAutoRefresh = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAutoRefresh(!autoRefresh)
        if (e.target.checked) {
            autoId.current = setInterval(() => {
                fetchSessionList()
            }, 2000)
        } else {
            clearInterval(autoId.current)
        }
    }

    const showData = () => (
        <>
        {sessionList.map((value: number, index: number) => {
            return (
                <JanusCollapsibleSessionRow
                    sessionId={value}
                    index={index}
                    rowKey={value}
                    key={value}
                    detailedInfo={detailedInfo}
                /> 
            )})}
        </>
    )


    return (
        <>
        <TableContainer
            style={{tableLayout: "fixed", paddingTop: "1rem"}}
        >
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: "1rem"}}>
                    <JanusTypographyTitle position={"relative"} display={"flex"} component={"span"}>
                        Sessions ({sessionList.length})
                        <Box position={"absolute"} right="0">
                            <Button
                                style={{paddingBottom: "0px", paddingTop: "0px"}}
                                variant="text"
                                color="primary"
                                startIcon={<LoopIcon />}
                                onClick={() => {fetchSessionList()}}
                            >
                                Refresh
                            </Button>
                        </Box>
                    </JanusTypographyTitle>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex">
                        <CustomSwitch
                            state={autoRefresh}
                            handleChange={handleAutoRefresh}
                        />
                        <Typography display={"inline-flex"} justifyContent={"center"} flexDirection={"column"}>
                            Auto refresh 
                        </Typography>
                    </Box>
                    <Box display="flex">
                        <CustomSwitch
                            state={detailedInfo}
                            handleChange={() => {setDetailedInfo(!detailedInfo)}}
                        />
                        <Typography display={"inline-flex"} justifyContent={"center"} flexDirection={"column"}>
                            Detailed info
                        </Typography>
                    </Box>
                    <Table size="small">
                        <TableBody>
                            {showData()}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </TableContainer>
        </>
    )
}

export default JanusSessions