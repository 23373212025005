import { OperationLivenessMode } from "neurotec-megamatcherid-management-client"

export const handleReduceValue = (array: (number | undefined)[] | undefined) => {
    if (array === undefined) return undefined
    let lenght = 0
    let value = array.reduce((previous, current) => {
        if (current === undefined || current === 254) return previous
        lenght = lenght + 1
        if (previous === undefined) return current
        return previous + current
    }, 0)

    if (value === undefined) return undefined
    if (lenght === 0) return undefined
    return value / lenght
}

export const handleReduceLivenessMode = (array: (OperationLivenessMode | undefined)[] | undefined) => {
    if (array === undefined) return undefined
    let value = array.find((l) => l !== undefined)
    return value
}