import { Dialog, DialogTitle, Grid } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DialogGridIcon from "./Components/DialogGridIcon";
import DialogGridText from "./Components/DialogGridText";
import DialogTypographyText from "./Components/DialogTypographyText";
import DialogGridButton from "./Components/DialogGridButton";
import DialogButton from "./Components/DialogButton";

interface IConfirmationDialog {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    title: string,
    text: string,
    cancelText: string,
    confirmText: string,
    confirm: () => void,
    confirmColor: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
}

const ConfirmationDialog: React.FC<IConfirmationDialog> = (props) => {

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleConfirm = () => {
        props.confirm()
        props.setOpen(false)
    }

    return (
        <Dialog onClose={handleClose} open={props.open} maxWidth="xs">
            <DialogTitle>{props.title}</DialogTitle>
            <Grid container>
                <Grid container>
                    <DialogGridIcon item xs={2}>
                        <InfoOutlinedIcon style={{fontSize: 40}} />
                    </DialogGridIcon>
                    <DialogGridText item xs={10}>
                        <DialogTypographyText>
                            {props.text}
                        </DialogTypographyText>
                    </DialogGridText>
                </Grid>
                <DialogGridButton container>
                    <Grid item xs={6} >
                        <DialogButton variant="outlined" color="secondary" onClick={handleClose}>
                            {props.cancelText}
                        </DialogButton>
                    </Grid>
                    <Grid item xs={6} >
                        <DialogButton color={props.confirmColor} variant="contained" onClick={handleConfirm}>
                            {props.confirmText}
                        </DialogButton>
                    </Grid>
                </DialogGridButton>
            </Grid>
        </Dialog>
    );
}

export default ConfirmationDialog