import { Box, ButtonProps, SvgIconProps } from "@mui/material";
import ConfirmationDialog from "./ConfirmationDialog";
import ButtonLightGray from "../../Filter/Components/ButtonLightGray";
import { useState } from "react";

interface IConfirmationButtonProps extends ButtonProps {
    icon?: React.ReactElement<SvgIconProps>
    buttonTitle: string,
    title: string,
    text: string,
    cancelText: string,
    confirmText: string,
    confirm: () => void,
    confirmColor: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning"
}

const ConfirmationButton: React.FC<IConfirmationButtonProps> = (props) => {
    
    const [open, setOpen] = useState<boolean>(false)

    const handleConfirm = () => {
        props.confirm()
        setOpen(false)
    }
    
    return (
        <Box style={{...props.style}}>
            <ButtonLightGray onClick={() => setOpen(true)} style={{height: "100%"}}>
                {props.icon ?? null}
                {props.buttonTitle}
            </ButtonLightGray>
            <ConfirmationDialog
                open={open}
                setOpen={setOpen}
                title={props.title}
                text={props.text}
                cancelText={props.cancelText}
                confirmText={props.confirmText}
                confirm={handleConfirm}
                confirmColor={props.confirmColor}
            />
        </Box>
    )
}

export default ConfirmationButton;