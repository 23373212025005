import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { BiometricVoiceData } from "neurotec-megamatcherid-management-client";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from 'wavesurfer.js'
import { THEME } from "../../config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const ContainerGrid = styled(Grid)(({ theme }) => ({
    marginTop: "1rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#e8e8e8",
    minHeight: "8rem"
}))

interface IAudioOperationSummary {
    audioInfo: BiometricVoiceData | null | void,
}

const AudioOperationSummary: React.FC<IAudioOperationSummary> = (props) => {

    const waveformRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false)
    const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | undefined>(undefined)

    console.log(props.audioInfo)

    const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
          
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
    const handleButtonClick = () => {
        setIsPlaying(!isPlaying)
        if (!isPlaying) {
            waveSurfer?.play()
        } else {
            waveSurfer?.pause()
        }
    }

    useEffect(() => {
      if(waveSurfer === undefined && waveformRef.current && props.audioInfo && props.audioInfo.audio) {
        const url = URL.createObjectURL(b64toBlob(props.audioInfo.audio, "audio/mpeg"))
         const waveSurfer = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: '#ffffff',
          progressColor: THEME.palette.primary.main,
          url: url,
          barWidth: 5,
          barRadius: 3,
          barGap: 1,
          height: "auto",
          barHeight: 1.2
        });
        waveSurfer.on("finish", () => {setIsPlaying(false)})
        setWaveSurfer(waveSurfer)
      }
    }, [props.audioInfo, waveSurfer]);


    return (
        <ContainerGrid container>
            {props.audioInfo?.audio && props.audioInfo?.audio?.length > 0 ? 
                <>
                <Box style={{alignSelf: "center", alignItems: "center", width: "3rem"}}>   
                    <Button
                        disableRipple
                        sx={{"&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent"}

                        }}
                        variant={"text"}
                        onClick={handleButtonClick}
                        style={{fontSize: "2rem"}}
                    >
                        {isPlaying? <PauseIcon/> : <PlayArrowIcon/>}
                    </Button>

                </Box>
                <Box style={{width: "calc(100% - 3rem)"}}>
                    <Box
                        style={{marginRight: "1rem"}}
                        ref={waveformRef}
                    />
                </Box>
                </>
                : (typeof props.audioInfo !== 'undefined')? <Typography textAlign="center">No audio</Typography> : <Typography textAlign="center">Loading...</Typography>}
        </ContainerGrid>
    )
}

export default AudioOperationSummary;