import { Typography, styled } from "@mui/material"

const FilterDrawerTitle = styled(Typography)(() => ({
    fontWeight: 500,
    paddingTop: "1rem",
    fontSize: "1.2rem",
    paddingLeft: "1rem",
    paddingRight: "1rem"
}))

export default FilterDrawerTitle