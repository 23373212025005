import { Grid, Table, TableBody, TableContainer } from "@mui/material"
import JanusTypographyTitle from "./misc.tsx/JanusTypographyTitle"
import { JanusStyledTableRow } from "./misc.tsx/styled/JanusStyledTableRow"
import { useContext, useEffect, useState } from "react"
import { APIsContext } from "../../../Store/APIs/context"
import JanusTitleTableCell from "./misc.tsx/styled/JanusTitleTableCell"
import JanusValueTableCell from "./misc.tsx/styled/JanusValueTableCell"
import { useSnackbar } from "notistack"

interface IJanusServerInfoProps {
}

const JanusServerInfo: React.FC<IJanusServerInfoProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const apisState = useContext(APIsContext).state
    const [status, setStatus] = useState<string>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        apisState.JanusApi.getInfo().then(res => {
            setStatus(res.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            enqueueSnackbar("Unable to get janus session list", {variant: "error"})
        })
    }, [apisState.JanusApi, enqueueSnackbar])

    const handleBoolean = (val: String) => {
        if (val === undefined) return "-"
        if (val) {
            return "True"
        } else {
            return "False"
        }
    }

    return (
        <>
        <TableContainer
            style={{tableLayout: "fixed", paddingTop: "1rem", paddingBottom: "1rem"}}
        >
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: "1rem"}}>
                    <JanusTypographyTitle>
                        Server info
                    </JanusTypographyTitle>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Version:"}/>
                                <JanusValueTableCell value={(status as any) ? (status as any)["version_string"] : "-"} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Accepting new sessions:"}/>
                                <JanusValueTableCell value={(status as any) ? handleBoolean((status as any)["accepting-new-sessions"]) : "-"} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Local IP:"}/>
                                <JanusValueTableCell value={(status as any) ? (status as any)["local-ip"]: "-"} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Public IP:"}/>
                                <JanusValueTableCell value={(status as any) ? (status as any)["public-ip"]: "-"} loading={loading}/>
                            </JanusStyledTableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </TableContainer>
        </>
    )
}

export default JanusServerInfo