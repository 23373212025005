import { Button } from "@mui/material"
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { APIsContext } from "../../../Store/APIs/context";

const ExportExceptionButton: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { state } = useContext(APIsContext)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [exportTitle, setExportTitle] = useState("Export")

    const handleExportClick = async () => {
        setExportTitle("Exporting...")
        setButtonsDisabled(true)
        state.ExceptionsApi.getAllExceptions().then(res => {
            setExportTitle("Export")
            setButtonsDisabled(false)
            if (!res.data)
                return;
            let atag = document.createElement("a");
            let file = new Blob([JSON.stringify(res.data)], { type: "application/json" });
            atag.href = URL.createObjectURL(file);
            atag.download = "exceptions.json";
            atag.click();
        }).catch((e: any) => {
            if (e)
                enqueueSnackbar(e.status + ": " + e.message, { variant: 'error' });
            else 
                enqueueSnackbar("Cannot export exceptions, undefined error", { variant: 'error' });
        });
    };

    return (
        <Button
            style={{marginLeft: "0.5rem"}}
            color="primary"
            variant="contained"
            onClick={handleExportClick}
            disabled={buttonsDisabled}
        >
            {exportTitle}
        </Button>
    )
}

export default ExportExceptionButton