import { Button, TableCell, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useState } from "react"
import { NormalTableRow, SmallContentTableRow, SmallTextTableRow } from "../Settings"
import TextCell from "./TextCell"
import ExceptionsFilterDialog from "./ExceptionsFilterDialog"

const useStyles = makeStyles(() => ({
    tableCell: {
        width: "50%",
    },
    button: {
        opacity: "100%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
    disabledButton: {
        opacity: "50%",
        backgroundImage: "linear-gradient(90deg, #5da3a5, #518382)",
    },
    typography: {
        color: "red"
    }
}))

interface IExceptionFilterRow {
    title: string,
    subtitle: string,
    isMobile: boolean,
    apply: (str: string) => void,
    value: string
    type: "name" | "http" | "path"
}

const ExceptionFilterRow: React.FC<IExceptionFilterRow> = (props) => {

    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false)

    const handleButtonPress = () => {
        setOpen(true)
    }

    const getDialogTitle = () => {
        switch (props.type) {
            case "name":
                return "Edit exception name filter sequence"
            case "http":
                return "Edit HTTP response filter sequence"
            case "path":
                return "Edit HTTP call path filter sequence"
        }
    }

    const getSequence = () => {
        if (props.isMobile) {
            if (props.value.length > 25)
                return props.value.substring(0, 22) + "..."
        } else {
            if (props.value.length > 50)
                return props.value.substring(0, 47) + "..."
        }
        return props.value
    }

    const getDialogCell = (span: number) => {
        return (
            <TableCell colSpan={span} className={classes.tableCell} align={"right"}>
                <Button className={classes.button} color="primary" onClick={handleButtonPress} variant="contained">
                    Edit sequence
                </Button>
            </TableCell>
        )
    }

    return (
        <>
        {props.isMobile? 
            <>
            <SmallTextTableRow>
                <TextCell colspan={3} title={props.title} subtitle={props.subtitle}/>
            </SmallTextTableRow>
            <SmallContentTableRow style={{borderBottom: "none"}}>
                {getDialogCell(3)}
            </SmallContentTableRow>
            <SmallContentTableRow>
                <TableCell colSpan={3} style={{paddingTop: "0px"}} >
                    <Typography style={{paddingLeft: "0px"}}>
                        <b>
                            Applied sequence:&nbsp;
                        </b>
                        {getSequence()}
                    </Typography>
                </TableCell>
            </SmallContentTableRow>
            </>
        :
            <>
            <NormalTableRow style={{borderBottom: "none"}}>
                <TextCell colspan={2} title={props.title} subtitle={props.subtitle}/>
                {getDialogCell(2)}
            </NormalTableRow>
            <NormalTableRow>
                <TableCell colSpan={4} style={{paddingTop: "0px"}} >
                    <Typography style={{paddingLeft: "2rem"}}>
                        <b>
                            Applied sequence:&nbsp;
                        </b>
                        {getSequence()}
                    </Typography>
                </TableCell>
            </NormalTableRow>
            </>
        }
        <ExceptionsFilterDialog
            title={getDialogTitle()}
            open={open}
            setOpen={setOpen}
            apply={props.apply}
            value={props.value}                
        />
        </>
    )
}

export default ExceptionFilterRow