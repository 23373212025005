import { FieldsOrder } from "../Subjects/types"

export interface IOperation {
    id: number,
    operationType: OperationType,
    status: Status,
    livenessMode: LivenessMode,
    date: number,
    uuid: string,
    imageId: number,
    yaw: number,
    roll: number,
    pitch: number,
    livenessTargetYaw: number,
    livenessScore: number,
    quality: number,
    boundingRect: IRectangle,
    age: number,
}

export interface IOperationFilter {
    operationsFieldToOrderBy?: OperationsFieldOrderBy
    fieldsOrder?: FieldsOrder,

    livenessModes?: LivenessMode[],
    uuid?: string,
    icaoWarnings?: IcaoWarnings[],
    operationTypes?: OperationType[],
    statuses?: Status[],
    modalities?: Modality[],

    dateLowerBound?: number,
    dateUpperBound?: number,
    yawLowerBound?: number,
    yawUpperBound?: number,
    rollLowerBound?: number,
    rollUpperBound?: number,
    pitchLowerBound?: number,
    pitchUpperBound?: number,
    livenessScoreLowerBound?: number,
    livenessScoreUpperBound?: number,
    qualityLowerBound?: number,
    qualityUpperBound?: number,
    ageLowerBound?: number,
    ageUpperBound?: number,
}

export enum OperationsFieldOrderBy {
    OPERATION_DATE = "OPERATION_DATE",
    OPERATION_STATUS = "OPERATION_STATUS",
    OPERATION_OPERATION_TYPE = "OPERATION_OPERATION_TYPE",
    OPERATION_LIVENESS_MODE = "OPERATION_LIVENESS_MODE",
    OPERATION_LIVENESS_SCORE = "OPERATION_LIVENESS_SCORE",
    OPERATION_AGE = "OPERATION_AGE",
    OPERATION_QUALITY = "OPERATION_QUALITY",
    OPERATION_MODALITY = "OPERATION_MODALITY"
}

export enum Status {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    TIMEOUT = "TIMEOUT",
    CANCELED = "CANCELED",
    BAD_QUALITY = "BAD_QUALITY",
    MATCH_NOT_FOUND = "MATCH_NOT_FOUND",
    CAMERA_NOT_FOUND = "CAMERA_NOT_FOUND",
    FACE_NOT_FOUND = "FACE_NOT_FOUND",
    LIVENESS_CHECK_FAILED = "LIVENESS_CHECK_FAILED",
    BAD_SHARPNESS = "BAD_SHARPNESS",
    TOO_NOISY = "TOO_NOISY",
    BAD_LIGHTNING = "BAD_LIGHTNING",
    OCCLUSION = "OCCLUSION",
    BAD_POSE = "BAD_POSE",
    TOO_MANY_OBJECTS = "TOO_MANY_OBJECTS",
    MASK_DETECTED = "MASK_DETECTED",
    DUPLICATE_FOUND = "DUPLICATE_FOUND",
    DUPLICATE_ID = "DUPLICATE_ID",
    MOTION_BLUR = "MOTION_BLUR",
    COMPRESSION_ARTIFACTS = "COMPRESSION_ARTIFACTS",
    TOO_DISTANT = "TOO_DISTANT",
    TOO_CLOSE = "TOO_CLOSE",
    FAILED = "FAILED",
    INITIALIZED = "INITIALIZED",
    JANUS_UNKNOWN_ERROR = "JANUS_UNKNOWN_ERROR",
    JANUS_PLUGIN_ERROR = "JANUS_PLUGIN_ERROR",
    JANUS_NOT_FOUND = "JANUS_NOT_FOUND",
    JANUS_BAD_REQUEST = "JANUS_BAD_REQUEST",
    JANUS_ICE_FAILED = "JANUS_ICE_FAILED",
    CHUNK_RECEIVE_TIMEOUT = "CHUNK_RECEIVE_TIMEOUT",
	STREAM_DECODE_FAILED = "STREAM_DECODE_FAILED",
    ADJUSTING_QUALITY = "ADJUSTING_QUALITY",
    INTERNAL_ERROR = "INTERNAL_ERROR",
}

export enum OperationType {
    UNSPECIFIED = "UNSPECIFIED",
    VERIFY = "VERIFY",
    CHECKS = "CHECKS",
    CREATE_TEMPLATE = "CREATE_TEMPLATE",
    REEXTRACT_TEMPLATE = "REEXTRACT_TEMPLATE",
}

export enum LivenessMode {
    DO_NOT_DETECT = "DO_NOT_DETECT",
    PASSIVE = "PASSIVE",
    ACTIVE = "ACTIVE",
    SIMPLE = "SIMPLE",
    CUSTOM = "CUSTOM",
    PASSIVE_WITH_BLINK = "PASSIVE_WITH_BLINK",
}

export enum IcaoWarnings {
    NO_WARNINGS = "NO_WARNINGS",
    FACE_NOT_DETECTED = "FACE_NOT_DETECTED",
    ROLL_LEFT = "ROLL_LEFT",
    ROLL_RIGHT = "ROLL_RIGHT",
    YAW_LEFT = "YAW_LEFT",
    YAW_RIGHT = "YAW_RIGHT",
    PITCH_UP = "PITCH_UP",
    PITCH_DOWN = "PITCH_DOWN",
    TOO_NEAR = "TOO_NEAR",
    TOO_FAR = "TOO_FAR",
    rTOO_NORTH = "TOO_NORTH",
    TOO_SOUTH = "TOO_SOUTH",
    TOO_EAST = "TOO_EAST",
    TOO_WEST = "TOO_WEST",
    SHARPNESS = "SHARPNESS",
    BACKGROUND_UNIFORMITY = "BACKGROUND_UNIFORMITY",
    GRAYSCALE_DENSITY = "GRAYSCALE_DENSITY",
    SATURATION = "SATURATION",
    EXPRESSION = "EXPRESSION",
    DARK_GLASSES = "DARK_GLASSES",
    EYES_CLOSED = "EYES_CLOSED",
    MOUTH_OPEN = "MOUTH_OPEN",
    LOOKING_AWAY = "LOOKING_AWAY",
    RED_EYE = "RED_EYE",
    FACE_DARKNESS = "FACE_DARKNESS",
    UNNATURAL_SKINTONE = "UNNATURAL_SKINTONE",
    WASHED_OUT = "WASHED_OUT",
    PIXELATION = "PIXELATION",
    SKIN_REFLECTION = "SKIN_REFLECTION",
    GLASSES_REFLECTION = "GLASSES_REFLECTION",
    HEAVY_FRAME = "HEAVY_FRAME",
    OCCLUSION_WARNING = "OCCLUSION_WARNING",
    RESOLUTION_WARNING = "RESOLUTION_WARNING",
    MOTION_BLUR_WARNING = "MOTION_BLUR_WARNING",
    COMPRESSION_ARTIFACTS_WARNING = "COMPRESSION_ARTIFACTS_WARNING",
    OVEREXPOSURE = "OVEREXPOSURE",
    UNDEREXPOSURE = "UNDEREXPOSURE",
    GLASSES = "GLASSES",
    HAT = "HAT",
    UNRECOGNIZED = "UNRECOGNIZED",
}

export enum Modality {
    FACE_MODALITY = "FACE_MODALITY",
    VOICE_MODALITY = "VOICE_MODALITY",
}

export interface IRectangle {
    x: number,
    y: number,
    width: number,
    height: number
}
