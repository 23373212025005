import { Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react";
import { handleStringHelper } from "../../../helpers/displayStringHelpers";
import { FilterTitle } from "./FilterTitle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
};

interface SelectCheckmarksProps {
    title: string,
    value: any[] | undefined,
    setValue: (values: any[]) => void
    type: any,
}

const SelectCheckmarks: React.FC<SelectCheckmarksProps> = (props) => {

    const getTypeValues = (): string[] => {
        return Object.keys(props.type).map((e) => {return e})
    }

    const [values, setValues] = useState<string[]>((props.value as string[]) ?? []);

    useEffect(() => {
        setValues(props.value as string[] ?? [])
    }, [props.value])

    const handleChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
        const { target: { value }} = event;
        if (typeof value === "string") {
            setValues([value])
            props.setValue([value])
        } else {
            setValues(value)
            props.setValue(value)
        }
    }

    const handleRenderValue = (str: string[]) => {
        if (str.length > 0) {
            return str.map(s => handleStringHelper(s)).join(', ')
        } else {
            return ""
        }
    }

    return (
        <Grid container style={{width: "100%", height: "100%"}}>
            <Grid item xs={12}>
                <FilterTitle>
                    {props.title}
                </FilterTitle>
            </Grid>
            <Grid item xs={12}>
                <Select
                    displayEmpty
                    style={{width: "100%", height: "100%"}}
                    value={values}
                    input={<OutlinedInput/>}
                    multiple
                    onChange={handleChange}
                    color="primary"
                    renderValue={handleRenderValue}
                    MenuProps={MenuProps}
                    inputProps={{"aria-label": "Without label"}}
                    >
                    {getTypeValues().map((e, i) => (
                        <MenuItem key={i} value={e}>
                            <Checkbox checked={values.findIndex((v) => v === e) > -1} color="primary" />
                            <ListItemText primary={handleStringHelper(e)} />
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    )
}

export default SelectCheckmarks