import { makeStyles } from "@mui/styles";
import { Grid, MenuItem, Select, SelectChangeEvent, TableCell, Tooltip, Typography, styled } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { THEME } from "../../../config";

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    titleDisabled: {
        opacity: "50%",
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    iconInfo: {
        marginLeft: "10px",
        position: "relative",
        top: "5px",
        color: THEME.palette.primary.main
    },
    select: {
        width: "95%"
    }
}))

const SelectTableCell = styled(TableCell)(({theme}) => ({
    paddingRight: "0px",
    paddingLeft: "0px",
    [theme.breakpoints.down("md")]: {
        paddingLeft: "16px"
    }
}))

export interface ISelectValue {
    key: string,
    value: string
}

interface ISelectCell {
    title: string,
    active: boolean,
    info?: string,
    colSpan?: number,
    menuItems: ISelectValue[],
    value: string,
    setValue: (value: string) => void,
}

const SelectCell: React.FC<ISelectCell> = (props) => {

    const classes = useStyles();

    const handleChange = (event: SelectChangeEvent) => {
        props.setValue(event.target.value)
    }

    return(
        <SelectTableCell colSpan={props.colSpan? props.colSpan : 2}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography component={'span'} className={props.active? classes.title : classes.titleDisabled}>
                        {props.title}
                        {props.info? 
                            <Tooltip disableFocusListener title={props.info} >
                                <div style={{display: "inline"}} >
                                    <HelpOutlineIcon className={classes.iconInfo} />
                                </div>
                            </Tooltip>
                        : null}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        disabled={!props.active}
                        value={props.value}
                        label="Liveness mode"
                        onChange={handleChange}
                        className={classes.select}
                        color="primary"
                        >
                        {props.menuItems.map((e, i) => (
                             <MenuItem key={i} value={e.value}>{e.key}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </SelectTableCell>
    );
}

export default SelectCell;