import { Box, Card, CardContent, Grid, Table, TableBody, TableContainer, Typography, styled } from "@mui/material"
import { Dongle } from "neurotec-megamatcherid-management-client"
import LicenseTableRow from "../components/LicenseTableRow"
import usbImage from "../../../assets/USB.png"
import theme from "../../../config/Theme"
import { THEME } from "../../../config"
import DongleUpdate from "../../../components/Licensing/DongleUpdate"
import { useContext, useState } from "react"
import { IStatus, LicensingStatusUpdate } from "../../../Store/Licensing/types"
import { useSnackbar } from "notistack"
import { APIsContext } from "../../../Store/APIs/context"

const CardInternetLicense = styled(Card)(() => ({
    width: "33%",
    minWidth: "14rem",
    marginBottom: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem"
}))

const TypographyTitle = styled(Typography)(()=>({
    fontWeight: "500",
    borderBottomStyle: "solid",
    borderBottomColor: "#D7D7D7"
}))

const BoxCorner = styled(Box)(() => ({
    backgroundColor: "red",
    aspectRatio: "1 / 1",
    height: "1.5rem",
    color: "white",
    borderRadius: "0px 0px 5px 0px",
    textAlign: "center",
    position: "absolute"
}))

const USBImage = styled('img')({
    width: "100%",
    height: "10rem",
    borderRadius: "5px",
    display: "block",
    margin: "auto",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
        width: "80%",
        height: "auto"
    }
}); 

interface IDonglesLicenseContainer {
    dongles: Dongle[]
}

const DonglesLicenseContainer: React.FC<IDonglesLicenseContainer> = (props) => {
    
    const [updateStatus, setUpdateStatus] = useState<IStatus>({status: LicensingStatusUpdate.NONE});
    const { state } = useContext(APIsContext)
    const { enqueueSnackbar } = useSnackbar();

    const handleDongleUpdate = async (event: React.FormEvent<HTMLFormElement>, file: File) => {
        event.preventDefault();
        if (!file) {
            enqueueSnackbar("Please choose a file for updating", {variant: "warning"})
            return
        }
        const data = file
        const content = await data.text();
        if (content) {
            try {
                let status = ((await state.LicensingApi.updateDongle({body: content})).data as unknown as IStatus)
                setUpdateStatus({status: status.status})
                const interval = setInterval(() => {
                    state.LicensingApi.getUpdateStatus().then(res => {
                        let uptStatus = (res.data as unknown as IStatus).status
                        setUpdateStatus({status: uptStatus as LicensingStatusUpdate});
                        if (uptStatus !== LicensingStatusUpdate.IN_PROGRESS && uptStatus !== LicensingStatusUpdate.QUEUED ) {
                            if (uptStatus === LicensingStatusUpdate.DONE)
                                enqueueSnackbar("Dongle updated finished succesfully", {variant: 'success'})
                            if (uptStatus === LicensingStatusUpdate.FAILED)
                                enqueueSnackbar("Dongle updated failed", {variant: "error"})
                            clearInterval(interval)
                        }
                    }).catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                        clearInterval(interval);
                    });
                }, 1000)
                if (status.status === LicensingStatusUpdate.QUEUED) {
                    enqueueSnackbar("Dongle update has been queued", { variant: 'info' });
                } else {
                    enqueueSnackbar("Dongle update status: " + status.status, { variant: 'info' });
                }
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        } else {
            enqueueSnackbar("Please select dongle update file", { variant: 'warning' });
        }
    }

    const isStatusOk = (str: string | undefined) => {
        if (str === undefined) return false
        if (str.toUpperCase() === "OK") return true
        else return false
    }
    
    const getCard = (dongle: Dongle, index: number) => {
        return (
            <CardContent style={{padding: "0px", position: "relative", paddingBottom: "8px"}}>
                <BoxCorner style={{backgroundColor: isStatusOk(dongle.status)? THEME.palette.primary.main : THEME.palette.error.main}}>
                    {index + 1}
                </BoxCorner>
                <USBImage src={usbImage} alt="" />
                <Table>
                    <TableBody>
                        <LicenseTableRow title="Dongle Id:" value={dongle.dongleId}/>
                        <LicenseTableRow title="Hardware:" value={dongle.hardwareId}/>
                        <LicenseTableRow title="Status:" value={dongle.status} status/>
                        <LicenseTableRow title="Application Id:" value={dongle.applicationId}/>
                        <LicenseTableRow title="Version:" value={dongle.dongleVersion}/>
                    </TableBody>
                </Table>
            </CardContent>
        )
    }

    return (
        <>
        <TableContainer
            style={{tableLayout: "fixed"}}
        >
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: "1rem"}}>
                    <TypographyTitle>
                        Dongles in use
                    </TypographyTitle>
                </Grid>
            </Grid>
        </TableContainer>
        <TableContainer style={{marginBottom: "2rem"}}>
            <Grid container>
                <Grid item xs={12} style={{display: "flex"}}>
                    {props.dongles.map((dongle, index) => 
                        <CardInternetLicense key={index} variant="outlined">
                            {getCard(dongle, index)}
                        </CardInternetLicense>
                    )}
                </Grid>
            </Grid>
        </TableContainer>
        <TableContainer
            style={{marginBottom: "2rem", tableLayout: "fixed"}}
        >
            <Grid container>
                <Grid item xs={12}>
                    <TypographyTitle>
                        Dongle update
                    </TypographyTitle>
                    <DongleUpdate updateStatus={updateStatus} handleDongleUpdate={handleDongleUpdate}/>
                </Grid>
            </Grid>
        </TableContainer>
        </>
    )
}

export default DonglesLicenseContainer