import { Grid, TextField } from "@mui/material"
import { FilterTitle } from "./FilterTitle"

interface IFilterDrawerMoreTextField {
    title: string,
    value: string | undefined
    setValue: (values: string) => void
}

const FilterDrawerMoreTextField: React.FC<IFilterDrawerMoreTextField> = (props) => {

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.setValue(event.target.value)
    }

    return (
        <Grid container style={{width: "100%", height: "100%"}}>
            <Grid item xs={12}>
                <FilterTitle>
                    {props.title}
                </FilterTitle>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={{margin: "none"}}
                    autoComplete="off"
                    id={props.title}
                    name={props.title}
                    label={props.title}
                    value={props.value ?? ""}
                    onChange={(event) => handleValueChange(event)}
                    inputProps={{
                        style: {paddingBottom: "0.5rem"}
                    }}
                    InputLabelProps={{
                        sx: {
                            top: "-7px",
                            "&.MuiInputLabel-shrink": {top: "0px"}
                        },
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default FilterDrawerMoreTextField