import React, { createContext } from "react";
import { NavigationActions } from "./actions";
import { initNavigationState, NavigationState } from "./state";

export const NavigationContext = createContext<{
    state: NavigationState,
    dispatch: React.Dispatch<NavigationActions>
}>({
    state: initNavigationState,
    dispatch: () => undefined
});