import { Box, Drawer, DrawerProps, Grid, IconButton, styled } from "@mui/material"
import DateTimeRange from "../DateTimeRange";
import ButtonGradient from "../ButtonGradient";
import ButtonLightGray from "../ButtonLightGray";
import { ISubjectsFilter, Status } from "../../../../Store/Subjects/types";
import { IOperationFilter, IcaoWarnings, LivenessMode, Modality, OperationType } from "../../../../Store/Operations/types";
import SelectCheckmarks from "../SelectCheckmarks";
import CloseIcon from '@mui/icons-material/Close';
import FilterDrawerTitle from "../FilterDrawerTitle";
import { FilterTitle } from "../FilterTitle";
import GridFilterRow from "../grid/GridFilterRow";
import { GridButtons } from "../grid/GridButtons";

const BoxButton = styled(Box)(() => ({
    padding: "5px 5px 5px 5px"
}))

interface IDrawerMoreFilters extends DrawerProps {
    type: "subject" | "operation"
    onFilterApply: (filter: ISubjectsFilter) => void,
    resetFilter: () => void,
    filter: ISubjectsFilter | IOperationFilter,
    setFilter: React.Dispatch<React.SetStateAction<ISubjectsFilter>> | React.Dispatch<React.SetStateAction<IOperationFilter>>
}

const DrawerMoreFilters: React.FC<IDrawerMoreFilters> = (props) => {

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            props.onFilterApply(props.filter)
        }
    }

    return (
        <Drawer
            anchor={props.anchor}
            open={props.open}
            onClose={props.onClose}
            style={{position: "relative"}}
            onKeyDown={(e) => handleKeyDown(e)}
        >
            <Box style={{maxWidth: "450px"}}>
                <Grid container>
                    <Grid item xs={8}>
                        <FilterDrawerTitle>
                            More Filters
                        </FilterDrawerTitle>
                    </Grid>
                    <Grid item xs={4} display={"inline-flex"} justifyContent={"flex-end"}>
                        <IconButton onClick={props.onClose as () => {}}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                    <Grid item xs={12}>
                        <FilterTitle>
                            Data ranges
                        </FilterTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeRange
                            from={props.filter.dateLowerBound}
                            to={props.filter.dateUpperBound}
                            onFromDateChange={(value) => props.setFilter({...props.filter, dateLowerBound: value})}
                            onToDateChange={(value) => props.setFilter({...props.filter, dateUpperBound: value})}
                        />
                    </Grid>
                    {props.type === "operation" ?
                        <SelectCheckmarks
                            title="Operation type"
                            value={props.filter.operationTypes}
                            setValue={(value) => props.setFilter({...props.filter, operationTypes: value})}
                            type={OperationType}
                        />
                    : null }
                    <SelectCheckmarks
                        title="Liveness mode"
                        value={props.filter.livenessModes}
                        setValue={(value) => props.setFilter({...props.filter, livenessModes: value})}
                        type={LivenessMode}
                    />
                    <SelectCheckmarks
                        title="Statuses"
                        value={props.filter.statuses}
                        setValue={(value) => props.setFilter({...props.filter, statuses: value})}
                        type={Status}
                    />
                    <SelectCheckmarks
                        title="Icao warnings"
                        value={props.filter.icaoWarnings}
                        setValue={(value) => props.setFilter({...props.filter, icaoWarnings: value})}
                        type={IcaoWarnings}
                    />
                    <SelectCheckmarks
                        title="Modality"
                        value={props.filter.modalities}
                        setValue={(value) => props.setFilter({...props.filter, modalities: value})}
                        type={Modality}
                    />
                    <GridFilterRow
                        title="Quality"
                        lowerBound={props.filter.qualityLowerBound}
                        upperBound={props.filter.qualityUpperBound}
                        setLowerBound={(value) => props.setFilter({...props.filter, qualityLowerBound: value})}
                        setUpperBound={(value) => props.setFilter({...props.filter, qualityUpperBound: value})}
                    />
                    <GridFilterRow
                        title="Liveness score"
                        lowerBound={props.filter.livenessScoreLowerBound}
                        upperBound={props.filter.livenessScoreUpperBound}
                        setLowerBound={(value) => props.setFilter({...props.filter, livenessScoreLowerBound: value})}
                        setUpperBound={(value) => props.setFilter({...props.filter, livenessScoreUpperBound: value})}
                    />
                    <GridFilterRow
                        title="Estimated age"
                        lowerBound={props.filter.ageLowerBound}
                        upperBound={props.filter.ageUpperBound}
                        setLowerBound={(value) => props.setFilter({...props.filter, ageLowerBound: value})}
                        setUpperBound={(value) => props.setFilter({...props.filter, ageUpperBound: value})}
                    />
                    <GridFilterRow
                        title="Yaw"
                        lowerBound={props.filter.yawLowerBound}
                        upperBound={props.filter.yawUpperBound}
                        setLowerBound={(value) => props.setFilter({...props.filter, yawLowerBound: value})}
                        setUpperBound={(value) => props.setFilter({...props.filter, yawUpperBound: value})}
                    />
                    <GridFilterRow
                        title="Roll"
                        lowerBound={props.filter.rollLowerBound}
                        upperBound={props.filter.rollUpperBound}
                        setLowerBound={(value) => props.setFilter({...props.filter, rollLowerBound: value})}
                        setUpperBound={(value) => props.setFilter({...props.filter, rollUpperBound: value})}
                    />
                    <GridFilterRow
                        title="Pitch"
                        lowerBound={props.filter.pitchLowerBound}
                        upperBound={props.filter.pitchUpperBound}
                        setLowerBound={(value) => props.setFilter({...props.filter, pitchLowerBound: value})}
                        setUpperBound={(value) => props.setFilter({...props.filter, pitchUpperBound: value})}
                    />
                </Grid>
                <Box style={{paddingBottom: "2rem"}} />
                <GridButtons container>
                    <Grid item xs={6} >
                        <BoxButton>
                            <ButtonLightGray
                                onClick={props.resetFilter}
                                variant='text'
                                color="primary"
                                style={{height: "100%", width: "100%"}}
                            >
                                Clear filter
                            </ButtonLightGray>
                        </BoxButton>
                    </Grid>
                    <Grid item xs={6}>
                        <BoxButton>    
                            <ButtonGradient
                                onClick={() => props.onFilterApply(props.filter)}
                                variant='contained'
                                color="primary"
                                style={{height: "100%", width: "100%"}}
                            >
                                Apply
                            </ButtonGradient>
                        </BoxButton>
                    </Grid>
                </GridButtons>
            </Box>
        </Drawer>
    )
}

export default DrawerMoreFilters