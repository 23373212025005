import { Button, Grid, Table, TableBody, TableContainer } from "@mui/material";
import JanusTypographyTitle from "./misc.tsx/JanusTypographyTitle";
import JanusTitleTableCell from "./misc.tsx/styled/JanusTitleTableCell";
import { JanusStyledTableRow } from "./misc.tsx/styled/JanusStyledTableRow";
import JanusValueTableCell from "./misc.tsx/styled/JanusValueTableCell";
import { APIsContext } from "../../../Store/APIs/context";
import { useCallback, useContext, useEffect, useState } from "react";
import { JanusAdminGetStatusResponse, JanusApiSetSettingsRequest } from "neurotec-megamatcherid-management-client";
import { JanusStyledTableCell } from "./misc.tsx/styled/JanusStyledTableCell";
import NumberEnteringDialog from "../../../components/Misc/Dialog/NumberEnteringDialog";
import { useSnackbar } from "notistack";

const JanusSettings: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const apisState = useContext(APIsContext).state
    const [status, setStatus] = useState<JanusAdminGetStatusResponse>()
    const [openSession, setOpenSession] = useState(false)
    const [openLogLevel, setOpenLogLevel] = useState(false)
    const [openMedia, setOpenMedia] = useState(false)
    const [loading, setLoading] = useState(true)

    const fetchData = useCallback(() => {
        apisState.JanusApi.getStatus().then(res => {
            setLoading(false)
            setStatus(res.data)
        }).catch(() => {
            setLoading(false)
            enqueueSnackbar("Unable to get janus settings", {variant: "error"})
        })
    }, [apisState.JanusApi, enqueueSnackbar])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleBooleans = (bool: boolean | undefined) => {
        if (bool === undefined) return undefined
        if (bool) {
            return "True"
        }
        return "False"
    }

    const handleButtonClick = (value: any, option: "timeout" | "level" | "noMediaTimer" | "timestamps" | "colors" | "lockingDebug" | "refDebug" | "libniceDebug") => {
        let request: JanusApiSetSettingsRequest;
        switch (option) {
            case "timeout":
                request = {timeout: value};
                break;
            case "level":
                request = {level: value};
                break;
            case "noMediaTimer":
                request = {noMediaTimer: value};
                break;
            case "timestamps":
                request = {timestamps: value};
                break;
            case "colors":
                request = {colors: value};
                break;
            case "lockingDebug":
                request = {lockingDebug: value};
                break;
            case "refDebug":
                request = {refDebug: value};
                break;
            case "libniceDebug":
                request = {libniceDebug: value};
                break;
        }
        apisState.JanusApi.setSettings(request).then(() => {
            fetchData()
        })
    }

    const editTableCell = (option: "timeout" | "level" | "noMediaTimer") => {
        return (
            <JanusStyledTableCell align="right">
                <Button
                    style={{paddingBottom: "0px", paddingTop: "0px"}}
                    variant="text"
                    color="primary"
                    onClick={() => {handleOnClickEdit(option)}}
                >
                    Edit
                </Button>
            </JanusStyledTableCell>
        )
    }

    const editBoolTableCell = (value: boolean | undefined, option: "timestamps" | "colors" | "lockingDebug" | "refDebug" | "libniceDebug") => {
        return (
            <JanusStyledTableCell align="right">
                <Button
                    style={{paddingBottom: "0px", paddingTop: "0px"}}
                    variant="text"
                    color={value? "error" : "primary"}
                    onClick={() => {handleButtonClick(!value, option)}}
                >
                    {value? "Disable" : "Enable"}
                </Button>
            </JanusStyledTableCell>
        )
    }

    const handleOnClickEdit = (option: "timeout" | "level" | "noMediaTimer") => {
        switch (option) {
            case "timeout":
                setOpenSession(true)
                break;
            case "level":
                setOpenLogLevel(true)
                break;
            case "noMediaTimer":
                setOpenMedia(true)
                break;
        }
    }

    return (
        <>
        <TableContainer
            style={{tableLayout: "fixed", paddingTop: "1rem", paddingBottom: "2rem"}}
        >
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: "1rem"}}>
                    <JanusTypographyTitle>
                        Server settings
                    </JanusTypographyTitle>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Token auth:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.token_auth)} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Session timeout:"}/>
                                <JanusValueTableCell value={status?.status?.session_timeout} loading={loading}/>
                                {editTableCell("timeout")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Reclaim session timout:"}/>
                                <JanusValueTableCell value={status?.status?.reclaim_session_timeout} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Candidates timeout:"}/>
                                <JanusValueTableCell value={status?.status?.candidates_timeout} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Log level:"}/>
                                <JanusValueTableCell value={status?.status?.log_level} loading={loading}/>
                                {editTableCell("level")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Log timestamps:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.log_timestamps)} loading={loading}/>
                                {editBoolTableCell(status?.status?.log_timestamps, "timestamps")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Log colors:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.log_colors)} loading={loading}/>
                                {editBoolTableCell(status?.status?.log_colors, "colors")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Locking debug:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.locking_debug)} loading={loading}/>
                                {editBoolTableCell(status?.status?.locking_debug, "lockingDebug")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Refcount debug:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.refcount_debug)} loading={loading}/>
                                {editBoolTableCell(status?.status?.refcount_debug, "refDebug")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Libnice debug:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.libnice_debug)} loading={loading}/>
                                {editBoolTableCell(status?.status?.libnice_debug, "libniceDebug")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Min nack queue:"}/>
                                <JanusValueTableCell value={status?.status?.min_nack_queue} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Nack optimizations:"}/>
                                <JanusValueTableCell value={handleBooleans(status?.status?.["nack-optimizations"])} loading={loading}/>
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"No media timer:"}/>
                                <JanusValueTableCell value={status?.status?.no_media_timer} loading={loading}/>
                                {editTableCell("noMediaTimer")}
                            </JanusStyledTableRow>
                            <JanusStyledTableRow>
                                <JanusTitleTableCell value={"Slowlink threshold:"}/>
                                <JanusValueTableCell value={status?.status?.slowlink_threshold} loading={loading}/>
                            </JanusStyledTableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </TableContainer>
        <NumberEnteringDialog
            open={openSession}
            setOpen={setOpenSession}
            title={"Set the new session timeout"}
            cancelText="Cancel"
            confirmText="Ok"
            confirmColor="primary"
            minValue={0}
            confirm={(val) => {handleButtonClick(val, "timeout")}}
        />
        <NumberEnteringDialog
            open={openLogLevel}
            setOpen={setOpenLogLevel}
            title={"Set the new desired log level"}
            cancelText="Cancel"
            confirmText="Ok"
            confirmColor="primary"
            minValue={0}
            maxValue={7}
            confirm={(val) => {handleButtonClick(val, "level")}}
        />
        <NumberEnteringDialog
            open={openMedia}
            setOpen={setOpenMedia}
            title={"Set the new desired no-media timer value"}
            cancelText="Cancel"
            confirmText="Ok"
            confirmColor="primary"
            minValue={0}
            confirm={(val) => {handleButtonClick(val, "noMediaTimer")}}
        />
        </>
    )
}

export default JanusSettings;