import { useContext, useEffect, useState } from "react";
import { NormalTableRow, SettingTable, SmallContentTableRow, SmallTextTableRow, settingUseStyles } from "../Settings";
import TextCell from "../components/TextCell";
import { Link, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, styled, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { APIsContext } from "../../../Store/APIs/context";
import { NavigationContext } from "../../../Store/Navigation/context";
import { setDeveloperOptions } from "../../../Store/Navigation/reducer";

const NameTableCell = styled(TableCell)(({theme}) => ({
    paddingLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "1rem"
    }
}));


const About: React.FC = () => {

    const classes = settingUseStyles();
    const { enqueueSnackbar } = useSnackbar();
    const APIstate = useContext(APIsContext).state
    const {state, dispatch} = useContext(NavigationContext)
    const [managementState, setManagementState] = useState<{[key: string]: object}>();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const [secretClicks, setSecretClicks] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const managementInfo = (await APIstate.ManagementApi.getManagementInfo()).data;
                if (!managementInfo) {
                    enqueueSnackbar("Could not get management info", {variant: "error"})
                    return;
                }
                setManagementState(managementInfo)
            } catch (error: any) {
                enqueueSnackbar(error.message, {variant: "error"})
            }
        }
        fetchData()
    }, [enqueueSnackbar, APIstate])

    useEffect(() => {
        if (secretClicks > 6 && !state.developerOptions) {
            dispatch(setDeveloperOptions(true))
            enqueueSnackbar("Developer options enabled", {variant: "info"})
        }
    }, [secretClicks, dispatch, enqueueSnackbar, state.developerOptions])

    const secretClick = () => {
        setSecretClicks(secretClicks + 1)
        setTimeout(() => {
            setSecretClicks(0)
        }, 5000)
    }

    const getColSpan = (): number => {
        if (isMobile)
            return 2
        if (isSmall)
            return 2;
        return 1
    }

    const showTableRow = (name: string, value: string, click?: () => void) => {
        if (isMobile) {
            return (
                <TableRow onClick={click? click : undefined}>
                    <NameTableCell width={"50%"} colSpan={getColSpan()}>
                        {name}
                    </NameTableCell>
                    <TableCell colSpan={3}>
                        {value}
                    </TableCell>
                </TableRow>
            );
        } if (isSmall) {
            return (
                <TableRow onClick={click? click : undefined}>
                    <NameTableCell width={"50%"} colSpan={getColSpan()}>
                        {name}
                    </NameTableCell>
                    <TableCell colSpan={3}>
                        {value}
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow onClick={click? click : undefined}>
                    <NameTableCell width={"25%"} colSpan={getColSpan()}>
                        {name}
                    </NameTableCell>
                    <TableCell colSpan={3}>
                        {value}
                    </TableCell>
                </TableRow>
            );
        }
    }

    const contactUsRow = () => {
        if (isMobile) {
            return (
                <>
                <SmallTextTableRow>
                    <TextCell colspan={5} title="Need help or want to report an issue?" subtitle="Contact us"/>
                </SmallTextTableRow>
                <SmallContentTableRow>
                    <TableCell align="center" colSpan={3}>
                        <Link style={{textDecoration: "none", color: "#477eb8", fontSize: "0.9rem", fontWeight: 500}} href="mailto:faceverification@neurotechnology.com">faceverification@neurotechnology.com</Link>
                    </TableCell>
                </SmallContentTableRow>
                </>
            )
        } else {
            return (
                <NormalTableRow>
                    <TextCell colspan={3} title="Need help or want to report an issue?" subtitle="Contact us"/>
                    <TableCell>
                        <Link style={{textDecoration: "none", color: "#477eb8", fontSize: "0.9rem", fontWeight: 500}} href="mailto:faceverification@neurotechnology.com">faceverification@neurotechnology.com</Link>
                    </TableCell>
                </NormalTableRow>
            )
        }
    }

    return(
        <TableContainer component={Paper} className={classes.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell colspan={5} head title="Mega Matcher Id Web demo" subtitle=""/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contactUsRow()}
                    {showTableRow("Version", (managementState?.version? managementState?.version : "none").toString(), secretClick)}
                    {showTableRow("Revision", (managementState?.revision? managementState?.revision : "none").toString().substring(0, 8))}
                </TableBody>
            </SettingTable>
        </TableContainer>
    );
}

export default About;
