import TextCell from "../components/TextCell";
import { Paper, TableBody, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import { useState} from "react";
import SwitchCell from "../components/SwitchCell";
import { ISliderInterface } from "../components/SliderCell";
import { ISelectValue } from "../components/SelectCell";
import { LivenessMode } from "../../../types/FaceVerification";
import ISOLivenessTable from "../components/ISOLivenessTable";
import DoubleInputCell from "../components/DoubleInputCell";
import { DefaultButton, NormalTableRow, SettingTable, SmallContentTableRow, SmallTextTableRow, TableCellButton, settingUseStyles } from "../Settings";
import SliderRow from "../components/SliderRow";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { useSnackbar } from "notistack";
import LivenessActionSequenceRow from "../components/LivenessActionSequenceRow";
import LivenessModeSelectRow from "../components/LivenessModeSelectRow";

interface ILiveness {
    liveness: string,
    findDefaultFVSliderInterface: (name: string) => ISliderInterface | undefined,
    findFVSliderInterface: (name: string) => (ISliderInterface | undefined),
    setFVSliderInterfaceOption: (op: ISliderInterface, basic?: boolean | undefined) => void
    setOption: (key: string, value: string | number | boolean, advanced?: boolean) => void
    resetValueToDefault: (name: string) => void,
    livenessCustomActionSequence: string
}

const Liveness: React.FC<ILiveness> = (props) => {
    
    const [selectLiveness, setSelectLiveness] = useState(Number(props.liveness) > 0? props.liveness : "1")
    const [active, setActive] = useState<boolean>(Number(props.liveness) > 0? true : false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [qualityImageSize, setQualityImageSize] = useState<[number, number]>([1280, 720])
    const classes = settingUseStyles();
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    const setLiveness = (liveness: boolean) => {
        setActive(liveness)
        if (liveness) {
            props.setOption("livenessMode", Number(selectLiveness), true)
        }
        else
        props.setOption("livenessMode", LivenessMode.NONE, true)
    }
    
    const getEnumValuesAndKey = (): ISelectValue[] => {
        return Object.keys(LivenessMode).map((e) => {
            if (Number(e) > 0) {
                return {key: LivenessMode[e as any], value: e}
            }
            return undefined
        }).filter((el) => el !== undefined) as ISelectValue[]
    }

    const handleResetClick = () => {
        enqueueSnackbar("Liveness settings reset", {variant: "info"})
        props.resetValueToDefault("LivenessThreshold")
        props.resetValueToDefault("PassiveLivenessSensitivityThreshold")
        props.resetValueToDefault("PassiveLivenessQualityThreshold")
        props.resetValueToDefault("LivenessBlinkTimeout")
        props.resetValueToDefault("StrictQualityTimeout")
        setQualityImageSize([1280, 720])
        handleQualityImageSetting(1280, 720)
        handleSetLivenessMode("1")
    }

    const handleSetLivenessMode = (mode: string) => {
        setSelectLiveness(mode)
        props.setOption("livenessMode", Number(mode), true)
    }

    const handleQualityImageSetting = (w: number, h: number) => {
        props.setOption("StrictQualityImageSize", (w * h))
    }

    const handleLivenessActionSequenceApply = (string: string) => {
        props.setOption("LivenessCustomActionSequence", string)
    }

    const doubleInputRow = () => {
        if (isMobile) {
            return (
                <>
                <SmallTextTableRow>
                    <TextCell colspan={3} title="Strict Quality Image size" subtitle="Minimal frame pixel count to start detection. E.g. 480x640 would be 307200." />
                </SmallTextTableRow>
                <SmallContentTableRow>
                    <DoubleInputCell
                        qualityImageSize={qualityImageSize}
                        setQualityImageSize={setQualityImageSize}
                        title="Image size"
                        active={active && (selectLiveness === "1" || selectLiveness === "6")}
                        colSpan={3}
                        saveSetting={handleQualityImageSetting}
                    />
                </SmallContentTableRow>
                </>
            )
        } else {
            return (
                <NormalTableRow>
                    <TextCell title="Strict Quality Image size" subtitle="Minimal frame pixel count to start detection. E.g. 480x640 would be 307200." />
                    <DoubleInputCell
                        qualityImageSize={qualityImageSize}
                        setQualityImageSize={setQualityImageSize}
                        title="Image size"
                        active={active && (selectLiveness === "1" || selectLiveness === "6")}
                        colSpan={3}
                        saveSetting={handleQualityImageSetting}
                    />
                </NormalTableRow>
            )
        }
    }

    return(
        <>
            <TableContainer component={Paper} className={classes.container}>
                <SettingTable>
                    <TableHead>
                        <TableRow>
                            <TextCell head title="Liveness" subtitle=""/>
                            <TableCellButton align="right" colSpan={isMobile? 1 : 2}>
                                <DefaultButton variant="text" color="primary" onClick={() => setOpenDialog(true)}>
                                    Reset to default
                                </DefaultButton>
                            </TableCellButton>
                            <SwitchCell state={active} setState={setLiveness}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LivenessModeSelectRow
                            isMobile={isMobile}
                            active={active}
                            getEnumValuesAndKey={getEnumValuesAndKey}
                            selectLiveness={selectLiveness}
                            handleSetLivenessMode={handleSetLivenessMode}
                        />
                        <LivenessActionSequenceRow
                            isMobile={isMobile}
                            active={active}
                            options={props.livenessCustomActionSequence}
                            apply={handleLivenessActionSequenceApply}
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Liveness threshold"
                            subtitle="Determines if liveness prediction is confident enough."
                            title="Threshold range"
                            option={props.findFVSliderInterface("LivenessThreshold")}
                            defaultOption={props.findDefaultFVSliderInterface("LivenessThreshold")}
                            setOption={props.setFVSliderInterfaceOption}
                            minValue={0}
                            maxValue={100}
                            type="raising"
                            active={active}
                            lockMaxValue
                            noWarningMsg="Everything will pass"
                            alwaysWarningMsg="Nothing will pass"
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Passive liveness sensitivity"
                            subtitle="Regulates the positioning restrictions."
                            title="Threshold range"
                            option={props.findFVSliderInterface("PassiveLivenessSensitivityThreshold")}
                            defaultOption={props.findDefaultFVSliderInterface("PassiveLivenessSensitivityThreshold")}
                            setOption={props.setFVSliderInterfaceOption}
                            minValue={0}
                            maxValue={100}
                            type="raising"
                            active={active}
                            lockMaxValue
                            noWarningMsg="Everything will pass"
                            alwaysWarningMsg="Nothing will pass"
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Passive liveness quality"
                            subtitle="Determines if image quality is suitable for liveness check."
                            title="Threshold range"
                            option={props.findFVSliderInterface("PassiveLivenessQualityThreshold")}
                            defaultOption={props.findDefaultFVSliderInterface("PassiveLivenessQualityThreshold")}
                            setOption={props.setFVSliderInterfaceOption}
                            minValue={0}
                            maxValue={100}
                            type="raising"
                            active={active}
                            lockMaxValue
                            noWarningMsg="Everything will pass"
                            alwaysWarningMsg="Nothing will pass"
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Liveness blink timeout"
                            subtitle="Maximum time in ms when user has to blink when asked."
                            title="Threshold range"
                            option={props.findFVSliderInterface("LivenessBlinkTimeout")}
                            defaultOption={props.findDefaultFVSliderInterface("LivenessBlinkTimeout")}
                            setOption={props.setFVSliderInterfaceOption}
                            minValue={0}
                            maxValue={60000}
                            type="static"
                            active={active}
                            lockMaxValue
                            steps={1000}
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Strict Quality Timeout"
                            subtitle="Indicates time for the image to reach timeout."
                            title="Timeout in ms"
                            option={props.findFVSliderInterface("StrictQualityTimeout")}
                            defaultOption={props.findDefaultFVSliderInterface("StrictQualityTimeout")}
                            setOption={props.setFVSliderInterfaceOption}
                            minValue={0}
                            maxValue={60000}
                            type="static"
                            active={active && (selectLiveness === "1" || selectLiveness === "6")}
                            lockMaxValue
                            steps={1000}
                        />
                        {doubleInputRow()}
                    </TableBody>
                </SettingTable>
            </TableContainer>
            <ISOLivenessTable
                setOption={props.setOption}
                setLiveness={(liveness) => props.setOption("livenessMode", Number(liveness), true)}
                setQualityImageSize={setQualityImageSize}
                active={active}
            />
            <ConfirmationDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title="Reset liveness settings"
                text="Reset liveness settings to default values?"
                cancelText="Cancel"
                confirmText="Yes"
                confirm={handleResetClick}
                confirmColor="error"
            />
        </>
    );
}

export default Liveness;