import { Box, TableContainer, styled} from '@mui/material';
import { Usage } from 'neurotec-megamatcherid-management-client';
import PieChart from '../components/Piechart';

interface IUsageProps {
    usages: Usage[]
} 

const UsageContainer: React.FC<IUsageProps> = (props) => {

    const PieChartBox = styled(Box)(({theme}) => ({
        width: "50%",
        marginRight: "2rem",
        [theme.breakpoints.down("md")]: {
            marginRight: "1rem"
        }
    }))

    return (
        <TableContainer
            style={{marginBottom: "2rem", tableLayout: "fixed"}}
        >
            {props.usages.reduce((accumulator: any[], currentValue, currentIndex, array: Usage[]) => {
                if (currentIndex % 2 === 0) {
                    accumulator.push(array.slice(currentIndex, currentIndex + 2));
                }
                return accumulator;
            }, []).map(p => 
                <Box style={{display: "flex", paddingBottom: "5rem"}} key={p[0].operation}>
                    <PieChart usage={p[0]} index={1} />
                    {p[1] ? <PieChart usage={p[1]} index={2} /> : <PieChartBox/>}
                </Box>
            )}
       </TableContainer>
    )
}

export default UsageContainer
