import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import FilterTextField from './Components/FilterTextField';
import DrawerMoreFilters from './Components/drawer/DrawerMoreFilters';
import ButtonLightGray from './Components/ButtonLightGray';
import ChipFactory from './Components/chips/ChipFactory';
import { IOperationFilter } from '../../Store/Operations/types';
import { GridButton } from './Components/grid/GridButton';
import { BoxButton } from './Components/BoxButton';
import GridContainer from './Components/grid/GridContainer';

export interface IDateRange {
    from: Date | null,
    to: Date | null
}
export interface INumberRange {
    from: number,
    to: number
}

interface IOperationFilterProps {
    onFilterApply: (filter: IOperationFilter) => void
    filter: IOperationFilter
}

const OperationFilter: React.FC<IOperationFilterProps> = (props) => {

    const [operationFilter, setOperationFilter] = useState<IOperationFilter>(props.filter)
    const [uuidRegexValid, setUUIDRegexValid] = useState(true);
    const [openMoreFilters, setOpenMoreFilters] = useState<boolean>(false)

    useEffect(() => {
        setOperationFilter(props.filter)
    }, [props.filter]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            onFilterApply(operationFilter)
        }   
    }

    const handleUUIDChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOperationFilter({...operationFilter, uuid: event.target.value})
        try {
            new RegExp(event.target.value);
        } catch(e) {
            setUUIDRegexValid(false)
            return;
        }
        setUUIDRegexValid(true)
    }

    const setFilterChip = (filter: IOperationFilter) => {
        props.onFilterApply(filter)
        setOperationFilter(filter)
    }

    const resetFilter = () => {
        setOperationFilter({})
        props.onFilterApply({})
    }

    const onFilterApply = (filter: IOperationFilter) => {
        setOperationFilter(filter)
        props.onFilterApply(filter);
        setOpenMoreFilters(false)
    }

    return (
        <>
            <GridContainer container>
                <Grid item xs={12} md={5}>
                    <FilterTextField
                        style={{height: "100%"}}
                        variant='outlined'
                        spellCheck="false"
                        fullWidth
                        label="Regex"
                        size="small"
                        placeholder='Click to filter UUID'
                        value={operationFilter.uuid}
                        onChange={(event) => handleUUIDChange(event)}
                        error={!uuidRegexValid}
                        onClick={() => onFilterApply(operationFilter)}
                        onInputKeyDown={handleKeyDown}
                    />
                </Grid>
                <GridButton item xs={12} md={7}>
                    <BoxButton>
                        <ButtonLightGray
                            onClick={() => setOpenMoreFilters(true)}
                            variant='text'
                            color="primary"
                            style={{height: "100%"}}
                        >
                            More filters
                        </ButtonLightGray>
                    </BoxButton>
                </GridButton>
                <ChipFactory
                    filter={props.filter}
                    setChip={setFilterChip}
                    clearFilter={resetFilter}
                />
            </GridContainer>
            <DrawerMoreFilters
                anchor='right'
                type="operation"
                filter={operationFilter}
                setFilter={setOperationFilter}
                open={openMoreFilters}
                onClose={() => setOpenMoreFilters(false)}
                resetFilter={resetFilter}
                onFilterApply={onFilterApply}
            />
        </>
    )
}

export default OperationFilter