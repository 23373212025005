import { Button, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { THEME } from "../../config";
import React from "react";
import { Cloud, Dongle, InternetLicense, Usage } from "neurotec-megamatcherid-management-client";
import { LicensingType } from "../../Store/Navigation/state";

const useStyles = makeStyles(() => ({
    button: {
        width: "100%",
        justifyContent: "flex-start",
        textAlign: "left",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        boxShadow: "0",
        borderRadius: "0",
        color: "inherit"
    },
    gridContainer: {
        borderRight: "solid",
        borderRightColor: "#e0e0e0",
        borderWidth: "2px",
        position: "sticky",
    },
    gridSettingActive: {
        borderLeftWidth: "3px",
        borderLeft: "solid",
        borderLeftColor: THEME.palette.primary.main,
        backgroundColor: THEME.palette.primary.light,
        color: THEME.palette.primary.main
    },
    gridSettingNotActive: {
        borderLeftWidth: "3px",
        borderLeft: "solid",
        borderLeftColor: "transparent",
    }
}))


interface ILicensingMenuItem {
    name: string,
    licesingType: LicensingType,
}

const UsageMenuItem: ILicensingMenuItem = {
    name: "Usage",
    licesingType: LicensingType.USAGE
}

const CloudMenuItem: ILicensingMenuItem = {
    name: "Cloud",
    licesingType: LicensingType.CLOUD
}

const DonglesMenuItem: ILicensingMenuItem = { 
    name: "Dongles",
    licesingType: LicensingType.DONGLES
}

const InternetMenuItem: ILicensingMenuItem = {
    name: "Internet license",
    licesingType: LicensingType.INTERNET_LICENSING
}

interface ILicensingSidebar {
    setLicensing: (type: LicensingType) => void
    activeLicensing: LicensingType
    usage: Usage[]
    internetLicense: InternetLicense[]
    dongles: Dongle[]
    loading: boolean
    cloud: Cloud[]
}

const LicensingSidebar: React.FC<ILicensingSidebar> = (props) => {

    const classes = useStyles();

    const handleButtonClick = (type: LicensingType) => {
        props.setLicensing(type)
    }

    const getSkeleton = () => (
        <Grid item xs={12}>
            <Skeleton className={classes.button}/>
        </Grid>
    )

    const getButtons = () => (
        <>
            {props.usage && props.usage.length > 0?
                <Grid item xs={12} key={UsageMenuItem.name} className={props.activeLicensing === UsageMenuItem.licesingType? classes.gridSettingActive : classes.gridSettingNotActive}>
                    <Button onClick={() => {handleButtonClick(UsageMenuItem.licesingType)}} className={classes.button} variant={"text"}>
                        {UsageMenuItem.name}
                    </Button>
                </Grid> : <></> 
            }
            {props.dongles && props.dongles.length > 0?
                <Grid item xs={12} key={DonglesMenuItem.name} className={props.activeLicensing === DonglesMenuItem.licesingType? classes.gridSettingActive : classes.gridSettingNotActive}>
                    <Button onClick={() => {handleButtonClick(DonglesMenuItem.licesingType)}} className={classes.button} variant={"text"}>
                        {DonglesMenuItem.name}
                    </Button>
                </Grid> : <></> 
            }
            {props.internetLicense && props.internetLicense.length > 0?
                <Grid item xs={12} key={InternetMenuItem.name} className={props.activeLicensing === InternetMenuItem.licesingType? classes.gridSettingActive : classes.gridSettingNotActive}>
                    <Button onClick={() => {handleButtonClick(InternetMenuItem.licesingType)}} className={classes.button} variant={"text"}>
                        {InternetMenuItem.name}
                    </Button>
                </Grid> : <></> 
            }
            {props.cloud && props.cloud.length > 0?
                <Grid item xs={12} key={CloudMenuItem.name} className={props.activeLicensing === CloudMenuItem.licesingType? classes.gridSettingActive : classes.gridSettingNotActive}>
                    <Button onClick={() => {handleButtonClick(CloudMenuItem.licesingType)}} className={classes.button} variant={"text"}>
                        {CloudMenuItem.name}
                    </Button>
                </Grid> : <></> 
            }
        </>
    )

    return (
        <Grid container className={classes.gridContainer}>
            {props.loading? getSkeleton() : getButtons()}
        </Grid>
    );
}

export default LicensingSidebar