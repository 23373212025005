import axios from "axios";
import Authentication from "../Store/Authentication/AuthenticationStore";
import history from "./history";
import { HttpError } from "./types";


const configAxios = () => {
    axios.interceptors.request.use(async conf => {
        conf.withCredentials = true;
        return conf;
    });

    axios.interceptors.response.use(undefined, error => {
        var url = error.config.url as String;
        if (url.includes("/login"))
            return Promise.reject(error);

        let httpError: HttpError | null = null;
        if (!error.response) {
            httpError = new HttpError(new Date().valueOf(), -1, error.message, error.response ? error.response.data.error : error.message);
        } else {
            httpError = new HttpError(new Date().valueOf(), -1, "", "");
            if (error.response.status === 401) {
                httpError.status = 401
                if (Authentication.isAuthenticated()) {
                    Authentication.logout();
                    httpError.message = "Session expired";
                } else {
                    history.push("/login")
                    httpError.message = "Authentication needed";
                }
                return Promise.reject(httpError);
            }
            httpError = new HttpError( 
                error.response.data.timestamp,
                error.response.data.status,
                error.response.data.error,
                error.response.data.message);
        }
        return Promise.reject(httpError);
    });

};

const getEnvVariableValue = (variable: string | undefined): string => {
    if (variable)
        return variable;
    return "";
}

export const API_BASE_PREFIX = process.env.NODE_ENV === 'production' ? getEnvVariableValue(process.env.REACT_APP_API_BASE_PREFIX) : "";
export const API_BASE_URL = process.env.NODE_ENV === 'production' ? getEnvVariableValue(process.env.REACT_APP_API_BASE_URL) : "http://localhost:40002";


export const getFullApiUrl = (): string => {
    return API_BASE_URL + API_BASE_PREFIX;
};

export default configAxios;
