import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Input, TableCell, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CachedIcon from '@mui/icons-material/Cached';
import { THEME } from "../../../config";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Slider from "./Slider";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    titleDisabled: {
        opacity: "50%",
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    iconButton: {
        opacity: "100%"
    },
    iconButtonDisabled: {
        opacity: "50%"
    },
    iconInfo: {
        marginLeft: "10px",
        position: "relative",
        top: "5px",
        color: THEME.palette.primary.main
    },
    tableCell: {
        paddingRight: 0,
        paddingLeft: "inherit",
    },
    gridSlider: {
        paddingRight: "5%"
    },
    slider: {
        markLabel: {
            color: "red"
        }
    },
    typographyNoWarning: {
        color: THEME.palette.success.main
    },
    typographyAlwaysWarning: {
        color: THEME.palette.error.main
    }
}))

export interface ISliderInterface {
    key: string,
    value: number
}

export interface ISliderCell {
    title: string,
    info?: string,
    option: ISliderInterface | undefined,
    defaultOption: ISliderInterface | undefined,
    setOption: (op: ISliderInterface, basic?: boolean) => void,
    active: boolean,
    minValue: number,
    maxValue: number,
    lockMaxValue?: boolean,
    lockMinValue?: boolean,
    steps?: number,
    basic?: boolean,
    colspan?: number,
    noWarningMsg?: string,
    alwaysWarningMsg?: string,
    badValueRange?: [number, number],
    type: "raising" | "falling" | "static" | "infinite" | "raisingMono" | "fallingMono"
}

const SliderCell: React.FC<ISliderCell> = (props) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(props.option?.value? props.option?.value : 0);
    const [inputValue, setInputValue] = useState(props.option?.value? props.option?.value : 0)

    useEffect(() => {
        setValue(props.option?.value? props.option?.value : 0)
        setInputValue(props.option?.value? props.option?.value : 0)
    }, [props.option])

    const saveOptionValue = (newValue: number) => {
        if (props.option?.key !== undefined){ 
            if (props.basic)
                props.setOption({key: props.option?.key, value: newValue}, true)
            else
                props.setOption({key: props.option?.key, value: newValue})
        }
    }

    const handleSaveValue = (value: number) => {
        setInputValue(value)
    }

    const handleSliderCommitedChange = (value: number) => {
        setValue(value as number)
        setInputValue(value)
        saveOptionValue(value as number)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let number = event.target.value === '' ? 0 : Number(event.target.value)
        if (props.lockMaxValue) {
            if (number > props.maxValue){ 
                setInputValue(props.maxValue)
                setValue(props.maxValue)
                saveOptionValue(props.maxValue)
                return
            }
        }
        if (props.lockMinValue) {
            if (number < props.minValue){ 
                setInputValue(props.minValue)
                setValue(props.minValue)
                saveOptionValue(props.minValue)
                return
            }
        }
        setValue(number);
        saveOptionValue(number)

    }

    const handleIconClick = () => {
        if (props.defaultOption?.value || props.defaultOption?.value === 0) {
            setValue(Number(props.defaultOption.value))
            setInputValue(Number(props.defaultOption.value))
            saveOptionValue(Number(props.defaultOption.value))
        } else {
            enqueueSnackbar("Could not get default value", {variant: "error"})
        }     
    }

    const noWarningMessage = () => {
        return (
            <Typography className={classes.typographyNoWarning} align="center">
                {props.noWarningMsg? props.noWarningMsg : "Warning never shown"}
            </Typography>
        );
    }

    const alwaysWarningMessage = () => {
        return (
            <Typography className={classes.typographyAlwaysWarning} align="center">
                {props.alwaysWarningMsg? props.alwaysWarningMsg : "Warning always shown"}
            </Typography>
        );
    }

    const badValueRangeMessage = () => {
        return (
            <Typography className={classes.typographyAlwaysWarning} align="center">
                {"Value not recommended"}
            </Typography>
        );
    }

    const noMessage = () => {
        return (
            <Typography className={classes.typographyAlwaysWarning} align="center">
                &nbsp;
            </Typography>
        );  
    }

    const handleThresholdMessage = () => {
        if (props.type !== "static") {
            if (props.type === "raising") {
                if (value === props.minValue) 
                    return noWarningMessage();
                if (value === props.maxValue)
                    return alwaysWarningMessage();
            } else if (props.type === "falling") {
                if (value === props.minValue) 
                    return alwaysWarningMessage();
                if (value === props.maxValue)
                    return noWarningMessage();
            }
        }
        if (props.badValueRange) {
            if (value > props.badValueRange[0] && value < props.badValueRange[1])
                return badValueRangeMessage()
        }
        return noMessage()
    }

    return(
        <TableCell className={classes.tableCell} colSpan={props.colspan? props.colspan : 1} component="th">
            <Grid container>
                <Grid item xs={12}>
                    <Typography component={'span'} className={props.active? classes.title : classes.titleDisabled}>
                        {props.title}
                        {props.info? 
                            <Tooltip disableFocusListener title={props.info} >
                                <div style={{display: "inline"}} >
                                    <HelpOutlineIcon className={classes.iconInfo} />
                                </div>
                            </Tooltip>
                        : null}
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={8} className={classes.gridSlider}>
                        <Slider
                            active={props.active}
                            minValue={props.minValue}
                            maxValue={props.maxValue}
                            steps={props.steps}
                            value={value}
                            saveValue={handleSaveValue}
                            commitValue={handleSliderCommitedChange}
                            type={props.type}
                        />
                        {handleThresholdMessage()}
                    </Grid>
                    <Grid item xs={2}>
                        <Input
                            disabled={!props.active}
                            value={inputValue}
                            onChange={handleInputChange}
                            inputProps={{
                                step: 1,
                                min: props.minValue,
                                max: props.type !== "infinite"? props.maxValue : null,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            onClick={handleIconClick}
                            color="primary"
                            disabled={!props.active}
                            className={props.active? classes.iconButton : classes.iconButtonDisabled}
                        >
                            <CachedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </TableCell>
    );
}

export default SliderCell;