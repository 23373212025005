import { Box, Collapse, Table, TableBody, TableCell, TableRow, Typography, styled } from "@mui/material"
import { JanusAdminSessionResponse } from "neurotec-megamatcherid-management-client"

const CustomTableRow = styled(TableRow)(() => ({}))

const CustomTableCell = styled(TableCell)(() => ({
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem"
}))

interface IJanusCollapseRowProps {
    open: boolean,
    info: JanusAdminSessionResponse | undefined
}

const JanusCollapseRow: React.FC<IJanusCollapseRowProps> = (props) => {

    const returnOneCellPair = (title: String, value: String | number | undefined) => {
        return (
            <>
            <CustomTableCell>
                <Typography gutterBottom component="div" fontWeight={"500"} fontSize={"0.8rem"}>
                    {title}
                </Typography>
            </CustomTableCell>
            <CustomTableCell>
                <Typography gutterBottom component="div" fontSize={"0.8rem"}>
                    {value ?? "-"}
                </Typography>
            </CustomTableCell>
            </>
        )
    }

    const getUdpPort = (str: string | undefined | null) => {
        if (str === undefined || str === null) return undefined
        let temp = str.substring(str.search(":") + 1)
        return temp.substring(0, temp.search(" "))
    }

    const getClient = (str: string | undefined| null) => {
        if (str === undefined || str === null) return undefined
        let temp = str.substring(str.search("<->") + 4)
        return temp.substring(0, temp.search(" "))
    }

    const getDuration = (current: number | undefined | null, created: number | undefined | null) => {
        if (current === undefined || created === undefined || current === null || created === null) return undefined
        let duration = current - created
        let ans = (duration / 1000 / 1000).toFixed(0)
        return ans
    }

    const getLastActive = (current: number | undefined | null, lastActive: number | undefined | null) => {
        if (current === undefined || lastActive === undefined || current === null || lastActive === null) return undefined
        let duration = current - lastActive
        let ans = (duration / 1000 / 1000).toFixed(0)
        return ans
    }

    return (
        <TableRow style={{backgroundColor: "#f8f8f8"}}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <Box>
                        <Table>
                            <TableBody>
                                <CustomTableRow>
                                {   returnOneCellPair("Handle Id", props.info?.handleId)}
                                    {returnOneCellPair("Link Quality", props.info?.inLinkQuality)}
                                </CustomTableRow>
                                <CustomTableRow>
                                    {returnOneCellPair("Duration", getDuration(props.info?.currentTime, props.info?.created))}
                                    {returnOneCellPair("Last active before", getLastActive(props.info?.currentTime, props.info?.sessionLastActivity))}
                                </CustomTableRow>
                                <CustomTableRow>
                                    {returnOneCellPair("Packet received", props.info?.in?.packets)}
                                    {returnOneCellPair("Packet sent", props.info?.out?.packets)}
                                </CustomTableRow>
                                <CustomTableRow>
                                    {returnOneCellPair("Video codec", props.info?.videoCodec)}
                                    {returnOneCellPair("DTLS", props.info?.dtlsState + " (" + props.info?.dtlsRole+ ")" )}
                                </CustomTableRow>
                                <CustomTableRow>
                                    {returnOneCellPair("UDP Port", getUdpPort(props.info?.selectedPair))}
                                    {returnOneCellPair("Client", getClient(props.info?.selectedPair))}
                                </CustomTableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

export default JanusCollapseRow