import { Box, BoxProps, InputBase, Typography, styled } from "@mui/material";

const SearchTextField = styled(Box)(() => ({
    borderStyle: "solid",
    borderRadius: "7px",
    borderColor: "#A6A6A6",
    borderWidth: "1px",
    display: "flex"
}));

const SearchInputBase = styled(InputBase)(() => ({
    ml: 1,
    flex: 1,
    paddingTop: "3px",
    paddingBottom: "3px",
    height: "100%",
    width: "100%"
}))

interface IInputFilterProps extends BoxProps{
    label?: string,
    value: number | undefined,
    setValue: (str: number) => void,
    minValue: number, 
    maxValue: number
}

const InputFilter: React.FC<IInputFilterProps> = (props) => {

    const handleOnChange = (event: any) => {
        if (event.target.value as number < props.minValue) {
            props.setValue(props.minValue)
            return
        }
        if (event.target.value as number > props.maxValue) {
            props.setValue(props.maxValue)
            return
        }
        props.setValue(event.target.value)
    }

    const getNumberValue = (number: number | undefined): number | string => {
        if (number && !isNaN(number)) {
            return number
        } else {
            return "";
        }
    }
    
    return (
        <>
        <Typography>
            {props.label? props.label : null}
        </Typography>
        <SearchTextField style={props.style}>
            <SearchInputBase
                inputProps={{ 'aria-label': 'input' }}
                style={{paddingLeft: "0.5rem"}}
                onChange={handleOnChange}
                value={getNumberValue(props.value)}
                placeholder={props.placeholder}
            />
        </SearchTextField>
        </>
    )
}

export default InputFilter