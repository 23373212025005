import React from "react";
import { Route, Redirect } from "react-router-dom";
import Authentication from "../../Store/Authentication/AuthenticationStore";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.FC<any>;
}
const PrivateRoute: React.FC<IProps> = ({ component: Component, ...otherProps }: IProps) => {

    const isAuth = (props: any) => {
        return (
            <Component {...props} />
        )
    }

    const isNotAuth = (props: any) => {
        return (
            <Redirect to={{ pathname: "/login", state: { referrer: props.location } }} />
        )
    }

    return (
        <Route
            {...otherProps}
            render={props => Authentication.isAuthenticated() ? isAuth(props) : isNotAuth(props)}
        />
    );
}


export default PrivateRoute;
