import { Box, Dialog, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CloseIcon from '@mui/icons-material/Close';
import DialogButton from "../../../components/Misc/Dialog/Components/DialogButton";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useCallback, useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
    dialog: {
    },
    dialogTitle: {
        fontWeight: 500,
        backgroundColor: "#f8f8f8"
    },
    titleTypography: {
        fontWeight: 500,
        fontSize: "1.2rem"
    },
    tableCell: {
        width: "50%",
    },
    selectGrid: {
        width: "100%",
    },
    closeButton: {
        color: "#000000"
    },
    select: {
        width: "100%",
        marginLeft: "1rem",
        paddingRight: "1rem",
    },
    buttonGrid: {
        margin: "1rem",
        textAlign: "center",
        marginTop: "2rem"
    },
    actionTypography: {
        fontWeight: 500,
        paddingLeft: "1rem"
    }
}))

interface IExceptionsFilterDialog {
    title: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    apply: (string: string) => void,
    value: string
}

const ExceptionsFilterDialog: React.FC<IExceptionsFilterDialog> = (props) => {

    const classes = useStyles();

    const tokenizeSequence = useCallback(() => {
        let array: string[] = []
        props.value.split(",").forEach((e) => {
            array.push(e)
        })
        return array
    }, [props.value])

    const [elementList, setElementList] = useState<string[]>(tokenizeSequence());
    
    useEffect(() => {
        setElementList(tokenizeSequence())
    }, [props, tokenizeSequence])

    const stringifySequence = () => {
        let str: string = ""
        elementList.forEach((e) => {
            if (str === "")
                str = (e as string)
            else {
                if (e !== "") str = str + "," + (e as string)
            }  
                
        })
        return str
    }
    
    const changeValue = (value: any, index: number) => {
        const nextElements = elementList.map((e, i) => {
            if (i === index) {
                return value.target.value
            } else {
                return e
            }
        })
        setElementList(nextElements)
    }

    const handleDelete = (index: number) => {
        setElementList(
            elementList.filter((e, i) => i !== index
        ))
    }

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleNewAction = () => {
        setElementList([...elementList, ""])
    }

    const handleApply = () => {
        props.apply(stringifySequence())
        handleClose()
    }

    return (
        <Dialog onClose={handleClose} open={props.open} className={classes.dialog} fullWidth maxWidth="xs">
        <DialogTitle className={classes.dialogTitle}>
            <Grid container>
                <Grid item xs={10}>
                    <Typography component={"span"} textAlign={"left"} className={classes.titleTypography}>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign: "right"}}>
                    <IconButton color="primary" onClick={handleClose} className={classes.closeButton}>
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <Grid container>
            {elementList.map((el, i) =>
                <Box style={{width: "100%", paddingLeft: "1rem", display: "flex", alignItems: "center"}} key={i}>
                    <TextField
                        style={{width: "100%"}}
                        value={el}
                        onChange={(e) => {changeValue(e, i)}}
                    />
                    <IconButton color="primary" onClick={() => {handleDelete(i)}}>
                        <DeleteForeverOutlinedIcon fontSize="large" />
                    </IconButton>
                </Box>
            )}
            <Grid container className={classes.buttonGrid}>
                <Grid item xs={6}>
                    <DialogButton variant="outlined" color="secondary" onClick={handleNewAction}>
                        + Add new filter
                    </DialogButton>
                </Grid>
                <Grid item xs={6}>
                    <DialogButton variant="contained" color="primary" onClick={handleApply}>
                        Apply
                    </DialogButton>
                </Grid>
            </Grid>
        </Grid>
      </Dialog>
    )
}

export default ExceptionsFilterDialog