import { Grid, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

interface IDialogTitleCloseProps {
    title: string,
    onClose: () => void
}

const DialogTitleClose: React.FC<IDialogTitleCloseProps> = (props) => {
    return (
        <Grid container>    
            <Grid item xs={10}>
                {props.title}
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
                <IconButton onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default DialogTitleClose