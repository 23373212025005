import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { ISubjectsFilter } from '../../Store/Subjects/types';
import FilterTextField from './Components/FilterTextField';
import ButtonGradient from './Components/ButtonGradient';
import DrawerMoreFilters from './Components/drawer/DrawerMoreFilters';
import ButtonLightGray from './Components/ButtonLightGray';
import ChipFactory from './Components/chips/ChipFactory';
import GridContainer from './Components/grid/GridContainer';

const BoxButton = styled(Box)(({theme}) => ({
    marginLeft: "1rem",
    [theme.breakpoints.down("md")]: {
        marginLeft: "0rem",
    }
}));

const GridButton = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down("md")]: {
        marginTop: "1rem",
    }
}));

export interface IDateRange {
    from: Date | null,
    to: Date | null
}
export interface INumberRange {
    from: number,
    to: number
}

interface ISubjectFilterProps {
    onFilterApply: (filter: ISubjectsFilter) => void
    setOpenCreateSubjectDialog: React.Dispatch<React.SetStateAction<boolean>>
    filter: ISubjectsFilter
}

const SubjectFilter: React.FC<ISubjectFilterProps> = (props) => {

    const [subjectFilter, setSubjectFilter] = useState<ISubjectsFilter>(props.filter)
    const [subjectIdRegexValid, setSubjectIdRegexValid] = useState(true);
    const [openMoreFilters, setOpenMoreFilters] = useState<boolean>(false)

    useEffect(() => {
        setSubjectFilter(props.filter)
    }, [props.filter]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            onFilterApply(subjectFilter)
        }   
    }

    const handleSubjectIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSubjectFilter({...subjectFilter, subjectId: event.target.value})
        try {
            new RegExp(event.target.value);
        } catch(e) {
            setSubjectIdRegexValid(false)
            return;
        }
        setSubjectIdRegexValid(true)
    }

    const setFilterChip = (filter: ISubjectsFilter) => {
        props.onFilterApply(filter)
    }

    const resetFilter = () => {
        setSubjectFilter({})
        props.onFilterApply({})
    }

    const onFilterApply = (filter: ISubjectsFilter) => {
        props.onFilterApply(filter)
        setSubjectFilter(filter)
        setOpenMoreFilters(false)
    }

    return (
        <>
            <GridContainer container>
                <Grid item xs={12} md={5}>
                    <FilterTextField
                        variant='outlined'
                        spellCheck="false"
                        fullWidth
                        label="Regex"
                        size="small"
                        placeholder='Click to filter Subject Id'
                        value={subjectFilter.subjectId}
                        onChange={(event) => handleSubjectIdChange(event)}
                        error={!subjectIdRegexValid}
                        onClick={() => onFilterApply(subjectFilter)}
                        onInputKeyDown={handleKeyDown}
                    />
                </Grid>
                <GridButton item xs={6} md={3}>
                    <BoxButton display="flex" justifyContent="flex-start">
                        <ButtonLightGray
                            onClick={() => setOpenMoreFilters(true)}
                            variant='text'
                            color="primary"
                            style={{height: "100%"}}
                        >
                            More filters
                        </ButtonLightGray>
                    </BoxButton>
                </GridButton>
                <GridButton item xs={6} md={4}>
                    <BoxButton display="flex" justifyContent="flex-end">
                        <ButtonGradient
                            onClick={() => props.setOpenCreateSubjectDialog(true)}
                            color="primary"
                            variant="contained"
                            style={{height: "100%"}}
                        >
                            Add subject
                        </ButtonGradient>
                    </BoxButton>
                </GridButton>
                <ChipFactory
                    filter={props.filter}
                    setChip={setFilterChip}
                    clearFilter={resetFilter}
                />
            </GridContainer>
            <DrawerMoreFilters
                type="subject"
                anchor='right'
                filter={subjectFilter}
                setFilter={setSubjectFilter}
                open={openMoreFilters}
                onClose={() => setOpenMoreFilters(false)}
                resetFilter={resetFilter}
                onFilterApply={onFilterApply}
            />  
        </>
    )
}

export default SubjectFilter