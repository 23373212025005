import { Status } from "../Store/Operations/types"
import { THEME } from "../config"

export const handleNumberHelper = (num: number) => {
    if (num === 255 || num === 254) {
        return "-"
    } else {
        return num
    }
}

export const handleStringLengthHelper = (str: string | undefined) => {
    if (!str) return ""
    if (str.length < 25) return str;
    return str.substring(0, 22) + "..."
}

export const capitalizeFirstLetterHelper = (string: string) => {
    return (string.charAt(0).toUpperCase() + string.slice(1)).replace("_", " ").replace("_", " ");
}

export const handleStringHelper = (str: string | undefined) => {
    if (str === undefined) return "-"
    return capitalizeFirstLetterHelper(replaceAllHelper(str.toLowerCase(), "_", " "))
}

export const handleDate = (dateValue: any, isMobile: boolean) => {
    if (isMobile) {
        return new Date(dateValue).toISOString().split( "T" )[0];
    } else {
        return new Date(dateValue).toUTCString()
    }
}

export const replaceAllHelper = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, 'g'), replace);
}

export const selectStatusColorHelper = (str: string) => {
    switch(str) {
        case Status.SUCCESS:
            return THEME.palette.primary.main
        case Status.INTERNAL_ERROR:
        case Status.NONE:
            return THEME.palette.error.main
        default:
            return THEME.palette.warning.main
    }
}

export const selectHTTPColorHelper = (num: number) => {
    if (num >= 500) {
        return THEME.palette.error.main
    } else {
        return THEME.palette.warning.main
    }
}

export const handleStringFromLastOccurence = (str: string, char: string) => {
    return str.substring(str.lastIndexOf(char) + 1)
}