import { makeStyles } from "@mui/styles";
import { TableCell, useTheme } from "@mui/material"
import CustomSwitch from "../../../components/Misc/CustomSwitch";

const useStyles = makeStyles(() => ({
    switch: {
        marginRight: "2rem",
        [useTheme().breakpoints.down("sm")]: {
            marginRight: "0px"
        }
    },
    cell: {
        textAlign: "right",
        [useTheme().breakpoints.down("sm")]: {
            paddingRight: "5px"
        }
    }
}))

interface ISwitchCell {
    state: boolean
    setState: (bool: boolean) => void
    colSpan?: number
    disabled?: boolean
}

const SwitchCell: React.FC<ISwitchCell> = (props) => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setState(event.target.checked)
    }

    return(
        <TableCell className={classes.cell} colSpan={props.colSpan? props.colSpan : 1}>
            <CustomSwitch
                className={classes.switch}
                disabled={props.disabled}
                state={props.state}
                handleChange={handleChange}
            />
        </TableCell>
    )
}

export default SwitchCell
