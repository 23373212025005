import { Grid, styled } from "@mui/material";

const gradient = "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)"

export const GridButtons = styled(Grid)(() => ({
    position: "sticky",
    bottom: 0,
    paddingBottom: "10px",
    paddingTop: "3rem",
    background: gradient
}))