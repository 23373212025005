import { ForwardedRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import { APIsContext } from "../../../../Store/APIs/context";
import { JanusAdminSessionResponse } from "neurotec-megamatcherid-management-client";
import DataTableRow from "../../../../components/Misc/DataTable/DataTableRow";
import { IconButton, TableCell, Theme, useMediaQuery } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from "react";
import JanusCollapseRow from "./JanusCollapseRow";
import DownloadIcon from '@mui/icons-material/Download';
import { enqueueSnackbar } from "notistack";
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

export interface IJanusCollapsibleSessionRowProps {
    sessionId: number,
    index: number,
    detailedInfo: boolean,
    rowKey: number
}

const JanusCollapsibleSessionRow: React.FC<IJanusCollapsibleSessionRowProps> = (props: IJanusCollapsibleSessionRowProps, ref: ForwardedRef<IJanusCollapsibleSessionRowProps>) => {

    const [open, setOpen] = useState(false);
    const apisState = useContext(APIsContext).state
    const [sessionInfo, setSessionInfo] = useState<JanusAdminSessionResponse>()
    const autoId = useRef<NodeJS.Timeout>();
    const notLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const fetchData = useCallback((force?: boolean) => {
        if (!force && !props.detailedInfo) return
        apisState.JanusApi.getSession({sessionId: props.sessionId}).then(res => {
            setSessionInfo(res.data)
            if (force && !open)
                setOpen(!open)
        }).catch(e => {
            setOpen(false)
        })
    }, [props.sessionId, props.detailedInfo, apisState.JanusApi, open])

    const fetchDataOnClick = () => {
        apisState.JanusApi.getListSession().then(res => {
            if (res.data.find((v) => v === props.sessionId) !== undefined) {
                fetchData(true)
            } else {
                enqueueSnackbar("Janus session not found", {variant: "warning"})
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [props, fetchData])

    useEffect(() => {
        if (open) {
            autoId.current = setInterval(() => {
                fetchData(true)
            }, 2000)
        } else {
            clearInterval(autoId.current)
        }
        return (() => {
            clearInterval(autoId.current)
        })
    }, [open, fetchData])

    const handleClick = () => {
        if (open) {
            setOpen(!open)
        } else {
            fetchDataOnClick()
        }
        
    }

    const getSignalIcon = (signal: number | undefined) => {
        if (signal === undefined) return (<SignalCellular0BarIcon/>)
        if (signal >= 98 ){
            return (<SignalCellularAltIcon/>)
        } else if (signal >= 80) {
            return (<SignalCellularAlt2BarIcon/>)
        } else if (signal >= 50) {
            return (<SignalCellularAlt1BarIcon/>)
        } else {
            return (<SignalCellularConnectedNoInternet0BarIcon/>)
        }
    }

    const calcDownload = (num: number | undefined) => {
        if (num === undefined) return "X";
        let ans = num / 1048576 // convert to MB
        return ans.toFixed(2)
    }
    
    return (
        <>
            <DataTableRow
                hover
                tabIndex={-1}
                key={props.rowKey}
                style={{backgroundColor: props.index % 2 === 0? "#fefefe" : "#fafafa"}}
                onClick={handleClick}
            >
                <TableCell style={{fontWeight: "500", paddingRight: "0"}}>SessionId:</TableCell>
                <TableCell align="left" style={{paddingLeft: "2px"}}>{props.sessionId}</TableCell>
                <TableCell align="right" style={{padding: "0"}}>
                    <DownloadIcon style={{color: "#4e4e4e", paddingTop: "4px"}}/>
                </TableCell>
                <TableCell style={{paddingLeft: "0px", paddingRight: "0px"}}>
                    {calcDownload(sessionInfo?.sessionDownload?.bytes)} MB ({calcDownload(sessionInfo?.sessionDownload?.bytesLastSec)} {notLarge ? "MB/s" : "MB per last second"} )
                </TableCell>
                <TableCell style={{paddingRight: "0px"}}>
                    {getSignalIcon(sessionInfo?.inLinkQuality)}
                </TableCell>
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </DataTableRow>
            <JanusCollapseRow
                open={open}
                info={sessionInfo}
            />
        </>
    )
}

export default JanusCollapsibleSessionRow;
