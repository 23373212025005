import React from "react";
import { SettingType } from "../../containers/Settings/Settings";
import { LicensingType } from "./state";

export enum ActionType {
    ClickMenu,
    ToggleDrawer,
    ToggleMobileMenu,
    ClosePage,
    SetSettingType,
    SetLicensingType,
    SetLicensingUsageActive,
    SetLicensingCloudActive,
    SetLicensingInternetLicenseActive,
    SetLicensingDonglesActive,
    SetOptionsActive,
    SetLoading,
    SetDeveloperOptions,
    SetSubjectUUID,
}

export interface ClickMenu {
    type: ActionType.ClickMenu,
    page: React.FC<any>
}

export interface ToggleDrawer {
    type: ActionType.ToggleDrawer
}

export interface ToggleMobileMenu {
    type: ActionType.ToggleMobileMenu,
    toggle: boolean
}

export interface ClosePage {
    type: ActionType.ClosePage,
}

export interface SetSettingType {
    type: ActionType.SetSettingType,
    settingType: SettingType
}

export interface SetLicensingType {
    type: ActionType.SetLicensingType,
    licensingType: LicensingType
}

export interface SetLicensingUsageActive {
    type: ActionType.SetLicensingUsageActive,
    usage?: boolean
}

export interface SetLicensingCloudActive {
    type: ActionType.SetLicensingCloudActive,
    cloud?: boolean
}

export interface SetLicensingInternetLicenseActive {
    type: ActionType.SetLicensingInternetLicenseActive,
    internetLicense?: boolean
}

export interface SetLicensingDonglesActive {
    type: ActionType.SetLicensingDonglesActive,
    dongles?: boolean
}

export interface SetOptionsActive {
    type: ActionType.SetOptionsActive,
    optionsActive: boolean
}

export interface SetLoading {
    type: ActionType.SetLoading,
    isLoading: boolean
}

export interface SetDeveloperOptions {
    type: ActionType.SetDeveloperOptions,
    developerOptions: boolean,
}

export interface SetSubjectUUID {
    type: ActionType.SetSubjectUUID,
    subjectUUID: string,
}

export type NavigationActions = ClickMenu | ToggleDrawer | ToggleMobileMenu | ClosePage | SetSettingType | SetLicensingType | SetOptionsActive | SetLoading | SetDeveloperOptions | SetLicensingUsageActive | SetLicensingCloudActive | SetLicensingInternetLicenseActive | SetLicensingDonglesActive | SetSubjectUUID;