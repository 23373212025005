import { Box, Container, Grid } from "@mui/material"
import JanusServerInfo from "./components/JanusServerInfo"
import JanusSettings from "./components/JanusSettings"
import JanusSessions from "./components/JanusSessions"

const Janus: React.FC = () => {

    return (
        <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "column" }}>
            <Grid container>
                <Grid item xs={12} md={6} style={{paddingLeft: "1rem", paddingRight: "1rem"}} >
                    <Box style={{position: "sticky", top: "7vh"}}>
                        <JanusServerInfo />
                        <JanusSettings />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "3rem"}}>
                    <JanusSessions />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Janus