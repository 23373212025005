import { Switch, SwitchProps, styled } from "@mui/material"

const CSwitch = styled(Switch)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        marginRight: "0px"
    },
    height: "36px",
    '& .MuiSwitch-track': {
        paddingTop: "2px"
      },
}))

interface ICustomSwitch extends SwitchProps {
    state: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomSwitch: React.FC<ICustomSwitch> = (props) => {

    return (
        <CSwitch
            className={props.className}
            disabled={props.disabled}
            color="primary"
            checked={props.state}
            onChange={props.handleChange}
        />
    )
}

export default CustomSwitch