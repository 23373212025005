import React from 'react'
import { Button, useTheme } from '@mui/material'
import { THEME } from '../../config'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    link: {
        borderRadius: "0px",
        fontSize: "1rem",
        fontWeight: 500,
        textAlign: "center",
        textDecoration: "none",
        height: "inherit",
        padding: "1.2rem",
        [useTheme().breakpoints.down("md")]: {
            borderRadius: "0px",
            color: THEME.palette.primary.main,
            backgroundColor: "#fcfcfc",
            width: "100%",
            height: "auto",
            placeContent: "normal",
            paddingRight: "3.5rem"
        }
    },
}));

export interface IMenuItemProps {
    name: string,
    enabled?: boolean,
    onClick?: () => void,
    disableRipple?: boolean,
    sub?: boolean,
    selected?: boolean
    slightBorder?: boolean
}

const MenuItem: React.FC<IMenuItemProps> = (props) => {

    const classes = useStyles()

    const getBorder = () => {
        if (props.selected) {
            return "solid"
        }
        if (props.slightBorder) {
            return "solid 1px #dce1e6"
        }
        return undefined
    } 

    return (
        <Button
            className={classes.link}
            style={{
                paddingLeft: props.sub? "1.9rem" : "1.2rem",
                backgroundColor: props.sub? "#f7f7f7" : undefined,
                borderBottom: getBorder(),
                width: "100%"
            }}
            onClick={props.onClick}
            disableRipple={props.disableRipple}
            color="primary"
        >
            {props.name}
        </Button>
    )
}

export default MenuItem
