import { Box, FormControl, Grid, MenuItem, Pagination, Select, SelectChangeEvent, Theme, Typography, styled, useMediaQuery } from "@mui/material";

const PerPageSelect = styled(Select)({
    padding: "none",
})

interface DataTableFooterProps {
    page: number,
    handlePageChange: (event: React.ChangeEvent<unknown> | undefined, newPage: number) => void
    count: number,
    perPage: number,
    setPerPage: React.Dispatch<React.SetStateAction<number>>,
    label: string
}

const DatatableFooter: React.FC<DataTableFooterProps> = (props) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    
    const handleChangePerPage = (event: SelectChangeEvent<unknown>) => {
        props.setPerPage(event.target.value as number)
        props.handlePageChange(undefined, 1);
    }

    return (
        <Grid container>
            <Grid item md={4} xs={12}>
                <Box style={{display: "flex", justifyContent: isMobile? "center" : "flex-start"}}>
                    <Typography style={{marginTop: "1.4rem"}}> {props.count} {props.label}&nbsp; |&nbsp; Per page:&nbsp;&nbsp;</Typography>
                    <FormControl style={{marginTop: "1rem"}}>
                        <PerPageSelect
                            value={props.perPage}
                            onChange={handleChangePerPage}
                            style={{padding: "0px"}}
                            MenuProps={{MenuListProps: {disablePadding: true}}}
                        >
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={40}>40</MenuItem>
                            <MenuItem value={80}>80</MenuItem>
                            <MenuItem value={160}>160</MenuItem>
                        </PerPageSelect>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item md={8} xs={12}>
                <Box display="flex" justifyContent={ isMobile? "center" : "flex-end"}>
                    <Pagination
                        style={{
                            paddingTop: "1rem",
                            paddingBottom: "2rem",
                            alignSelf: "center"
                        }}
                        page={props.page}
                        onChange={props.handlePageChange}
                        count={Math.ceil(props.count / props.perPage)}
                        color="primary"
                        boundaryCount={isMobile? 1 : 2}
                        showFirstButton
                        showLastButton
                        size={isMobile? "small" : "medium"}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default DatatableFooter;