import { Paper, TableBody, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import TextCell from "../components/TextCell";
import { ISliderInterface } from "../components/SliderCell";
import { DefaultButton, SettingTable, TableCellButton } from "../Settings";
import SliderRow from "../components/SliderRow";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";

interface ICapturing {
    findDefaultFVSliderInterface: (name: string) => ISliderInterface | undefined,
    findFVSliderInterface: (name: string) => (ISliderInterface | undefined),
    setFVSliderInterfaceOption: (op: ISliderInterface, basic?: boolean | undefined) => void
    resetValueToDefault: (name: string, basic?: boolean) => void,
    timeout: number | undefined
}

const Capturing: React.FC<ICapturing> = (props) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    const handleResetClick = () => {
        enqueueSnackbar("Capturing settings reset", {variant: "info"})
        props.resetValueToDefault("QualityThreshold")
        props.resetValueToDefault("FramesToSkip")
        props.resetValueToDefault("timeout", true)
    }

    return (
        <>
        <TableContainer component={Paper} style={{marginBottom: "2rem", tableLayout: "fixed"}}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell head title="Capturing" subtitle=""/>
                        <TableCellButton align="right" colSpan={isMobile? 2 : 3}>
                            <DefaultButton variant="text" color="primary" onClick={() => setOpenDialog(true)}>
                                Reset to default
                            </DefaultButton>
                        </TableCellButton>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Timeout"
                        subtitle="Determines operation timeout in milliseconds."
                        title="Time value"
                        option={{key: "timeout", value: props.timeout? props.timeout : 0}}
                        defaultOption={{key: "timeout", value: 0}}
                        setOption={props.setFVSliderInterfaceOption}
                        minValue={0}
                        maxValue={120000}
                        type="static"
                        active
                        lockMaxValue
                        steps={1000}
                        basic
                        badValueRange={[0, 1000]}
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Quality"
                        subtitle="Face quality threshold."
                        title="Threshold range"
                        option={props.findFVSliderInterface("QualityThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("QualityThreshold")}
                        setOption={props.setFVSliderInterfaceOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active
                        lockMaxValue
                        noWarningMsg="Everything will pass"
                        alwaysWarningMsg="Nothing will pass"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Frames to skip"
                        subtitle="Skips first X frames before starting detection."
                        title="Frames"
                        option={props.findFVSliderInterface("FramesToSkip")}
                        defaultOption={props.findDefaultFVSliderInterface("FramesToSkip")}
                        setOption={props.setFVSliderInterfaceOption}
                        minValue={0}
                        maxValue={1000}
                        type="static"
                        active
                        lockMaxValue
                    />
                </TableBody>
            </SettingTable>
        </TableContainer>
        <ConfirmationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            title="Reset capturing settings"
            text="Reset capturing settings to default values?"
            cancelText="Cancel"
            confirmText="Yes"
            confirm={handleResetClick}
            confirmColor="error"
        />
        </>
    );
}

export default Capturing;