
import React, { useContext, useState } from 'react'
import Authentication from '../../Store/Authentication/AuthenticationStore';
import { RouteComponentProps } from 'react-router';
import Logo from '../../components/Logo/Logo';
import { Box, Button, CircularProgress, Paper, TextField, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { setUpApis } from '../../Store/APIs/reducer';
import { APIsContext } from '../../Store/APIs/context';
import { getFullApiUrl } from '../../config/axios';
import { NavigationContext } from '../../Store/Navigation/context';
import { setOptionsActive } from '../../Store/Navigation/reducer';

const LoginContainer = styled(Box)(({ theme }) => ({
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
        width: "550px"
    },
    [theme.breakpoints.down("sm")]: {
        width: "350px"
    }
}));

const LoginInnerContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1.5rem",
    [theme.breakpoints.down("sm")]: {
        padding: "1.5rem"
    }
}));

const LoginPage: React.FC<RouteComponentProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const dispatch = useContext(APIsContext).dispatch
    const navigationDispatch = useContext(NavigationContext).dispatch

    const handleError = (error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        Authentication.Authenticate(data.get("username") as string, data.get("password") as string).then(() => {
            dispatch(setUpApis({
                basePath: getFullApiUrl(),
                username: data.get("username") as string,
                password: data.get("password") as string
            }))
            navigationDispatch(setOptionsActive(true))
            props.history.push("/subjects")
        }).catch(error => {
            setLoading(false);
            handleError(error);
        });
    }

    return (
        <LoginContainer>
            <Paper elevation={10}>
                <LoginInnerContainer>
                    <Logo login/>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ marginTop: 1, textAlign: "center" }}>
                        <TextField
                            sx={{margin: "5px"}}
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus
                            inputProps={{
                                style: {paddingTop: "16px", paddingBottom: "16px"}
                            }}
                        />
                        <TextField
                            sx={{margin: "5px"}}
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            inputProps={{
                                style: {paddingTop: "16px", paddingBottom: "16px"}
                            }}
                        />
                        {loading ?
                            (<CircularProgress sx={{ marginTop: 3, marginBottom: 3 }} />)
                            :
                            (<Button type="submit" size="large" fullWidth variant="contained" sx={{ marginTop: 3, marginBottom: 3 }}>
                                Log In
                            </Button>)}
                    </Box>
                </LoginInnerContainer>
            </Paper>
        </LoginContainer >
    )
}

export default LoginPage
