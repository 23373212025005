import { BiometricDataApi, ExceptionsApi, ImagesApi, JanusApi, LicensingApi, ManagementApi, OperationsApi, SubjectsApi } from "neurotec-megamatcherid-management-client"

export interface APIsState {
    JanusApi: JanusApi,
    ManagementApi: ManagementApi,
    SubjectsApi: SubjectsApi,
    OperationsApi: OperationsApi,
    ImagesApi: ImagesApi,
    LicensingApi: LicensingApi,
    ExceptionsApi: ExceptionsApi,
    BiometricDataApi: BiometricDataApi
}

export const initAPIsState: APIsState = {
    JanusApi: new JanusApi(),
    ManagementApi: new ManagementApi(),
    SubjectsApi: new SubjectsApi(),
    OperationsApi: new OperationsApi(),
    ImagesApi: new ImagesApi(),
    LicensingApi: new LicensingApi(),
    ExceptionsApi: new ExceptionsApi(),
    BiometricDataApi: new BiometricDataApi()
}