import { forwardRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { Box, Card, CardActions, Grid, IconButton, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
    root: {
    "@media (min-width:600px)": {
        minWidth: "344px !important"
    }
    },
    card: {
    width: "100%"
    },
    actionRoot: {
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between"
    },
    icons: {
    marginLeft: "auto"
    },
    paper: {
    backgroundColor: "#fff",
    padding: 16
    },
    button: {
    padding: 0,
    textTransform: "none"
    }
}));

const useStylesError = makeStyles(() => ({
    card: {
        width: "100%",
        backgroundColor: "#F7D7DD"
    },
    typography: {
        color: "#6C1B2A"
    },
    icon: {
        color: "#D83553"
    }
}))

const useStylesWarning = makeStyles(() => ({
    card: {
        width: "100%",
        backgroundColor: "#F7E4D7"
    },
    typography: {
        color: "#6C3D1B"
    },
    icon: {
        color: "#D87935"
    }
}))

const useStylesInfo = makeStyles(() => ({
    card: {
        width: "100%",
        backgroundColor: "#DAE5F1"
    },
    typography: {
        color: "#243F5C"
    },
    icon: {
        color: "#477EB8"
    }
}))

const useStylesSuccess = makeStyles(() => ({
    card: {
        width: "100%",
        backgroundColor: "#D6E4DF"
    },
    typography: {
        color: "#183D2F"
    },
    icon: {
        color: "#307A5E"
    }
}))

const SnackbarStyled = forwardRef<HTMLDivElement, CustomContentProps>(({ id, ...props }, ref) => {
    const classes = useStyles();
    const getVariant = () => {
        switch (props.variant) {
            case "error":
                return useStylesError
            case "warning":
                return useStylesWarning
            case "info":
                return useStylesInfo
            case "success":
                return useStylesSuccess
            default:
                return useStylesError
        }
    }

    const variantClasses = getVariant()()
    const { closeSnackbar } = useSnackbar();

    const getIcon = () => {
        switch (props.variant) {
            case "error":
                return <ErrorOutlineIcon className={variantClasses.icon}/>
            case "warning":
                return <WarningAmberIcon className={variantClasses.icon}/>
            case "info":
                return <InfoIcon className={variantClasses.icon}/>
            case "success":
                return <CheckCircleOutlineIcon className={variantClasses.icon}/>
            default:
                return <ErrorOutlineIcon className={variantClasses.icon}/>
        }
    }

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card className={variantClasses.card}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <Box>
                {getIcon()}
            </Box>
            <Typography className={variantClasses.typography}>
              {props.message as string}
            </Typography>
            <Grid className={classes.icons}>
            </Grid>
            <IconButton onClick={handleDismiss}>
                <CloseIcon />
            </IconButton>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

export default SnackbarStyled;
