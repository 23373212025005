import { FieldsOrder } from "../Subjects/types";

export interface IExceptionFilter {
    exceptionsFieldOrderBy?: ExceptionsFieldOrderBy,
    fieldsOrder?: FieldsOrder,

    dateLowerBound?: number,
    dateUpperBound?: number,
    uuid?: string,
    httpCode?: string,
    exceptionName?: string,
    exceptionMessage?: string,
    path?: string
}

export enum ExceptionsFieldOrderBy {
    EXCEPTION_UUID = "EXCEPTION_UUID",
    EXCEPTION_DATE = "EXCEPTION_DATE",
    EXCEPTION_HTTP_RESPONSE_CODE = "EXCEPTION_HTTP_RESPONSE_CODE",
    EXCEPTION_NAME = "EXCEPTION_NAME",
    EXCEPTION_MESSAGE = "EXCEPTION_MESSAGE",
    EXCEPTION_PATH = "EXCEPTION_PATH"
}