import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Drawer, Grid, IconButton, LinearProgress, styled, Theme, useMediaQuery } from '@mui/material'
import Menu from '@mui/icons-material/Menu';
import Logo from '../Logo/Logo'
import { setLicensingPage, setSettingPage } from '../../Store/Navigation/reducer';
import { ActionType } from '../../Store/Navigation/actions';
import { THEME } from '../../config';
import { useHistory, useLocation } from 'react-router-dom';
import { SettingType } from '../../containers/Settings/Settings';
import MenuItem from './MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { APIsContext } from '../../Store/APIs/context';
import { useSnackbar } from 'notistack';
import { NavigationContext } from '../../Store/Navigation/context';
import { LicenseTabs, LicensingType } from '../../Store/Navigation/state';

const ReactiveLogoContainer = styled(Grid)(() => ({
    minHeight: "inherit",
    height: "inherit",
    padding: "0.5rem 0",
    flex: 1
}));

const ReactiveMenuContainer = styled(Grid)(() => ({
    minHeight: "inherit",
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
}));

const ReactiveNavigationContent = styled(Grid)(({ theme }) => ({
    height: "inherit",
    minHeight: "inherit",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    padding: "0 1rem",
    [theme.breakpoints.down("sm")]: {
        padding: "0"
    }
}));

const ReactiveNavigationBar = styled(AppBar)(({ theme }) => ({
    minHeight: "50px",
    height: "7vh",
    width: "100%",
    zIndex: 5,
    top: 0,
    position: "sticky",
    background: "#f8f8f8",
    borderBottom: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#dce1e6",
    display: "block",
    [theme.breakpoints.down("md")]: {
        height: "5vh",
    }
}));

const MobileAccordion = styled(Accordion)(() => ({
    boxShadow: "none",
    borderRadius: "0px",
    borderBottom: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#dce1e6"
}))

const MobileAccordionSummary = styled(AccordionSummary)(() => ({
    padding: "0px",
    borderBottomColor: "#a8a8a8",
    borderBottomStyle: "solid",
    borderBottomWidth: "0px",
    margin: "0 0 0 0"
}))

const MobileAccordionDetails = styled(AccordionDetails)(() => ({
    display: "table-caption",
    padding: "0px",
    width: "200%"
}))

const Navigation: React.FC = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const { state, dispatch } = useContext(NavigationContext)
    const apiState = useContext(APIsContext).state
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [mobileDrawer, setMobileDrawer] = useState(false)
    const [settingDrawerAccordion, setSettingDrawerAccordion] = useState(false)
    const [licensingDrawerAccordion, setlicensingDrawerAccordion] = useState(false)
    const [optionsActive, setOptionsActive] = useState(true)
    const [licensingTabsActive, setLicensingTabsActive] = useState<LicenseTabs>({usage: false, cloud: false, internetLicensing: false, dongles: false})
    const [developerOptionsActive, setDeveloperOptionsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        dispatch({ type: ActionType.ToggleMobileMenu, toggle: matches })
    }, [matches, dispatch])

    useEffect(() => {
        setOptionsActive(state.optionsActive)
    }, [state.optionsActive])
    
    useEffect(() => {
        if ((apiState.ImagesApi as any).configuration === undefined) {
            history.push("/login")
        }
    }, [apiState, history])

    useEffect(() => {
        if (state.licensingTabs.cloud === false && state.licensingTabs.dongles === false &&
            state.licensingTabs.internetLicensing === false && state.licensingTabs.usage === false) {
                enqueueSnackbar("Cannot connect to licensing server", {variant: "error"})
            }
    }, [state.licensingTabs, enqueueSnackbar])

    useEffect(() => {
        setIsLoading(state.isLoading)
    }, [state.isLoading])

    useEffect(() => {
        setDeveloperOptionsActive(state.developerOptions)
    }, [state.developerOptions])

    useEffect(() => {
        setLicensingTabsActive(state.licensingTabs)
    }, [state.licensingTabs])

    const handleMenuItemClick = (route: string, drawer?: boolean, settingType?: SettingType | undefined, licensingType?: LicensingType) => {
        history.push(route)
        if (drawer) {
            setMobileDrawer(false)
        }
        if (settingType) {
            dispatch(setSettingPage(settingType))
        }
        if (licensingType) {
            dispatch(setLicensingPage(licensingType))
        }
    }

    const getSettingAccordion = () => {
        return (
            <MobileAccordion
                expanded={settingDrawerAccordion}
                onChange={() => setSettingDrawerAccordion(!settingDrawerAccordion)}
            >
                <MobileAccordionSummary
                    expandIcon={
                        <ExpandMoreIcon fontSize='medium' style={{color: THEME.palette.primary.main}}/>
                    }
                    id="Accordion"
                >
                    <MenuItem name="Settings" disableRipple />
                </MobileAccordionSummary>
                <MobileAccordionDetails>
                    <MenuItem name="Capturing" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.CAPTURING)}/>
                    <MenuItem name="Verification" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.VERIFICATION)}/>
                    <MenuItem name="Liveness" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.LIVENESS)}/>
                    <MenuItem name="ICAO" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.ICAO)}/>
                    <MenuItem name="Management" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.MANAGEMENT)}/>
                    {developerOptionsActive ? <MenuItem name="Developer" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.DEVELOPER)}/> : null }
                    <MenuItem name="Reset" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.RESET)}/>
                    <MenuItem name="About" sub onClick={() => handleMenuItemClick("/settings", true, SettingType.ABOUT)} />
                </MobileAccordionDetails>
            </MobileAccordion>
        )
    }

    const getLicensingAccordrion = () => {
        return (
            <MobileAccordion
                expanded={licensingDrawerAccordion}
                onChange={() => setlicensingDrawerAccordion(!licensingDrawerAccordion)}
            >
                <MobileAccordionSummary
                    expandIcon={
                        <ExpandMoreIcon fontSize='medium' style={{color: THEME.palette.primary.main}}/>
                    }
                    id="Accordion"
                >
                    <MenuItem name="Licensing" disableRipple />
                </MobileAccordionSummary>
                <MobileAccordionDetails>
                    {licensingTabsActive.usage?             <MenuItem name="Usage" sub onClick={() => handleMenuItemClick("/licensing", true, undefined, LicensingType.USAGE)}/> : null}
                    {licensingTabsActive.cloud?             <MenuItem name="Cloud" sub onClick={() => handleMenuItemClick("/licensing", true, undefined, LicensingType.CLOUD)}/> : null}
                    {licensingTabsActive.internetLicensing? <MenuItem name="Internet licensing" sub onClick={() => handleMenuItemClick("/licensing", true, undefined, LicensingType.INTERNET_LICENSING)}/> : null}
                    {licensingTabsActive.dongles?           <MenuItem name="Dongles" sub onClick={() => handleMenuItemClick("/licensing", true, undefined, LicensingType.DONGLES)}/> : null}
                </MobileAccordionDetails>
            </MobileAccordion>
        )
    }

    const navPicker = () => {
        if (!optionsActive) {
            return (<></>)
        }
        if (state.isMobile) {
            return (
                <IconButton sx={{ color: THEME.palette.primary.main }} onClick={() => setMobileDrawer(true)}>
                    <Menu fontSize="large" />
                </IconButton>
            )
        } else {
            return (
                <React.Fragment>
                    <MenuItem name="Subjects" onClick={() => handleMenuItemClick("/subjects")} selected={pathname === "/subjects"}/>
                    <MenuItem name="Operations" onClick={() => handleMenuItemClick("/operations")} selected={pathname === "/operations"}/>
                    <MenuItem name="Exceptions" onClick={() => handleMenuItemClick("/exceptions")} selected={pathname === "/exceptions"}/>
                    <MenuItem name="Settings" onClick={() => handleMenuItemClick("/settings")} selected={pathname === "/settings"}/>
                    <MenuItem name="Licensing" onClick={() => handleMenuItemClick("/licensing")} selected={pathname === "/licensing"}/>
                    <MenuItem name="Janus" onClick={() => handleMenuItemClick("/janus")} selected={pathname === "/janus"}/>
                </React.Fragment>
            )
        }
    }

    const getDrawer = () => {
        return (
            <Drawer
                anchor="right"
                open={mobileDrawer}
                onClose={() => setMobileDrawer(false)}
            >
                <MenuItem name="Subjects" onClick={() => handleMenuItemClick("/subjects", true)} slightBorder />
                <MenuItem name="Operations" onClick={() => handleMenuItemClick("/operations", true)} slightBorder/>
                <MenuItem name="Exceptions" onClick={() => handleMenuItemClick("/exceptions", true)} slightBorder/>
                <MenuItem name="Janus" onClick={() => handleMenuItemClick("/janus", true)} slightBorder/>
                {getSettingAccordion()}
                {getLicensingAccordrion()}
            </Drawer>
        )
    } 

    return (
        <>
            {getDrawer()}
            <ReactiveNavigationBar position="static" elevation={0}>
                <ReactiveNavigationContent container spacing={0}>
                    <ReactiveLogoContainer item>
                        <Logo nav/>
                    </ReactiveLogoContainer>
                    <ReactiveMenuContainer item>
                        {navPicker()}
                    </ReactiveMenuContainer>
                </ReactiveNavigationContent>
                {isLoading ? <LinearProgress style={{zIndex: "1000"}} color="primary" /> : null}
            </ReactiveNavigationBar>
        </>
    )
}

export default Navigation