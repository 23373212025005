import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme, styled, useMediaQuery } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MultimodalDownloadOption } from './ImageInfoDownload';
import {BiometricFaceData, BiometricVoiceData, MultimodalSubject, Operation, Subject} from 'neurotec-megamatcherid-management-client';
import ImageCanvas from './ImageCanvas';
import { useSnackbar } from 'notistack';
import { APIsContext } from '../../Store/APIs/context';
import ConfirmationButton from '../Misc/Dialog/ConfirmationButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OverviewInfo from './OverviewInfo';
import DialogTitleClose from '../Misc/Dialog/Components/DialogTitleClose';
import { NavigationContext } from '../../Store/Navigation/context';
import { setLoading, setSubjectUUID } from '../../Store/Navigation/reducer';
import ButtonLightGray from '../Filter/Components/ButtonLightGray';
import { useHistory } from 'react-router-dom';
import { OperationType } from '../../Store/Operations/types';
import { Status } from '../../Store/Subjects/types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SelectionIconButton } from './SelectionIconButton';
import { THEME } from '../../config';
import AudioOperationSummary from './AudioOperationSummary';
import BiometricDataDownload from './ImageInfoDownload';

const GridImage = styled(Grid)(({theme}) => ({
    minHeight: "5rem",
    paddingRight: "0.5rem",
    [theme.breakpoints.down("md")]: {
        marginBottom: "3rem",
        paddingRight: "0rem"
    }
}));

interface IOverviewDialogProps {
    multimodalSubject?: MultimodalSubject,
    singleOperation?: Operation,
    open: boolean,
    onClose: () => void,
    onImageDelete: () => void,
    onImageDownload: (id: string, downloadOptions: any) => void,
    isDeletable: boolean,
    isOperation?: boolean,
}

const OverviewDialog: React.FC<IOverviewDialogProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [imageInfo, setImageInfo] = useState<BiometricFaceData | void | null>(undefined);
    const [audioInfo, setAudioInfo] = useState<BiometricVoiceData | void | null>(undefined)
    const history = useHistory();
    const navigationDispatch = useContext(NavigationContext).dispatch
    const apisState = useContext(APIsContext).state
    const [operationList, setOperationList] = useState<Operation[]>([])
    const [activeModalityIndex, setActiveModalityIndex] = useState(0)
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const tokenizeOperation = useCallback(() => {
        let list: Operation[] = []
        if (props.singleOperation !== undefined) {
            list.push(props.singleOperation)
        }
        if (props.multimodalSubject !== undefined) {
            props.multimodalSubject.multimodalOperations?.forEach(o => list.push(o))
        }
        return list
    }, [props.multimodalSubject, props.singleOperation])

    useEffect(() => {
        setOperationList(tokenizeOperation())
        setActiveModalityIndex(0)
    }, [props.open, tokenizeOperation])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (operationList[activeModalityIndex] && operationList[activeModalityIndex].modality) {
                    navigationDispatch(setLoading(true))
                    switch (operationList[activeModalityIndex].modality) {
                        case 'FACE_MODALITY':
                            if (operationList[activeModalityIndex].uuid !== undefined) {
                                apisState.BiometricDataApi.getBiometricFaceDataByUUID({uuid: operationList[activeModalityIndex].uuid as string}).then(res => {
                                    navigationDispatch(setLoading(false))
                                    if (res.data === undefined) {
                                        setImageInfo(null)
                                        return
                                    };
                                    setImageInfo(res.data)
                                }).catch(err => {
                                    navigationDispatch(setLoading(false))
                                    setImageInfo(null)
                                    if (err.status !== 404) {
                                        enqueueSnackbar(err.message, { variant: 'error' });
                                    } else {
                                        enqueueSnackbar("Image no found", { variant: 'warning' });
                                    }
                                })
                            }
                            return;
                        case 'VOICE_MODALITY':
                            if (operationList[activeModalityIndex].uuid !== undefined) {
                                apisState.BiometricDataApi.getBiometricVoiceDataByUUID({uuid: operationList[activeModalityIndex].uuid as string}).then(res => {
                                    navigationDispatch(setLoading(false))
                                    if (res.data === undefined) {
                                        setAudioInfo(null)
                                    }
                                    setAudioInfo(res.data)
                                }).catch(err => {
                                    navigationDispatch(setLoading(false))
                                    setImageInfo(null)
                                    if (err.status !== 404) {
                                        enqueueSnackbar(err.message, { variant: 'error' });
                                    } else {
                                        enqueueSnackbar("Audio no found", { variant: 'warning' });
                                    }
                                })
                            }
                            return;
                        default:
                    }
                }
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
        fetchData();
        
        return (() => {
            setImageInfo(undefined)
            setAudioInfo(undefined)
        })
    }, [operationList, activeModalityIndex, apisState.BiometricDataApi, apisState.ImagesApi, enqueueSnackbar, navigationDispatch])

    const handleDownload = (downloadOptions: MultimodalDownloadOption[]) => {
        if (props.multimodalSubject?.subjectId) {
            props.onImageDownload(props.multimodalSubject?.subjectId, downloadOptions)
        } else {
            enqueueSnackbar("Can't get subject id", {variant: "error"})
        }      
    }

    const getImageArrowSelection = (left: boolean) => {
        return (
            operationList.length > 1?
                <SelectionIconButton
                    onClick={left? () => {setActiveModalityIndex(p => p + 1)} : () => {setActiveModalityIndex(p => p - 1)}}
                    disabled={left? activeModalityIndex + 1 >= operationList.length : activeModalityIndex <= 0}
                    style={{right: left? "15px" : undefined, left: left? undefined : "15px", position: "static", marginLeft: "0.5rem"}}
                    sx={{"&.Mui-disabled": {backgroundColor: "#e8e8e8"}}}
                    disableRipple
                >
                    <ArrowBackIosNewIcon style={{color: THEME.palette.text.secondary, transform: left? "scale(-1)" : "scale(1)"}} />
                </SelectionIconButton >
            : null
        )
    }

    const getSelectArrowBox = () => {
        return (
            <Box>
                {getImageArrowSelection(false)}
                {getImageArrowSelection(true)}
            </Box>
        )
    }

    return (
        <Dialog
            maxWidth="md"
            scroll="body"
            fullWidth
            fullScreen={isSmall ? true : false} 
            open={props.open}
            onClose={props.onClose}
            PaperProps={{sx: {margin: "0rem"}}}
        >
            <DialogTitle>
                <DialogTitleClose
                    title={props.isOperation? "Operation's information" : "Subject's information"}
                    onClose={props.onClose}
                />
            </DialogTitle>
            <DialogContent style={{paddingBottom: "0px"}}> 
                <Grid container>
                    {operationList[activeModalityIndex]? 
                    <>
                        <GridImage item xs={12} md={5}>
                            {
                                operationList[activeModalityIndex].modality === "FACE_MODALITY"?
                                    <ImageCanvas
                                        image={imageInfo}
                                        clickable={true}
                                        rectangle={operationList[activeModalityIndex].boundingRect}
                                        subjectId={(operationList[activeModalityIndex] as Subject).subjectId ?? "None"}
                                    /> :
                                    <AudioOperationSummary
                                        audioInfo={audioInfo}
                                    />
                            }

                        </GridImage>
                        <Grid item xs={12} md={7}>
                            <OverviewInfo
                                modality={operationList[activeModalityIndex].modality}
                                operation={operationList[activeModalityIndex]}
                                isOperation={props.isOperation}
                            />
                        </Grid>
                    </> : null
                    }
                </Grid> 
            </DialogContent>
            <DialogActions style={{display: "flex"}}>
                {props.isDeletable?
                    <ConfirmationButton
                        icon={<DeleteIcon />}
                        buttonTitle="Delete"
                        title="Confirmation"
                        text="Are you sure you want to delete seleected subject(s)? All subject information will be removed and this action cannot be undone."
                        cancelText="Cancel"
                        confirmText="Delete"
                        confirm={props.onImageDelete}
                        confirmColor='error'
                    />
                    : null}
                <Box style={{ flex: '1 0 0' }} />
                {
                    props.isOperation && operationList[activeModalityIndex]?.uuid !== undefined &&
                    (operationList[activeModalityIndex]?.uuid as string).length > 0 &&
                    (operationList[activeModalityIndex].operationType === OperationType.CREATE_TEMPLATE || operationList[activeModalityIndex].operationType === OperationType.REEXTRACT_TEMPLATE) &&
                    operationList[activeModalityIndex].status === Status.SUCCESS?
                    <ButtonLightGray
                        onClick={() => {
                            navigationDispatch(setSubjectUUID(operationList[activeModalityIndex]?.uuid as string))
                            history.push("/subjects")
                        }}
                    >
                        Go to Subject
                    </ButtonLightGray> : null
                }
                {
                    props.isOperation && operationList[activeModalityIndex]?.uuid !== undefined &&
                    (operationList[activeModalityIndex]?.uuid as string).length > 0 &&
                    operationList[activeModalityIndex].operationType === OperationType.VERIFY &&
                    operationList[activeModalityIndex].subjectTableId !== 0 &&
                    operationList[activeModalityIndex].subjectTableId !== undefined?
                    <ButtonLightGray
                        onClick={() => {
                            apisState.SubjectsApi.getMultimodalSubjectById({id: (operationList[activeModalityIndex].subjectTableId as number)}).then(res => {
                                if (res.data.multimodalOperations !== undefined && res.data.multimodalOperations[0].uuid !== undefined ) {
                                    navigationDispatch(setSubjectUUID(res.data.multimodalOperations[0].uuid as string))
                                    history.push("/subjects")
                                }
                            }).catch(e => {console.error(e)})
                        }}
                    >
                        Go to Subject
                    </ButtonLightGray> : null
                }
                {isSmall ? null : getSelectArrowBox()}
                <BiometricDataDownload
                    onDownloadClick={handleDownload}
                    single
                    faceImageExists={imageInfo?.image !== undefined && imageInfo.image.length > 0}
                    faceTemplateExists={imageInfo?.template !== undefined && imageInfo.template.length > 0}
                    faceTokenImageExists={imageInfo?.tokenImage !== undefined && imageInfo.tokenImage.length > 0}
                    voiceAudioExists={audioInfo?.audio !== undefined && audioInfo.audio.length > 0}
                    voiceTemplateExists={audioInfo?.template !== undefined && audioInfo.template.length > 0}
                />
            </DialogActions>
            {isSmall ? 
                <DialogActions style={{display: "flex", justifyContent: "center"}}>
                    {getSelectArrowBox()}
                </DialogActions>
            : null}
        </Dialog>
    )
}

export default OverviewDialog
