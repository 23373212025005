import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material"

interface ICollapseCallStack {
    open: boolean,
    exceptionName: string,
    exceptionMessage: string,
    path: string,
    callstack: String
    key: number
}

const CollapseCallStack: React.FC<ICollapseCallStack> = (props) => {

    const handleCallStackLine = (str: String) => {
        if (str === undefined || str.length === 0) {
            return;
        }
        let cutString = str.split(/\r?\n/)
        return cutString.map((line, key) => {
            let index = line.indexOf("(")
            return (
                <div style={{display: "flex"}} key={key}>
                    <div>
                        {line.substring(0, index)}
                    </div>
                    <div style={{fontWeight: "600"}}>
                        {line.substring(index)}
                    </div>
                </div>
            )
        })
    }

    const handleExceptionMessage = (str: String) => {
        let index = str.indexOf("Inner exception");
        if (index === -1) {
            return (str)
        } else {
            return (
                str.substring(0, index)
            )
        }
    }

    const handleExceptionMessageInner = (str: String) => {
        let index = str.indexOf("Inner exception");
        if (index === -1) {
            return (null)
        } else {
            return (
                str.substring(index + 16) // move index by 16 to not show "inner exception:" in message
            )
        }
    }

    return (
        <TableRow style={{backgroundColor: "#f8f8f8"}}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <Box>
                        <Typography variant="h6" gutterBottom component="div" style={{marginBottom: "0px", marginTop: "0.5rem", wordBreak: "break-word"}}>
                            {handleExceptionMessage(props.exceptionMessage)}
                        </Typography>
                        <Typography gutterBottom component="div" fontWeight={"500"} style={{wordBreak: "break-word"}}>
                            {handleExceptionMessageInner(props.exceptionMessage)}
                        </Typography>
                        <Typography gutterBottom component="div" style={{wordBreak: "break-word"}}>
                            {props.exceptionName}
                        </Typography>
                        <Typography gutterBottom component="div" style={{wordBreak: "break-word"}}>
                            {props.path}
                        </Typography>
                        <Typography
                            gutterBottom
                            component="div"
                            style={{
                                fontFamily: "monospace",
                                display: "table-caption",
                                fontSize: "0.9rem"
                            }}
                        >
                            {handleCallStackLine(props.callstack)}
                        </Typography>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

export default CollapseCallStack