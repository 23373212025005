import { Grid, Typography } from '@mui/material';
import ImageInfoDownload from '../../OverviewView/ImageInfoDownload';
import ConfirmationButton from '../Dialog/ConfirmationButton';
import DeleteIcon from '@mui/icons-material/Delete';
import GridContainer from '../../Filter/Components/grid/GridContainer';


interface IEnhancedTableToolbarProps {
    selected: number,
    onDeleteClick?: () => void,
    onDownloadClick: ((downloadOptions: any) => void),
}

const EnhancedTableToolbar = (props: IEnhancedTableToolbarProps) => {

    const { selected, onDeleteClick } = { ...props }

    return (
        <GridContainer container>
            <Grid item xs={3} md={2} style={{position: "relative"}}>
                <Typography
                    sx={{ position: "absolute", top: "25%" }}
                    color={"#898989"}
                    component="div"
                >
                    {selected} selected
                </Typography>
            </Grid>
            <Grid item xs={9} md={10} textAlign={"left"} style={{display: "inline-flex"}}>
                <ImageInfoDownload onDownloadClick={props.onDownloadClick} />
                {onDeleteClick !== undefined?
                    <ConfirmationButton
                        icon={<DeleteIcon />}
                        buttonTitle="Delete"
                        title="Confirmation"
                        text="Are you sure you want to delete seleected subject(s)? All subject information will be removed and this action cannot be undone."
                        cancelText="Cancel"
                        confirmText="Delete"
                        confirm={onDeleteClick}
                        confirmColor='error'
                        style={{paddingLeft: "1rem"}}
                    /> : <></>
                }
            </Grid>
        </GridContainer>
    );
}

export default EnhancedTableToolbar;

