import { Container } from "@mui/material"
import CollapsibleDataTable from "../../components/Misc/CollapsibleDataTable/CollapsibleDataTable"
import { useCallback, useContext, useEffect, useState } from "react"
import { NavigationContext } from "../../Store/Navigation/context"
import { useSnackbar } from "notistack"
import { setLoading } from "../../Store/Navigation/reducer"
import { APIsContext } from "../../Store/APIs/context"
import { IExceptionFilter } from "../../Store/Exceptions/types"
import { ExceptionModel } from "neurotec-megamatcherid-management-client"
import DatatableFooter from "../../components/Misc/DataTable/DatatableFooter"
import { HeadCell } from "../../components/Misc/DataTable/DataTable"
import ExceptionFilter from "../../components/Filter/ExceptionFilter"
import { FieldsOrder } from "../../Store/Subjects/types"

const headCells: HeadCell[] = [
    {
        id: 'EXCEPTION_DATE',
        align: 'left',
        disablePadding: false,
        label: 'Date',
        width: "20%"
    },
    {
        id: 'EXCEPTION_NAME',
        align: 'left',
        disablePadding: false,
        label: 'Name',
        width: "25%"
    },
    {
        id: 'EXCEPTION_HTTP_RESPONSE_CODE',
        align: 'left',
        disablePadding: false,
        label: ' HTTP',
        width: "5%"
    },
    {
        id: 'EXCEPTION_PATH',
        align: 'left',
        disablePadding: false,
        label: 'Path',
        width: "20%"
    },
    {
        id: 'EXCEPTION_UUID',
        align: 'left',
        disablePadding: true,
        label: 'UUID',
        width: "25%"
    },
];

const Exceptions: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const navigationDispatch = useContext(NavigationContext).dispatch
    const apisState = useContext(APIsContext).state
    const [exceptionsFilter, setExceptionsFilter] = useState<IExceptionFilter>({fieldsOrder: FieldsOrder.DESC});
    const [exceptionModels, setExceptionModels] = useState<ExceptionModel[]>([]);
    const [exceptionstCount, setExceptionsCount] = useState(0);

    const fetchExceptions = useCallback(async () => {
        try {
            navigationDispatch(setLoading(true))
            apisState.ExceptionsApi.getExceptionLog({page: page, size: perPage, ...exceptionsFilter}).then(res => {
                setExceptionModels(res.data)
                navigationDispatch(setLoading(false))
            }).catch(e => {
                console.log(e)
                enqueueSnackbar(e.message, {variant: "error"})
                navigationDispatch(setLoading(false))
            })

            apisState.ExceptionsApi.getExceptionLogCount(exceptionsFilter).then(res => {
                setExceptionsCount(res.data as unknown as number)
            }).catch(e => {
                console.log(e)
                enqueueSnackbar(e.message, {variant: "error"})
            })
            
        } catch (error: any) {
            console.log(error)
            enqueueSnackbar(error.message, {variant: "error"})
        }
    }, [page, perPage, apisState.ExceptionsApi, exceptionsFilter, enqueueSnackbar, navigationDispatch])

    useEffect(() => {
        fetchExceptions();
    }, [apisState.ExceptionsApi, exceptionsFilter, page, perPage, fetchExceptions, navigationDispatch])

    const handlePageChange = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
        setPage(newPage)
    }

    const handleFilterChange = (filter: IExceptionFilter) => {
        setPage(1)
        setExceptionsFilter(filter)
    }

    const handleOnCollapseClick = async (id: number) => {
        return await apisState.ExceptionsApi.getExceptionsCallstackById({id: id})
    }

    return (
        <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "column" }}>
            <ExceptionFilter
                filter={exceptionsFilter}
                onFilterApply={handleFilterChange}
                reloadFetch={() => fetchExceptions()}
            />
            <CollapsibleDataTable
                title="Exceptions"
                data={exceptionModels}
                headCells={headCells}
                filter={exceptionsFilter}
                onFilterChange={handleFilterChange}
                onCollapseClick={handleOnCollapseClick}
            />
            <DatatableFooter
                page={page}
                handlePageChange={handlePageChange}
                count={exceptionstCount}
                perPage={perPage}
                setPerPage={setPerPage}
                label='Exceptions'
            />
        </Container>
    )
}

export default Exceptions