import React, { createContext } from "react";
import { APIsState, initAPIsState } from "./state";
import { APIsActions } from "./actions";

export const APIsContext = createContext<{
    state: APIsState,
    dispatch: React.Dispatch<APIsActions>
}>({
    state: initAPIsState,
    dispatch: () => undefined
});