import { Button } from "@mui/material"
import { useSnackbar } from "notistack";
import { APIsContext } from "../../Store/APIs/context";
import { ChangeEvent, useContext, useState } from "react";
import { ExceptionModel } from "neurotec-megamatcherid-management-client";

interface IImportExceptionButtonProps {
    refresh: () => void
}

const ImportExceptionButton: React.FC<IImportExceptionButtonProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const { state } = useContext(APIsContext)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [importTitle, setImportTitle] = useState("Import")

    const handleFileUploadAndImport = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        if (file === undefined) return

        setImportTitle("Importing...")
        setButtonsDisabled(true)
        file.text().then(res => {
            state.ExceptionsApi.importExceptionList({exceptionModel: res as unknown as ExceptionModel[]}).then((r) => {
                if (r.status.toString() === "200") {
                    enqueueSnackbar("Succesfully imported exceptions", {variant: "info"})
                } else {
                    enqueueSnackbar("Failed to import exceptions: " + r.status, {variant: "error"})
                    console.log(r)
                }
                props.refresh()
                setButtonsDisabled(false)
                setImportTitle("Import")
            }).catch((e) => {
                enqueueSnackbar("Failed to import exceptions", {variant: "error"})
                setButtonsDisabled(false)
                setImportTitle("Import")
                console.log(e)
            })
        }).catch(e => {
            enqueueSnackbar("Invalid file", {variant: "error"})
            setButtonsDisabled(false)
            setImportTitle("Import")
        })
    }

    return (
        <Button
            style={{marginLeft: "0.5rem"}}
            color="primary"
            variant="contained"
            component="label"
            disabled={buttonsDisabled}
        >
            {importTitle}
            <input
                hidden
                type='file'
                onChange={handleFileUploadAndImport}
            />
        </Button>
    )
}

export default ImportExceptionButton