import { Typography, TypographyProps, styled } from "@mui/material";

const StyledJanusTypographyTitle = styled(Typography)(()=>({
    fontWeight: "500",
    borderBottomStyle: "solid",
    borderBottomColor: "#D7D7D7"
}))

interface IJanusTypographyTitle extends TypographyProps {}

const JanusTypographyTitle: React.FC<IJanusTypographyTitle> = (props) => {
    return (
        <StyledJanusTypographyTitle {...props} />
    )
}

export default JanusTypographyTitle

