
export enum Operations {
    UNSPECIFIED = 0,
    VERIFY = 1,
    CHECKS = 2,
    ENROLL = 3,
    VERIFY_WITH_QR = 99,
};

export enum LivenessAction {
    KEEP_STILL = 1,
    BLINK = 2,
    ROTATE_YAW = 4,
    KEEP_ROTATING_YAW = 8,
    TURN_TO_CENTER = 16,
    TURN_LEFT = 32,
    TURN_RIGHT = 64,
    TURN_UP = 128,
    TURN_DOWN = 256,
    MOVE_CLOSER = 512,
    MOVE_BACK = 1024,
};

export enum LivenessMode {
    NONE = 0,
    PASSIVE = 1,
    ACTIVE = 2,
    SIMPLE = 4,
    CUSTOM = 5,
    PASSIVE_WITH_BLINK = 6,
};

export enum Status {
    NONE = 0,
    SUCCESS = 1,
    TIMEOUT = 2,
    CANCELED = 3,
    BAD_QUALITY = 4,
    MATCH_NOT_FOUND = 5,
    CAMERA_NOT_FOUND = 6,
    FACE_NOT_FOUND = 7,
    LIVENESS_CHECK_FAILED = 8,
    BAD_SHARPNESS = 9,
    TOO_NOISY = 10,
    BAD_LIGHTNING = 11,
    OCCLUSION = 12,
    BAD_POSE = 13,
    TOO_MANY_OBJECTS = 14,
    MASK_DETECTED = 15,
    DUPLICATE_FOUND = 16,
    DUPLICATE_ID = 17,
    MOTION_BLUR = 18,
    COMPRESSION_ARTIFACTS = 19,
    TOO_DISTANT = 20,
    TOO_CLOSE = 21,
    FAILED = 22,
    INITIALIZED = 700,
    JANUS_UNKNOWN_ERROR = 701,
    JANUS_PLUGIN_ERROR = 702,
    JANUS_NOT_FOUND = 703,
    JANUS_BAD_REQUEST = 704,
    JANUS_ICE_FAILED = 705,
    CHUNK_RECEIVE_TIMEOUT = 996,
	STREAM_DECODE_FAILED = 997,
    ADJUSTING_QUALITY = 998,
    INTERNAL_ERROR = 999,
};

export const StatusMessages = {
    [Status.NONE]: 'None',
    [Status.SUCCESS]: 'Success',
    [Status.TIMEOUT]: 'Timeout',
    [Status.CANCELED]: 'Canceled',
    [Status.BAD_QUALITY]: 'Bad Quality',
    [Status.MATCH_NOT_FOUND]: 'Match Not Found',
    [Status.CAMERA_NOT_FOUND]: 'Camera Not Found',
    [Status.FACE_NOT_FOUND]: 'Face Not Found',
    [Status.LIVENESS_CHECK_FAILED]: 'Liveness Check Failed',
    [Status.BAD_SHARPNESS]: 'Bad Sharpness',
    [Status.TOO_NOISY]: 'Too Noisy',
    [Status.BAD_LIGHTNING]: 'Bad Lighting',
    [Status.OCCLUSION]: 'Occlusion',
    [Status.BAD_POSE]: 'Bad Pose',
    [Status.TOO_MANY_OBJECTS]: 'Too Many Faces',
    [Status.MASK_DETECTED]: 'Mask Detected',
    [Status.DUPLICATE_FOUND]: 'Duplicate Found',
    [Status.DUPLICATE_ID]: 'Duplicate Id Found',
    [Status.MOTION_BLUR]: 'Motion Blur',
    [Status.COMPRESSION_ARTIFACTS]: 'Compression Artifacts',
    [Status.TOO_DISTANT]: 'Too Far',
    [Status.TOO_CLOSE]: 'Too Close',
    [Status.FAILED]: 'Failed',
    [Status.INITIALIZED]: 'Initialized',
    [Status.JANUS_UNKNOWN_ERROR]: 'Janus unknown error',
    [Status.JANUS_PLUGIN_ERROR]: 'Janus plugin error',
    [Status.JANUS_NOT_FOUND]: 'Janus not found',
    [Status.JANUS_BAD_REQUEST]: 'Janus bad request',
    [Status.JANUS_ICE_FAILED]: 'Janus ICE failed',
    [Status.CHUNK_RECEIVE_TIMEOUT]: 'Chunk receive timeout',
    [Status.STREAM_DECODE_FAILED]: 'Stream decode failed',
    [Status.ADJUSTING_QUALITY]: 'Adjusting Quality',
    [Status.INTERNAL_ERROR]: 'Internal Error',
};

export interface IManagementInfo {
    allowSettingsOverride: boolean,
    allowClearDb: boolean,
    prefixMode: boolean,
    version: string,
    revision: string,
    buildDate: Date,
    livenessMode: number,
    checkIcaoCompliance: boolean,
};

export enum ILivenessActionSequence {
    "blink" = "Blink",
    "turnLeft" = "Turn left",
    "turnRight" = "Turn right",
    "turnUp" = "Turn up",
    "turnDown" = "Turn down",
    "turnWithTargets" = "Turn with targets",
    "keepStill" = "Keep still"
}

export enum IOperationsStatus {
    ACTIVE = "ACTIVE",
    IDLE = "IDLE",
    DONE = "DONE",
    SUCCESS = "SUCCESS"
}

export enum IICAOPositionalWarnings {
    RollLeft = "Roll left",
    RollRight = "Roll right",
    YawLeft = "Yaw left",
    YawRight = "Yaw right",
    PitchUp = "Pitch up",
    PitchDown = "Pitch down",
    TooNear = "Too near",
    TooFar = "Too far",
    TooNorth = "Too north",
    TooSouth = "Too south",
    TooEast = "Too east",
    TooWest = "Too west",
}
