import { useEffect, useState } from "react"
import { NormalTableRow, SmallContentTableRow, SmallTextTableRow } from "../Settings"
import SliderCell, { ISliderCell } from "./SliderCell"
import TextCell from "./TextCell"
import { Skeleton, TableCell, styled } from "@mui/material"

const SkeletonSlider = styled(Skeleton)(() => ({
    width: "100%",
    height: "100%",
    minHeight: "7vh"
}));

interface ISliderRow extends ISliderCell{
    mobile?: boolean,
    textTitle: string,
    subtitle: string
}

const SliderRow: React.FC<ISliderRow> = (props) => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (props.option === undefined) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [props.option])

    const getSkeleton = () => (
        <TableCell colSpan={3} style={{paddingLeft: "inherit"}}>
            <SkeletonSlider animation="wave" variant="text"/>
        </TableCell>
    )
 
    const getSlider = () => {
        return(
            <SliderCell
                title={props.title}
                option={props.option}
                defaultOption={props.defaultOption}
                setOption={props.setOption}
                minValue={props.minValue}
                maxValue={props.maxValue}
                lockMaxValue={props.lockMaxValue}
                lockMinValue={props.lockMinValue}
                type={props.type}
                active={props.active}
                info={props.info}
                colspan={3}
                steps={props.steps}
                basic={props.basic}
                noWarningMsg={props.noWarningMsg}
                alwaysWarningMsg={props.alwaysWarningMsg}
                badValueRange={props.badValueRange}
            />
        )
    }

    return (
        props.mobile?
            <>
            <SmallTextTableRow>
                <TextCell colspan={3} title={props.textTitle} subtitle={props.subtitle}/>
            </SmallTextTableRow>
            <SmallContentTableRow>
                {loading? getSkeleton() : getSlider()}
            </SmallContentTableRow>
            </>
            :
            <NormalTableRow>
                <TextCell title={props.textTitle} subtitle={props.subtitle}/>
                {loading? getSkeleton() : getSlider()}
            </NormalTableRow>
        )
}

export default SliderRow