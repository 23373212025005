import icon from '../../assets/icon.png'
import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { THEME } from '../../config';

const LogoImage = styled('img')({
    width: "auto",
    height: "100%",
});

const LinkButton = styled(Button)({
    height: "100%",
    color: THEME.palette.secondary.main,
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    textDecoration: "none",
    ":hover": {
        opacity: 1
    }
})

interface ILogoProps {
    nav?: boolean
    login?: boolean
}

const Logo: React.FC<ILogoProps> = (props) => {

    const history = useHistory()

    return (
        <LinkButton color="primary" onClick={() => history.push("/")} disabled={props.login}>
            <Box sx={{
                display: "inline-flex",
                alignItems: "center",
                minWidth: {
                    xs: "227px",
                    md: "280px"
                },
                height: "100%",
                width: "auto"
            }}>
                <LogoImage src={icon} alt="" />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Typography sx={{
                        color: "#1b1c1e",
                        fontSize: {
                            xs: "1.1rem",
                            sm: "1.3rem"
                        },
                        fontWeight: "500",
                    }}>Mega Matcher Id Demo</Typography>
                </Box>
            </Box>
        </LinkButton>
    )
}

export default Logo
