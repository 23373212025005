import { TableCell, TableRow, styled } from "@mui/material";
import { handleNumberHelper, handleStringHelper, selectStatusColorHelper } from "../../helpers/displayStringHelpers";
import { useEffect, useState } from "react";
import { THEME } from "../../config";


const TableRowImage = styled(TableRow)({
})

const TableCellImageProperty = styled(TableCell)({
    padding: "6px",
    borderBottom: "none",
    fontWeight: "bold"
})

const TableCellImageValue = styled(TableCell)({
    padding: "6px",
    borderBottom: "none"
})

interface IOverviewRowProps {
    color?: string
    property?: string,
    value?: any,
    type?: "number" | "string" | "date" | "longstring" | "type" | "status" | "liveness" | "modality"
}

const OverviewRow: React.FC<IOverviewRowProps> = (props) => {

    const [color, setColor] = useState(THEME.palette.text.primary)

    useEffect(() => {
        if (props.type === "status")
            setColor(selectStatusColorHelper(props.value))
    }, [props.value, props.type])

    const handleValue = () => {
        switch (props.type) {
            case "number":
                return (props.value !== undefined? handleNumberHelper(props.value) : "")
            case "string":
                return (props.value !== undefined? handleStringHelper(props.value) : "")
            case "date":
                return (props.value !== undefined? new Date(props.value).toUTCString() : "")
            case "longstring":
                return (props.value !== undefined? props.value : "")
            case "type":
                return handleStringHelper(props.value)
            case "status":
                return handleStringHelper(props.value)
            case "liveness":
                return handleStringHelper(props.value)
            case "modality":
                return handleStringHelper(props.value)
        }
    }

    return (
        <TableRowImage style={{backgroundColor: props.color}}>
            <TableCellImageProperty>
                {props.property}
            </TableCellImageProperty>
            <TableCellImageValue style={{color: color, fontWeight: props.type === "status" ? "500" : "400"}}>
                {handleValue()}
            </TableCellImageValue>
        </TableRowImage>
    );
}

export default OverviewRow;