import { Button, ButtonProps, styled } from "@mui/material";
import React, { Ref } from "react";

const ButtonCustom = styled(Button)(() => ({
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#d7d7d7",
    color: "#303030",
    backgroundColor: "#fafafa",
    boxSizing: "border-box",
}));

interface IButtonLightGray extends ButtonProps {}

const ButtonLightGray: React.FC<IButtonLightGray> = React.forwardRef((props, ref: Ref<HTMLButtonElement>) => {
    return (
        <ButtonCustom {...props} ref={ref} />
    )
})

export default ButtonLightGray