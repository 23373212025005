import { Container, Grid, Theme, styled, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { NavigationContext } from '../../Store/Navigation/context';
import LicensingSidebar from './LicensingSidebar';
import { APIsContext } from '../../Store/APIs/context';
import { Cloud, Dongle, InternetLicense, Usage } from 'neurotec-megamatcherid-management-client';
import InternetLicenseContainer from './tabs/InternetLicenseContainer';
import UsageContainer from './tabs/UsageContainer';
import DonglesLicenseContainer from './tabs/DonglesLicenseContainer';
import { ActionType } from '../../Store/Navigation/actions';
import SkeletonPieChart from './components/SkeletonPieChart';
import CloudLicenseContainer from './tabs/CloudLicenseContainer';
import { setLicensingCloudActive, setLicensingDonglesActive, setLicensingInternetLicenseActive, setLicensingUsageActive } from '../../Store/Navigation/reducer';

export const LicensingContainer = styled(Container)(({ theme }) => ({
    maxWidth: "97vw",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%"
    }
}));

const GridTab = styled(Grid)(({ theme }) => ({
    paddingTop: "2rem",
    paddingLeft: "2rem",
    [theme.breakpoints.down("md")]: {
        paddingLeft: 0
    }
}));

const GridBar = styled(Grid)(() => ({
    paddingTop: "2.1rem",
    position: "sticky",
    top: "max(7vh, 50px)",
    height: "fit-content"
}));

export enum LicensingType {
    USAGE = "USAGE",
    CLOUD = "CLOUD",
    DONGLES = "DONGLES",
    INTERNET_LICENSING = "INTERNET_LICENSING"
}

const Licensing: React.FC = () => {

    const breakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const { state, dispatch } = useContext(NavigationContext)
    const apiState = useContext(APIsContext).state
    const [licensing, setLicensing] = useState<LicensingType>(state.licensingType)
    const { enqueueSnackbar } = useSnackbar();
    const [internetLicenses, setInternetLicense] = useState<InternetLicense[]>([]);
    const [dongles, setDongles] = useState<Dongle[]>([]);
    const [cloud, setCloud] = useState<Cloud[]>([]);
    const [usage, setUsage] = useState<Usage[]>([]);
    const [token, setToken] = useState<String>("");
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchUsage = () => {
            apiState.LicensingApi.getLicensingUsage().then(res => {
                setUsage(res.data)
                setLoading(false)
                dispatch(setLicensingUsageActive(true))
            }).catch(err => {
                dispatch(setLicensingUsageActive(false))
                setLoading(false)
                enqueueSnackbar("Cannot contact license server", {variant: "error"})
            })
        }

        const fetchCloud = () => {
            apiState.LicensingApi.getCloudLicensingInfo().then(res => {
                if (res.data.length > 0) {
                    setCloud(res.data)
                    dispatch(setLicensingCloudActive(true))
                    fetchUsage()
                } else {
                    setLoading(false)
                    enqueueSnackbar("Cannot contact license server", {variant: "error"})
                }
            }).catch(err => {
                dispatch(setLicensingCloudActive(false))
                setLoading(false)
                enqueueSnackbar("Cannot contact license server", {variant: "error"})
            })
        }

        const fetchInternetLicense = () => {
            apiState.LicensingApi.getIntenetLicensingInfo().then(res => {
                if (res.data.length > 0) {
                    setInternetLicense(res.data)
                    dispatch(setLicensingInternetLicenseActive(true))
                    fetchUsage()
                } else {
                    fetchCloud()
                }
            }).catch(err => {
                dispatch(setLicensingInternetLicenseActive(false))
                fetchCloud()
            })
        }

        const fetchData = async () => {
            apiState.LicensingApi.getDongles().then(res => {
                if (res.data.length > 0) {
                    dispatch(setLicensingDonglesActive(true))
                    setDongles(res.data)
                    fetchUsage()
                } else {
                    fetchInternetLicense()
                }
            }).catch(err => {
                dispatch(setLicensingDonglesActive(false))
                fetchInternetLicense()
            })
            
            apiState.LicensingApi.getLicensingToken().then(res => 
                setToken(res.data)
            ).catch(err => {
                enqueueSnackbar("Failed to get license token", {variant: "error"})
            })
        };
        fetchData();
    }, [enqueueSnackbar, apiState, dispatch])

    useEffect(() => {
        setLicensing(state.licensingType)
    }, [state])

    const setLicensingType = (type: LicensingType) => {
        setLicensing(type)
        dispatch({type: ActionType.SetLicensingType, licensingType: type})
    }

    const getTab = (type: LicensingType) => {
        if (loading)
            return <SkeletonPieChart />
        switch(type) {
            case LicensingType.USAGE:
                return <UsageContainer usages={usage} />
            case LicensingType.CLOUD:
                return <CloudLicenseContainer token={token} />
            case LicensingType.DONGLES:
                return <DonglesLicenseContainer dongles={dongles} />
            case LicensingType.INTERNET_LICENSING:
                return <InternetLicenseContainer licenses={internetLicenses} />
        }
    }

    return (
        <LicensingContainer>
            <Grid container style={{position: "relative"}}>
                <GridBar item md={2.5} xs={0}>
                {breakpoint?
                    <LicensingSidebar
                        activeLicensing={licensing}
                        setLicensing={setLicensingType}
                        usage={usage}
                        internetLicense={internetLicenses}
                        dongles={dongles}
                        loading={loading}
                        cloud={cloud}
                    /> : null
                }
                </GridBar>
                <GridTab item md={9.5} xs={12}>
                    {getTab(licensing)}
                </GridTab>
            </Grid>
        </LicensingContainer>
    )
}

export default Licensing
