import React, { useEffect, useRef, useState } from 'react'
import { ButtonGroup, ButtonGroupProps, Checkbox, Menu, MenuItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSnackbar } from 'notistack';
import ButtonLightGray from '../Filter/Components/ButtonLightGray';
import DownloadIcon from '@mui/icons-material/Download';

// export type ImageDownloadOption = "IMAGE" | "TOKEN_IMAGE" | "TEMPLATE";
export type MultimodalDownloadOption = "FACE_IMAGE" | "FACE_TOKEN_IMAGE" | "FACE_TEMPLATE" | "VOICE_AUDIO" | "VOICE_TEMPLATE"

interface IMenuItem {
    option: MultimodalDownloadOption
    name: string,
    checked: boolean
}

const faceImageOption: IMenuItem = {
    name: 'Face image',
    option: "FACE_IMAGE",
    checked: true  
}

const faceTokenImageOption: IMenuItem = {
    name: 'Face token image',
    option: "FACE_TOKEN_IMAGE",
    checked: true
}

const faceTemplateOption: IMenuItem = {
    name: 'Face template',
    option: "FACE_TEMPLATE",
    checked: true
}

const voiceAudioOption: IMenuItem = {
    name: 'Voice audio',
    option: "VOICE_AUDIO",
    checked: true
}

const voiceTemplateOption: IMenuItem = {
    name: 'Voice template',
    option: "VOICE_TEMPLATE",
    checked: true
}


interface IBiometricDataDownloadProps extends ButtonGroupProps {
    onDownloadClick: (downloadOptions: MultimodalDownloadOption[]) => void,
    faceImageExists?: boolean,
    faceTemplateExists?: boolean,
    faceTokenImageExists?: boolean
    voiceAudioExists?: boolean,
    voiceTemplateExists?: boolean,
    single?: boolean
}

const BiometricDataDownload: React.FC<IBiometricDataDownloadProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [downloadSelection, setDownloadSelection] = useState<IMenuItem[]>([
        {...faceImageOption, checked: props.faceImageExists ?? false},
        {...faceTokenImageOption, checked: props.faceTemplateExists ?? false},
        {...faceTemplateOption, checked: props.faceTokenImageExists ?? false},
        {...voiceAudioOption, checked: props.voiceAudioExists ?? false},
        {...voiceTemplateOption, checked: props.voiceTemplateExists ?? false}
    ]);

    useEffect(() => {
        setDownloadSelection([
            {...faceImageOption, checked: props.faceImageExists ?? false},
            {...faceTokenImageOption, checked: props.faceTokenImageExists ?? false},
            {...faceTemplateOption, checked: props.faceTemplateExists ?? false},
            {...voiceAudioOption, checked: props.voiceAudioExists ?? false},
            {...voiceTemplateOption, checked: props.voiceTemplateExists ?? false}
        ])
    }, [props.faceImageExists, props.faceTemplateExists, props.faceTokenImageExists, props.voiceAudioExists, props.voiceTemplateExists])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleCheckBoxClick = (optionClick: any) => {
        let options = [...downloadSelection];
        let optionIndex = options.findIndex(x => x.option === optionClick);
        let option = options[optionIndex];
        option.checked = !option.checked;
        setDownloadSelection(options);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleDownloadClick = () => {
        let selectedOptions = downloadSelection.filter(x => x.checked).map(x => x.option);
        if (selectedOptions.length === 0) {
            enqueueSnackbar("At least one option for download must be selected", { variant: "warning" })
            return;
        }
        props.onDownloadClick(selectedOptions);
    }

    const handleActiveMenuItem = (e: IMenuItem, i: number) => {
        if (props.single) {
            switch (e.option) {
                case "FACE_IMAGE":
                    return (
                        handleMenuItem(e, i, !props.faceImageExists)
                    )
                case "FACE_TEMPLATE":
                    return (
                        handleMenuItem(e, i, !props.faceTemplateExists)
                    )
                case 'FACE_TOKEN_IMAGE':
                    return (
                        handleMenuItem(e, i, !props.faceTokenImageExists)
                    )
                case "VOICE_AUDIO":
                    return (
                        handleMenuItem(e, i, !props.voiceAudioExists)
                    )
                case "VOICE_TEMPLATE":
                    return (
                        handleMenuItem(e, i, !props.voiceTemplateExists)
                    )
            }
        }
        else {
            return (
                handleMenuItem(e, i, false)
            )
        }

    }

    const handleMenuItem = (e: IMenuItem, i: number, disabled: boolean) => (
        <MenuItem key={i} onClick={() => handleCheckBoxClick(e.option)} disabled={disabled}>
            <Checkbox color="primary" checked={disabled? false  : e.checked}/>
            {e.name}
        </MenuItem>
    )

    return (
        <>
        <ButtonGroup
            variant="text"
            style={{height: "100%", textDecoration: "none", boxSizing: "border-box"}}
        >   
            <ButtonLightGray onClick={handleDownloadClick} disabled={props.single && (!props.faceImageExists && !props.faceTokenImageExists && !props.faceTemplateExists  && !props.voiceAudioExists  && !props.voiceTemplateExists)} >
                <DownloadIcon fontSize='small' /> &nbsp; Download
            </ButtonLightGray>
            <ButtonLightGray onClick={handleToggle} ref={anchorRef}>
                <ArrowDropDownIcon fontSize='small' />
            </ButtonLightGray>
        </ButtonGroup>
        <Menu
            id="basic-menu"
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {downloadSelection.map((e, i) => handleActiveMenuItem(e, i))}
        </Menu>
        </>
    )
};

export default BiometricDataDownload
