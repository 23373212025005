import { makeStyles } from "@mui/styles";
import { Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TableCell, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { THEME } from "../../../config";
import { useCallback, useEffect, useState } from "react";
import { ISelectValue } from "./SelectCell";
import { IICAOPositionalWarnings } from "../../../types/FaceVerification";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    titleDisabled: {
        opacity: "50%",
        fontWeight: 500,
        fontSize: "1.1rem",
    },
    tableCell: {
        paddingRight: 0,
        paddingLeft: 0,
        width: "50%"
    },
    iconInfo: {
        marginLeft: "10px",
        position: "relative",
        top: "5px",
        color: THEME.palette.primary.main
    },
    select: {
        width: "95%",
    }
}))

interface SelectCheckmarksCellProps {
    title: string,
    active: boolean,
    info?: string,
    colSpan?: number,
    menuItems: ISelectValue[],
    value: string | undefined,
    setFilter: (str: string) => void
}

const SelectCheckmarksCell: React.FC<SelectCheckmarksCellProps> = (props) => {

    const classes = useStyles();

    const sequenceTokenize = useCallback(() => {
        let list: IICAOPositionalWarnings[] = []
        props.value?.split(',').forEach((a) => {
            let icao = a as unknown as IICAOPositionalWarnings
            if (icao)
                list.push(icao)
        })
        return list
    }, [props.value])

    useEffect(() => {
        setValues(sequenceTokenize())
    }, [props.value, sequenceTokenize])

    const [values, setValues] = useState<IICAOPositionalWarnings[]>(sequenceTokenize());

    const stringifySequence = (warnings: IICAOPositionalWarnings[]) => {
        let str: string = ""
        warnings.forEach((a) => {
            if (str === "")
                str = (a as unknown as string)
            else    
                str = str + "," + (a as unknown as string)
        })
        return str
    }

    const handleChange = (event: SelectChangeEvent<IICAOPositionalWarnings[]>) => {
        const { target: { value }} = event;
        if (typeof value === "string") {
            setValues([value as unknown as IICAOPositionalWarnings])
            props.setFilter(value)
        } else {
            setValues(value)
            props.setFilter(stringifySequence(value))
        }
    }

    const handleRenderValue = (selected: IICAOPositionalWarnings[]) => {
        if (selected.length > 0) {
            return selected.map((s) => IICAOPositionalWarnings[s as unknown as keyof typeof IICAOPositionalWarnings]).join(', ')
        } else {
            return ""
        }
    }

    return(
        <TableCell colSpan={props.colSpan? props.colSpan : 2} className={classes.tableCell} width="50%">
            <Grid container>
                <Grid item xs={12}>
                    <Typography component={'span'} className={props.active? classes.title : classes.titleDisabled}>
                        {props.title}
                        {props.info? 
                            <Tooltip disableFocusListener title={props.info} >
                                <div style={{display: "inline"}} >
                                    <HelpOutlineIcon className={classes.iconInfo} />
                                </div>
                            </Tooltip>
                        : null}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        disabled={!props.active}
                        value={values}
                        input={<OutlinedInput label="Tag" />}
                        label="Liveness mode"
                        multiple
                        onChange={handleChange}
                        className={classes.select}
                        color="primary"
                        renderValue={handleRenderValue}
                        MenuProps={MenuProps}
                        >
                        {props.menuItems.map((e, i) => (
                            <MenuItem key={i} value={e.key}>
                                <Checkbox checked={values.indexOf(e.key as unknown as IICAOPositionalWarnings) > -1} color="primary" />
                                <ListItemText primary={e.value} />
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </TableCell>
    );
}

export default SelectCheckmarksCell;