import { Table, TableBody, TableContainer } from "@mui/material";
import { HeadCell, Order, OrderByKey } from "../DataTable/DataTable";
import EnhancedTableHead from "../DataTable/EnhancedTableHead";
import { useState } from "react";
import { ExceptionsFieldOrderBy, IExceptionFilter } from "../../../Store/Exceptions/types";
import { FieldsOrder } from "../../../Store/Subjects/types";
import CollapsibleDataTableExceptions from "./CollapsibleDataTableExceptions";


interface ICollapsibleDataTable {
    title: string,
    data: any[],
    headCells: HeadCell[],
    filter: IExceptionFilter
    onFilterChange: (filter: any) => void
    onCollapseClick: (id: number) => void
}

const CollapsibleDataTable: React.FC<ICollapsibleDataTable> = (props) => {

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<OrderByKey>("EXCEPTION_DATE");
    
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: OrderByKey) => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        let exceptionsFilter: IExceptionFilter = {
            ...props.filter,
            exceptionsFieldOrderBy: ExceptionsFieldOrderBy[property.toString() as keyof typeof ExceptionsFieldOrderBy],
            fieldsOrder: isAsc? FieldsOrder.ASC : FieldsOrder.DESC
        }
        props.onFilterChange(exceptionsFilter)
    };

    const showData = () => (
        <>
        {props.data.map((row: any, index: number) => {
            return (
                <CollapsibleDataTableExceptions
                    row={row}
                    index={index}
                    key={index}
                />
            )})}
        </>
    )

    return (
        <TableContainer>
            <Table size="small">
                <EnhancedTableHead
                    numSelected={0}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={() => {}}
                    onRequestSort={handleRequestSort}
                    rowCount={props.data.length}
                    headCells={props.headCells}
                    inputProps={props.title.toLowerCase()}
                    disableCheckBox
                />
                <TableBody>
                    {showData()}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CollapsibleDataTable;