import { Box, Card, CardContent, TableContainer, Typography, styled } from "@mui/material"
import WifiIcon from '@mui/icons-material/Wifi';
import { InternetLicense } from "neurotec-megamatcherid-management-client";
import { THEME } from "../../../config";

const CardInternetLicense = styled(Card)(() => ({
    marginBottom: "1rem"
}))

const TypographyCardTitle = styled(Typography)(() => ({
    fontSize: "1.2rem",
    fontWeight: "500"
}))

const TypographyCardText = styled(Typography)(() => ({
    fontSize: "0.9rem",
    fontWeight: "400"
}))

interface IInternetLicenseProps {
    licenses: InternetLicense[]
}

const InternetLicenseContainer: React.FC<IInternetLicenseProps> = (props) => {

    const getColor = (str: string | undefined) => {
        if (str === "OK")
            return THEME.palette.primary.main
        else return THEME.palette.error.main
    }

    const getCard = (license: InternetLicense) => (
        <CardContent style={{display: "inline-flex", paddingBottom: "4px", paddingTop: "8px"}}>
            <Box style={{fontSize: "4rem"}}>
                <WifiIcon style={{height: "100%", color: "#898989"}} fontSize="inherit"/>
            </Box>
            <Box style={{paddingLeft: "1rem"}}>
                <TypographyCardTitle>
                    Internet License
                </TypographyCardTitle>
                <TypographyCardText>
                    Installation ID: {license.installationId}
                </TypographyCardText>
                <TypographyCardText style={{display: "inline-flex"}}>
                    Status:&nbsp;<Box component={"span"} style={{color: getColor(license.internetStatus), fontWeight: "500"}}>{license.internetStatus}</Box>
                </TypographyCardText>
            </Box>
        </CardContent>
    )

    return (
        <TableContainer
            style={{marginBottom: "2rem", tableLayout: "fixed"}}
        >
            {props.licenses.map((license) => (
                <CardInternetLicense key={license.installationId} variant="outlined">{getCard(license)}</CardInternetLicense>
            ))}
        </TableContainer>
    )
}

export default InternetLicenseContainer