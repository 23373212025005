import { Box, Card, CardContent, TableContainer, Typography, styled } from "@mui/material"
import CloudIcon from '@mui/icons-material/Cloud';

const CardInternetLicense = styled(Card)(() => ({
    marginBottom: "1rem"
}))

const TypographyCardTitle = styled(Typography)(() => ({
    fontSize: "1.2rem",
    fontWeight: "500"
}))

const TypographyCardText = styled(Typography)(() => ({
    fontSize: "0.9rem",
    fontWeight: "400"
}))

interface ICloudLicenseContainerProps {
    token: String
}

const CloudLicenseContainer: React.FC<ICloudLicenseContainerProps> = (props) => {

    const getCard = (token: String) => (
        <CardContent style={{display: "inline-flex", paddingBottom: "4px", paddingTop: "8px"}}>
            <Box style={{fontSize: "4rem"}}>
                <CloudIcon style={{height: "100%", color: "#898989"}} fontSize="inherit"/>
            </Box>
            <Box style={{paddingLeft: "1rem"}}>
                <TypographyCardTitle>
                    Cloud Licensing
                </TypographyCardTitle>
                <TypographyCardText>
                    Token: {token}
                </TypographyCardText>
            </Box>
        </CardContent>
    )

    return (
        <TableContainer
            style={{marginBottom: "2rem", tableLayout: "fixed"}}
        >
            <CardInternetLicense variant="outlined">{getCard(props.token)}</CardInternetLicense>
        </TableContainer>
    )
}

export default CloudLicenseContainer