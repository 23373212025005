import { Button, Grid } from "@mui/material";
import { SettingType } from "./Settings";
import { makeStyles } from "@mui/styles";
import { THEME } from "../../config";
import { useContext, useEffect, useState } from "react";
import { NavigationContext } from "../../Store/Navigation/context";

const useStyles = makeStyles(() => ({
    button: {
        width: "100%",
        justifyContent: "flex-start",
        textAlign: "left",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        boxShadow: "0",
        borderRadius: "0",
        color: "inherit"
    },
    gridContainer: {
        borderRight: "solid",
        borderRightColor: "#e0e0e0",
        borderWidth: "2px",
        position: "sticky",
    },
    gridSettingActive: {
        borderLeftWidth: "3px",
        borderLeft: "solid",
        borderLeftColor: THEME.palette.primary.main,
        backgroundColor: THEME.palette.primary.light,
        color: THEME.palette.primary.main
    },
    gridSettingNotActive: {
        borderLeftWidth: "3px",
        borderLeft: "solid",
        borderLeftColor: "transparent",
    }
}))

interface ISettingsSidebar {
    setSettings: (type: SettingType) => void
    activeSetting: SettingType
}

const SettingsSidebar: React.FC<ISettingsSidebar> = (props) => {

    const classes = useStyles();
    const state = useContext(NavigationContext).state
    const [developerOptionsActive, setDeveloperOptionsActive] = useState(false)
    
    useEffect(() => {
        setDeveloperOptionsActive(state.developerOptions)
    }, [state.developerOptions])


    const handleButtonClick = (type: SettingType) => {
        props.setSettings(type)
    }

    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={props.activeSetting === SettingType.CAPTURING? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.CAPTURING)}} className={classes.button} variant={"text"}>
                    Capturing
                </Button>
            </Grid>
            <Grid item xs={12} className={props.activeSetting === SettingType.VERIFICATION? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.VERIFICATION)}} className={classes.button} variant={"text"}>
                    Verification
                </Button>
            </Grid>
            <Grid item xs={12} className={props.activeSetting === SettingType.LIVENESS? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.LIVENESS)}} className={classes.button} variant={"text"}>
                    Liveness
                </Button>
            </Grid>
            <Grid item xs={12} className={props.activeSetting === SettingType.ICAO? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.ICAO)}} className={classes.button} variant={"text"}>
                    ICAO
                </Button>
            </Grid>
            <Grid item xs={12} className={props.activeSetting === SettingType.MANAGEMENT? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.MANAGEMENT)}} className={classes.button} variant={"text"}>
                    Management
                </Button>
            </Grid>
            {developerOptionsActive ?
                <Grid item xs={12} className={props.activeSetting === SettingType.DEVELOPER? classes.gridSettingActive : classes.gridSettingNotActive}>
                    <Button onClick={() => {handleButtonClick(SettingType.DEVELOPER)}} className={classes.button} variant={"text"}>
                        Developer
                    </Button>
                </Grid> : null
            }
            <Grid item xs={12} className={props.activeSetting === SettingType.RESET? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.RESET)}} className={classes.button} variant={"text"}>
                    Reset
                </Button>
            </Grid>
            <Grid item xs={12} className={props.activeSetting === SettingType.ABOUT? classes.gridSettingActive : classes.gridSettingNotActive}>
                <Button onClick={() => {handleButtonClick(SettingType.ABOUT)}} className={classes.button} variant={"text"}>
                    About Mega Matcher Id
                </Button>
            </Grid>
        </Grid>
    );
}

export default SettingsSidebar