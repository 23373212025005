import { ConfigurationParameters } from "neurotec-megamatcherid-management-client";

export enum APIsActionType {
    SetUpAPIs
}

export interface SetUpAPIs {
    type: APIsActionType.SetUpAPIs,
    configuration: ConfigurationParameters
}

export type APIsActions = SetUpAPIs