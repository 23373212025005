import { Typography } from "@mui/material"
import { JanusStyledTableCell } from "./JanusStyledTableCell"

interface IJanusTitleTableCellProps {
    value: any
}

const JanusTitleTableCell: React.FC<IJanusTitleTableCellProps> = (props) => {
    return (
        <JanusStyledTableCell style={{width: "40%"}}>
            <Typography fontWeight={"500"} style={{color: "#4E4E4E"}}>
                {props.value}
            </Typography>
        </JanusStyledTableCell>
    )
}

export default JanusTitleTableCell